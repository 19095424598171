import * as THREE from "three"
import React, { useContext, Suspense, useState, useRef } from 'react';
import { Canvas, useFrame, useLoader, useThree, useResource } from 'react-three-fiber'
import { PerspectiveCamera, OrbitControls, Text, MeshDistortMaterial } from '@react-three/drei'
import { LanguageContext } from './../../contexts/LanguageContext';


// imports for shader
import lerp from "lerp"
import img1 from "../../images/img1.jpg"
import img2 from "../../images/img1.jpg"
import disp from "../../images/displacement/11.jpg"

import "../../shaders/Fade"
// import RippleSurface from './../pages/RippleSurface';
import WaterCanvas from "./WaterCanvas";


// Ref: 
// https://codesandbox.io/s/blissful-driscoll-x37p4kk6p4?from-embed

function Image({ url1, url2, disp, ...props }) {
  const ref = useRef()
  const [hovered, setHover] = useState(false)
  const [texture1, texture2, dispTexture] = useLoader(THREE.TextureLoader, [url1, url2, disp])
  const wcanvas = document.getElementById('watercanvas')
  const canvasDispTexture = new THREE.CanvasTexture( wcanvas )
 // useFrame(() => (ref.current.material.dispFactor = lerp(ref.current.material.dispFactor, hovered ? 1 : 0, 0.1)))
  useFrame(() => {ref.current.material.dispFactor = 0.5; canvasDispTexture.needsUpdate = true;})
  return (
    <mesh ref={ref} {...props} onClick={() => setHover(!hovered)}>
      <planeBufferGeometry attach="geometry" args={[5.75, 7.66]} />
      <fade attach="material" texture={texture1} texture2={texture2} disp={canvasDispTexture} />
    </mesh>
  )
}



const Shaderport = () => {

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 

  const myCamera = useResource()

  
//
// canvas operations
//


const [ClickPos, setClickPos] = useState([])

const clear = (ctx) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
  ctx.fillStyle = '#000000'
  ctx.beginPath();
  ctx.rect(0, 0, 250, 333);
  ctx.fill()
}

const removePoint = (pointid) => {
  const id = pointid
  setClickPos(ClickPos.filter(item => item.id !== pointid));
}

const draw = (ctx, frameCount) => {
  clear(ctx)

  ctx.globalCompositeOperation = 'difference'
  ctx.filter = 'blur(5px)'

  //ctx.arc(ClickPos.x, ClickPos.y, 20*Math.sin(frameCount*0.05)**2, 0, 2*Math.PI)
  // todo: array draus machen, für jeden einzelnen inittime verfolgen...
  ClickPos.map((point) => {
    
    let opacity = 1- (Date.now()- point.age) /8000
    if(opacity > 1) opacity = 1
    if(opacity < 0){
      opacity = 0
    } 

    
    ctx.lineWidth = 8
    ctx.fillStyle = 'rgba(255,255,255,'+opacity+')'
    ctx.beginPath()
    ctx.arc(point.x, point.y, (Date.now()- point.age) /20, 0, 2*Math.PI)
    // ctx.fill()
    ctx.strokeStyle = 'rgba(255,255,255,'+opacity+')'
    ctx.stroke()

    if(opacity === 0){
      removePoint(point.id)
    }

  }
  )
      
}

const createPoint = (e) => {

  let aspect = window.innerWidth/window.innerHeight

  const point = {
    id: ClickPos.length,
    x: e.clientX/ window.innerWidth * 250, 
    y: e.clientY/ window.innerHeight * 333, 
    age:Date.now()
  }
  
  console.log(e.clientX, e.clientY, aspect)




  setClickPos(ClickPos => [...ClickPos, point] )
}

// const higherCanvasRef = useCanvas(draw, lastClick, {context})


  return ( 
    <div onClick={createPoint} >
    <Canvas>
      <Suspense fallback={null}>
        <Image url1={img1} url2={img2} disp={disp} />
        
      </Suspense>
    </Canvas>
    {/* <h1>Test</h1> */}
    <WaterCanvas lastClick={ClickPos} draw={draw} id="watercanvas" options={{context:'2d'}} />
    </div>
   );
}
 
export default Shaderport;