import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
// import placeholder from '../../../images/svg/placeholder.svg';


const ActivitiesExaminationCertification = ( prevPath ) => {


  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);

  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }

  

  return (
    <motion.div id="activities-3" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_3}/>
      <div className={"b-caption "+lang.credit_activities_3.color}>{lang.credit_activities_3.text}</div>
    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_3}/> */}
    
<svg version="1.1" id="svg-activities-ec" className="icon-round-large" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 36.9 36.9" xmlSpace="preserve">

<circle className="blue" cx="18.4" cy="18.4" r="18.4"/>
<g>

<polygon id="medallines-left" className="white" points="11.6,30.6 17.5,30.6 20.7,15.4 15,14.2 "/>
<polygon id="medallines-right" className="blue" points="20.8,13.4 15.1,14.6 18.4,30.6 24.3,30.6 "/>
<polygon id="medallines-right2" className="white" points="19.1,30.6 24.9,30.6 21.9,16.1 16.1,16.1 "/>
		
</g>

<circle className="white" cx="18.5" cy="12.2" r="6.4"/>
<path className="blue" d="M19.1,17.3l-0.7-0.5l-0.7,0.5l-0.5-0.7L16.6,17l-0.3-0.8l-0.8,0.1l-0.1-0.8l-0.8-0.1l0.1-0.8l-0.8-0.3l0.3-0.8
	l-0.7-0.5l0.5-0.7l-0.6-0.6l0.6-0.5l-0.4-0.7l0.7-0.4l-0.2-0.8l0.8-0.2l0-0.8l0.8,0L16,7.6l0.8,0.2l0.4-0.7l0.7,0.4L18.5,7l0.6,0.6
	l0.7-0.4l0.4,0.7L21,7.6l0.2,0.8l0.8,0l0,0.8l0.8,0.2l-0.2,0.8l0.7,0.4l-0.4,0.7l0.6,0.5l-0.6,0.6l0.5,0.7l-0.7,0.5l0.3,0.8
	l-0.8,0.3l0.1,0.8l-0.8,0.1l-0.1,0.8l-0.8-0.1L20.4,17l-0.8-0.3L19.1,17.3z M18.5,15.7l0.5,0.4l0.4-0.5l0.6,0.3l0.2-0.6l0.6,0.1
	l0.1-0.6l0.6-0.1l-0.1-0.6l0.6-0.2l-0.2-0.6l0.5-0.3L22,12.4l0.4-0.5l-0.5-0.4l0.3-0.6l-0.6-0.3l0.1-0.6L21.2,10l0-0.6l-0.6,0
	l-0.2-0.6l-0.6,0.2l-0.3-0.5l-0.5,0.3l-0.4-0.4l-0.4,0.4l-0.5-0.3l-0.3,0.5l-0.6-0.2l-0.2,0.6l-0.6,0l0,0.6l-0.6,0.1l0.1,0.6
	L14.8,11l0.3,0.6l-0.5,0.4l0.4,0.5l-0.4,0.5l0.5,0.3L15,13.8l0.6,0.2l-0.1,0.6l0.6,0.1l0.1,0.6l0.6-0.1l0.2,0.6l0.6-0.3l0.4,0.5
	L18.5,15.7z"/>
  
</svg>


    <h1 className='mb-0p5'>{lang.title_activities_3}</h1>
    <h2>{lang.subtitle_activities_3}</h2>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_3}}></div>

    </motion.div>
  )
}

export default ActivitiesExaminationCertification;