import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
// import { LanguageContext } from '../../contexts/LanguageContext';
import { ThemeContext } from '../../contexts/ThemeContext';

const SubpageNavigation = ({location}) => {

  // destructuring language context
  // const { current, de, en } = useContext(LanguageContext)

  // setting language
  // const lang = (current === "de") ? de : en 

  const { pages } = useContext(ThemeContext)
  const currentPage = pages[location.pathname];

  if(currentPage){
  return ( 
    <div className="subpage-navigation-wrapper visible">
      <div className="subpage-navigation">
        { currentPage.map(function(item) {
          return <span key={item.id} className={item.current ? 'current' : ''} >  &nbsp;</span>
          })
        }
      </div>
    </div>
   );
  }else{
    return (
      <div className="subpage-navigation-wrapper">
      <div className="subpage-navigation">
      &nbsp;
      </div>
      </div>
    )
  }
}
 
export default SubpageNavigation;