import React, { Component, useContext } from 'react';
// import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';


const CMS = () => {

    // consume theme context
    const { pageTransitions, subpagelinks } = useContext(ThemeContext)

    // destructuring language context
    const { state, current, de, en } = useContext(LanguageContext)
    // setting language
    const lang = (current === "de") ? de : en 

    const de_content = Object.keys(de).map((keyName, i) => (
      <li className="travelcompany-input" key={i}>
          <span className="input-label"><p className="mb-0">{i} <strong>{keyName}</strong> (de)</p></span>
          <textarea className="cms-input" type="text" value={de[keyName]}/>
      </li>
    ))
    //keyName.substring(0,1) == '_'

    return (
      <motion.div className="content-block scrollable"
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1>Admin</h1>
        <h5>Edit Content</h5>
                
        <hr/>

        <div className="d-cols-2 mb-4">
          <div>

        {
          Object.keys(de).map((keyName, i) => (
              <li className="travelcompany-input" key={i}>
                  <span className="input-label"><p className="mb-0">{i} <strong>{keyName}</strong> (de)</p></span>
                  <textarea className="cms-input" type="text" value={de[keyName]}/>
              </li>
          ))
        }
        
          </div>
          <div>
            
          {
          Object.keys(en).map((keyName, i) => (
              <li className="travelcompany-input" key={i}>
                  <span className="input-label"><p className="mb-0">{i} <strong>{keyName}</strong> (en)</p></span>
                  <textarea className="cms-input" type="text" value={en[keyName]}/>
              </li>
          ))
          }

          </div>
        </div>

{/* de.map(function(item) {
            return <div>{item}</div>
          }) */}

        contents...

      </motion.div>
    )
}

export default CMS;