import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';
import CountUp, { useCountUp } from 'react-countup';

const AboutYouth = ( prevPath ) => {

  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)

  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 

  // consume theme context
  // const { pages } = useContext(ThemeContext)
  // get location
  const location = useLocation()
  // set page along location
  // const pageInfo = pages[location.pathname]

  // console.log('prevPath auf (2):', prevPath.location.state )

  // let exitString = "exit";
  let initString = "hidden";

  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }
  
  return (
    <motion.div id="about-youth" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_about_youth}/>
      <div className={"b-caption "+lang.credit_about_youth.color}>{lang.credit_about_youth.text}</div>
    </div>

      <h1 className="mb-1">{lang.title_about_youth}</h1>

      <div className="d-grid-3-to-1">
        <div dangerouslySetInnerHTML={{__html: lang.text_about_youth1}}></div>
        <div><img src={lang.image_about_youth1}/></div>
      </div>

      <div className="d-grid-3-to-1">
        <div dangerouslySetInnerHTML={{__html: lang.text_about_youth2}}></div>
        <div><img src={lang.image_about_youth2}/></div>
      </div>

      <div className="d-grid-3-to-1">
        <div dangerouslySetInnerHTML={{__html: lang.text_about_youth3}}></div>
        <div><img src={lang.image_about_youth3}/></div>
      </div>
{/* 
      <div className="d-grid-2x2 d-title-twoline text-center mb-4">
          <div dangerouslySetInnerHTML={{__html: lang.text_members_card1}}></div>
          <div dangerouslySetInnerHTML={{__html: lang.text_members_card2}}></div>
          <div dangerouslySetInnerHTML={{__html: lang.text_members_card3}}></div>
          <div dangerouslySetInnerHTML={{__html: lang.text_members_card4}}></div>
      </div> */}
                  

    </motion.div>
  )
}


export default AboutYouth;