import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../contexts/ThemeContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';

const MainMenu = () => {

  const { subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  
    // consume language context
    const { current, de, en } = useContext(LanguageContext);
    // setting language
    const lang = (current === "de") ? de : en 

    // get location
    const location = useLocation()

  if(location.pathname != '/'){

  return ( 
    
    <Link to="/"><div onClick={ () => {
        toggleWhereAreWeGoing('hiddenDelayed')
    }} >{lang.home_title}</div></Link>
   );

  }else{

    return (
      
        <Link to="/"><div className="invisible">{lang.home_title}</div></Link>
      
    );

  }
}
 
export default MainMenu;