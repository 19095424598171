import React, { useContext } from 'react';
import logo from '../../images/logo.svg'
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../contexts/ThemeContext';
import { useLocation } from 'react-router-dom';

const Logo = () => {

  const { subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const location = useLocation()

  if(location.pathname != '/'){

  return ( 
    
    <Link to="/" className="link-home" id="dvgw-logo"><div onClick={ () => {
      
      if(location.pathname != '/'){
        console.log('go home '+location.pathname);
        toggleWhereAreWeGoing('hiddenDelayed')
      }else{
        console.log('go nowhere');
        toggleWhereAreWeGoing('nowhere')
      }
    }} >logo</div></Link>
   );

  }else{

    return (
      
      <div id="dvgw-logo"></div>
      
    );

  }
}
 
export default Logo;