import * as THREE from 'three'
import React, { useRef, useState, useContext, useLoader, Suspense, useCallback, useEffect } from 'react';
import { Canvas, useFrame, useThree, useResource, useLoader as uL } from 'react-three-fiber'
import { PerspectiveCamera, OrbitControls, Text, MeshDistortMaterial, Billboard, Plane } from '@react-three/drei'
import { LanguageContext } from './../../contexts/LanguageContext';
import { Link, NavLink } from 'react-router-dom';
import Effects from './Effects'
import Particles from './Particles'
import Sparks from './Sparks'
import uuid from "short-uuid"
import Spawned from "./Spawned"
import AutoSpawned from "./AutoSpawned"

const Viewport = ({ posterVisible }) => {

  // const { viewport } = useThree()
  // viewport = canvas in 3d units (meters)

  // consume language context
  // destructuring language context
  const { toggleLanguage, current, de, en } = useContext(LanguageContext)
  
  // setting language
  const lang = (current === "de") ? de : en 

  // 2021-10-22 click interaction
  const ref = useRef();
  
  const [items, set] = useState([])
  const [autoItems, setAuto] = useState([])

  const handleClick = useCallback(
   e => {
    console.log(e.point.x, e.point.y);
    set(items => [...items, 
    {id: uuid.generate(), x: e.point.x, y: e.point.y, z: e.point.z, t: Date.now(), v: 1, s:Math.random(), c: 'rgb(147,182,235)'},
    {id: uuid.generate(), x: e.point.x, y: e.point.y, z: e.point.z, t: Date.now()+10, v: 1, s:Math.random(), c: 'rgb(157,192,235)'},
    {id: uuid.generate(), x: e.point.x, y: e.point.y, z: e.point.z, t: Date.now()+20,v: 1, s:Math.random(), c: 'rgb(167,202,235)'},
    {id: uuid.generate(), x: e.point.x, y: e.point.y, z: e.point.z, t: Date.now()+30, v: 1, s:Math.random(), c: 'rgb(177,212,235)'},
    {id: uuid.generate(), x: e.point.x, y: e.point.y, z: e.point.z, t: Date.now()+40, v: 1, s:Math.random(), c: 'rgb(187,222,235)'}]);
    }, 
    []
  );

  // x: -22 bis 22,

  const autoClick = useCallback(
    e => {
      let eks = Math.floor(Math.random() * (20 - -20 + 1)) + -20;
      setAuto(autoItems => [...autoItems, 
     {id: uuid.generate(), x: eks, y: -40, z: -250, t: Date.now(), v: 1, s:Math.random(), c: 'rgb(147,182,235)'},
     {id: uuid.generate(), x: eks, y: -40, z: -250, t: Date.now()+10, v: 1, s:Math.random(), c: 'rgb(157,192,235)'},
     {id: uuid.generate(), x: eks, y: -40, z: -250, t: Date.now()+20,v: 1, s:Math.random(), c: 'rgb(167,202,235)'},
     {id: uuid.generate(), x: eks, y: -40, z: -250, t: Date.now()+30, v: 1, s:Math.random(), c: 'rgb(177,212,235)'},
     {id: uuid.generate(), x: eks, y: -40, z: -250, t: Date.now()+40, v: 1, s:Math.random(), c: 'rgb(187,222,235)'}]);
     }, 
     []
   );
 

  const itemsRef = useRef([]);


  // const context = canvas.getContext('2d');

  const handleOverflow = useCallback(
    e => {
      if(e.length > 3){
        // var array = [e]; // make a separate copy of the array
        // console.log(array);
        // array.slice(1,3);
        // console.log(array);
        // set(items => [array]);
      
      }
    },[]
  )

  // useEffect(() => {
  //   const canvas = document.getElementById('d-canvas');
  //   canvas.width = window.innerWidth;
  //   canvas.height = window.innerHeight;
  //   console.log(canvas.width, canvas.height);
  //   // canvas
  //   // resizeCanvas();
  // },[])


  

  useEffect(() => {
    const interval = setInterval(() => {
      setAuto(autoItems => []);
      autoClick();
      // console.log('okay 8500ms');
    }, 8500);
    return () => clearInterval(interval);
  }, []);


  const myCamera = useResource()

  const mesh = useRef()
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  const mouse = useRef([0, 0])
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const bg = uL(THREE.TextureLoader, './main-bg.png')

  return ( 
    <Canvas id="d-canvas" camera={{ zoom: 5, position: [0, 0, 20] }} pixelRatio={2} gl={{ antialias: true }} onCreated={({ gl }) => {
      gl.toneMapping = THREE.LinearToneMapping
      gl.setClearColor(new THREE.Color('#e0edf2')) //#e0edf2
    }}>
    {/* <fog attach="fog" args={['white', 5, 50]} /> */}
        <ambientLight intensity="0.75"/>
        <pointLight position={[10,12,5]} />
        <pointLight position={[-15,-5,0]} />
          
        <Particles count={isMobile ? 5000 : 10000} mouse={mouse} />
        {/* <Sparks count={20} mouse={mouse} colors={['#e49408']} /> */}

        <Suspense fallback={null}>

        <Plane args={[50,90]} position={[0,0,-250]} ref={ref} onClick={handleClick}>
        <meshBasicMaterial map={bg} attach="material" />
        {/* <myMaterial /> */}
        </Plane>

        {/* {items.map((key, index) => (
          <Spawned key={key} position={[0, -2 + index * 0.4, 0]} />
        ))}
         */}

         {items.map((item, index) => (
          <Spawned key={index} position={[item.x, item.y, item.z]} size={item.s} time={item.t} velocity={item.v} color={item.c}/>
        ))}
      
        {autoItems.map((item, index) => (
          <AutoSpawned key={index} position={[item.x, item.y, item.z]} size={item.s} time={item.t} velocity={item.v} color={item.c}/>
        ))}

        <group position={[1.2,2.5,0]} onClick={toggleLanguage}>
            <mesh
            onPointerOver={(event) => setHover(true)}
            onPointerOut={(event) => setHover(false)}>
              <sphereBufferGeometry args={[.3, 64, 32]} />
              <meshStandardMaterial roughness={1} color={hovered ? '#9cc53a' : '#88c33c'}/>
            </mesh>
            <Text
            position={[-.11,-0.24,2]}
            color={'#FFFFFF'}
            fontSize={0.1}
            maxWidth={50}
            lineHeight={1.0}
            letterSpacing={0.02}
            textAlign={'center'}
            font="../Helvetica77BoldCondensed.ttf"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0}
            outlineColor="#ffffff"
          >
            {lang.button_toggle_language}
          </Text>
        </group>

        <group position={[0.8,.6,0]} onClick={() => { window.appHistory.push("/gas/hydrogen")}}>
            <mesh>
              <sphereBufferGeometry args={[.7, 64, 32]} />
              {/* <MeshDistortMaterial color={hovered ? '#e49408' : '#f07c00'} distort={.3} speed={.2}/> */}
              <meshStandardMaterial roughness={1} color='#f07c00'/>
            </mesh>
            <Text
            position={[-.10,-.1,2]}
            color={'#FFFFFF'}
            fontSize={0.25}
            maxWidth={50}
            lineHeight={1.0}
            letterSpacing={0.02}
            textAlign={'center'}
            font="../Helvetica77BoldCondensed.ttf"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0}
            outlineColor="#ffffff"
          >
            {lang.button_gas}
          </Text>
        </group>
        

        <group position={[-.75,1.5,0]} onClick={() => { window.appHistory.push("/water") }}>
            <mesh>
              <sphereBufferGeometry args={[.7, 64, 32]} />
              {/* <MeshDistortMaterial color="#0067b4" distort={.5} speed={.5}/> */}
              <meshLambertMaterial color="#0067b4"/>
            </mesh>
            <Text
            position={[.09,-.15,2]}
            color={'#FFFFFF'}
            fontSize={0.25}
            maxWidth={50}
            lineHeight={1.0}
            letterSpacing={0.02}
            textAlign={'center'}
            font="../Helvetica77BoldCondensed.ttf"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0}
            outlineColor="#ffffff"
          >
            {lang.button_water}
          </Text>
        </group>

        <group position={[-.65,-.5,0]} onClick={() => { window.appHistory.push("/about")}}>
            <mesh>
              <sphereBufferGeometry args={[.5, 64, 32]} />
              {/* <MeshDistortMaterial color="#003164" distort={.5} speed={.47}/> */}
              <meshLambertMaterial color="#003164"/>
            </mesh>
            <Text
            position={[.075,.05,2]}
            color={'#FFFFFF'}
            fontSize={0.125}
            maxWidth={50}
            lineHeight={1.2}
            letterSpacing={0.02}
            textAlign={'center'}
            font="../Helvetica77BoldCondensed.ttf"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0}
            outlineColor="#ffffff"
          >
            {lang.button_about}
          </Text>
        </group>

        <group position={[.5,-1.5,0]} onClick={() => { window.appHistory.push("/activities")}}>
            <mesh>
              <sphereBufferGeometry args={[.5, 64, 32]} />
              {/* <MeshDistortMaterial color="#67acbf" distort={.48} speed={.45}/> */}
              <meshLambertMaterial color="#67acbf"/>
            </mesh>
            <Text
            position={[-.05,0.15,2]}
            color={'#ffffff'}
            fontSize={0.125}
            maxWidth={50}
            lineHeight={1.0}
            letterSpacing={0.02}
            textAlign={'center'}
            font="../Helvetica77BoldCondensed.ttf"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0}
            outlineColor="#ffffff"
          >
            {lang.button_activities}
          </Text>
        </group>
        </Suspense>
        <Effects />

          {/* <PerspectiveCamera ref={myCamera} position={[0, 0, 0]} /> */}
          {/* <OrbitControls camera={myCamera.current} /> */}
          
      </Canvas>
   );
}
 
export default Viewport;