import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';

import WaterCanvas from '../../three/WaterCanvas';
// import WaterTexture from './../three/WaterTexture';
import { ThemeContext } from '../../../contexts/ThemeContext';



const RippleSurface = ({ setShowTouch }) => {

  // consume theme context
  const { pageTransitions, subpagelinks } = useContext(ThemeContext)

  // destructuring language context
  const { current, de, en } = useContext(LanguageContext)
  // setting language
  const lang = (current === "de") ? de : en 

  return (
    <motion.div className="content-block"
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        dings
      </motion.div>
    )
  }

export default RippleSurface;