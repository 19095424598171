import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
// import { LanguageContext } from '../../contexts/LanguageContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import btnRdLeft from '../../images/svg/button-round-arrow-left.svg';
import btnRdRight from '../../images/svg/button-round-arrow-right.svg';
// import btnRdUp from '../../images/svg/button-round-arrow-up.svg';

const SubpageButtonRight = ({location}) => {

  // destructuring language context
  // const { current, de, en } = useContext(LanguageContext)

  // setting language
  // const lang = (current === "de") ? de : en 

  const { subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const currentPage = subpagelinks[location.pathname];

  if(currentPage){
  return ( 
    <div className={ 'd-mid-right ' + currentPage[0].position }>
    <div className="subpage-button subpage-button-right subpage-button-green">
      { currentPage.map(function(item) {
                return <NavLink key={item.id} className={(item.direction == 'next' || item.direction == 'up' || item.direction == 'home') ? 'btn-lg' : 'btn-sm' + (item.direction == 'none' ? ' btn-sm-none' : '')} to={
                  {pathname: item.path,
                  state: {prevPath: location.pathname, direction: 'forward'}
                  }} >
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 105 105" className={'button-round-arrow'+ (item.direction == 'up' ? ' up' : '')+ (item.direction == 'home' ? ' up' : '')} onClick={ () => {
                      if(item.direction == 'previous'){
                        toggleWhereAreWeGoing('exitRight');
                      }else if(item.direction == 'next'){
                        toggleWhereAreWeGoing('exitLeft');
                      }else if(item.direction == 'up'){
                        toggleWhereAreWeGoing('hidden');
                      }else if(item.direction == 'home'){
                        toggleWhereAreWeGoing('hiddenDelayed');
                      }}}>
                  <g>
                    <circle className="green" cx="52.5" cy="52.5" r="52.5"/>
                    <polyline className="st2" points="40.4,26.7 66.4,52.2 40.4,77.8 	"/>
                  </g>
                  </svg>
                  
                  </NavLink>
            })
        }
    </div>
    </div>
   );
  }else{
    return (
      <div className="d-mid-right">
      <div className="subpage-button">
      &nbsp;
      </div>
      </div>
    )
  }
}
 
export default SubpageButtonRight;