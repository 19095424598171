import React, { useContext, useState, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import Viewport from '../../three/Viewport'
import { ThemeContext } from '../../../contexts/ThemeContext';


const Poster = () => {

  // consume theme context
  const { pageTransitions, subpagelinks, direction } = useContext(ThemeContext)

  // destructuring language context
  const { current, de, en } = useContext(LanguageContext)
  // setting language
  const lang = (current === "de") ? de : en 

  // useEffect(() => {
  //   console.log('set init route from poster');
  //   // window.appHistory.push("/water")

  //   const interval = setTimeout(() => 
  //   {
  //   } 
  //   , 1000);

  // }, [])

  return (
    <motion.div className={"content-block d-r3f-content "+ direction}
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
      <Suspense fallback={null}>
        <Viewport/>
      </Suspense>
      </motion.div>
    )
  }

export default Poster;