import React, { Component, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ThemeContext } from '../../contexts/ThemeContext';

const Gas = () => {
 
    // consume theme context
    const { pageTransitions, links, whereAreWeGoing, toggleWhereAreWeGoing  } = useContext(ThemeContext)
    // destructuring language context
    const { current, de, en } = useContext(LanguageContext)
    // setting language
    const lang = (current === "de") ? de : en 

    return (
      <motion.div className="content-block"
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit={whereAreWeGoing}
      >
        <h3>{lang.title_gas}</h3>
        <p>{lang.dummy_text}</p>

        <div className="link-list">
        { links.filter(item => item.path.includes("gas")).map(function(item) {
                return <NavLink key={item.id} to={
                  {pathname: item.path }} >{lang[item.locString]}</NavLink>
            })
        }
        </div>

      </motion.div>
    )
  }

export default Gas;