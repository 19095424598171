import React, { useState, useRef, useEffect } from 'react';
import { Route, Switch, useLocation, Link } from "react-router-dom";
// import Header from './components/Header';

//
import { AnimatePresence } from 'framer-motion';
import LanguageContextProvider from './contexts/LanguageContext';
import ThemeContextProvider from './contexts/ThemeContext';

// import { Grid, Row, Col } from 'react-flexbox-grid'
import Shader from './components/pages/dev/Shader';
import Poster from './components/pages/dev/Poster';
import RippleSurface from './components/pages/dev/RippleSurface';
import StyleGuide from './components/pages/dev/StyleGuide';
import Changelog from './components/pages/dev/Changelog';

// CMS:
import ContentManagement from './components/pages/dev/ContentManagement';

import Home from './components/pages/Home';

// Subpages, Level 1
import About from './components/pages/About';
import Activities from './components/pages/Activities';
import Water from './components/pages/Water';
import Gas from './components/pages/Gas';

// Subpages, Level 2
// import SubPage from './components/pages/SubPage';

// Subpages, Level 2, About
import AboutMembership1 from './components/pages/subpages/AboutMembership1';
import AboutMembership2 from './components/pages/subpages/AboutMembership2';
import AboutGroups1 from './components/pages/subpages/AboutGroups1';
import AboutGroups2 from './components/pages/subpages/AboutGroups2';
import AboutResearch from './components/pages/subpages/AboutResearch';
import AboutYouth from './components/pages/subpages/AboutYouth';

// Subpages, Level 2, Activities
import ActivitiesRulesettingStandardization from './components/pages/subpages/ActivitiesRulesettingStandardization';
import ActivitiesResearchDevelopment from './components/pages/subpages/ActivitiesResearchDevelopment';
import ActivitiesExaminationCertification from './components/pages/subpages/ActivitiesExaminationCertification';
import ActivitiesTraining from './components/pages/subpages/ActivitiesTraining';
import ActivitiesYoungProfessionals from './components/pages/subpages/ActivitiesYoungProfessionals';
import ActivitiesTechnicalInformation from './components/pages/subpages/ActivitiesTechnicalInformation';
import ActivitiesSafetyQualityManagement from './components/pages/subpages/ActivitiesSafetyQualityManagement';
import ActivitiesOccupationalHealthSafety from './components/pages/subpages/ActivitiesOccupationalHealthSafety';
import ActivitiesITServices from './components/pages/subpages/ActivitiesITServices';
import ActivitiesTechnicalAdvice from './components/pages/subpages/ActivitiesTechnicalAdvice';

// Subpages, Level 2, Water
import WaterClimateChange from './components/pages/subpages/WaterClimateChange';
import WaterClimateChange2 from './components/pages/subpages/WaterClimateChange2';
import WaterClimateChange3 from './components/pages/subpages/WaterClimateChange3';
import WaterClimateChange4 from './components/pages/subpages/WaterClimateChange4';
import WaterClimateChange5 from './components/pages/subpages/WaterClimateChange5';
import WaterNitrates from './components/pages/subpages/WaterNitrates';
import WaterNitrates2 from './components/pages/subpages/WaterNitrates2';
import WaterNitrates3 from './components/pages/subpages/WaterNitrates3';
import WaterResponsibility from './components/pages/subpages/WaterResponsibility';

// Subpages, Level 2, Gas
import GasProblem1 from './components/pages/subpages/GasProblem1';
import GasProblem2 from './components/pages/subpages/GasProblem2';
import GasProblem3 from './components/pages/subpages/GasProblem3';
import GasSolutions from './components/pages/subpages/GasSolutions';
import GasHydrogen from './components/pages/subpages/GasHydrogen';
import GasHydrogenProduction from './components/pages/subpages/GasHydrogenProduction';
import GasHydrogenTransport from './components/pages/subpages/GasHydrogenTransport';
import GasHydrogenApplication from './components/pages/subpages/GasHydrogenApplication';

// UI elements
import TouchPoint from './components/elements/TouchPoint';


// DEPRECATED:
// import Base from './components/deprecated/Base';
// import Toppings from './components/deprecated/Toppings';
// import Order from './components/deprecated/Order';
import Modal from './components/elements/Modal';

import UserInterface from './components/elements/UserInterface';

// import { Player, Ui  } from '@vime/react';
// Default theme. ~960B
// import '@vime/core/themes/default.css';
import VideoPlane from './components/elements/VideoPlane';

import ScreenSaver from './components/elements/ScreenSaver';

import { useSwipeable } from 'react-swipeable';


function App() {
  const location = useLocation()

  const [showModal, setShowModal] = useState(false)
  const [showTouch, setShowTouch] = useState({ isVisible: false, x:0, y:0, touchStartAt: null})
  const [showDebug, setShowDebug] = useState(false)
  
  const [showNav, setShowNav] = useState(false);
  const [posterVisible, setPosterVisible] = useState(true);
  const [showScreenSaver, setShowScreenSaver] = useState(true);

  const [zoomLevel, setZoomLevel] = useState(1);

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const [lastLocation, setLastLocation] = useState('/');

  const [swipeDir, setSwipeDir] = useState('');

  const [transitioning, setTransitioning] = useState(false);

  const [sessionHistory, setSessionHistory] = useState('0');
  const [currentSessionCount, setCurrentSessionCount] = useState(0);

  function toggle() {
    setIsActive(!isActive);
  }

  const reset = () => {
    setSeconds(0);
  }

  const saveSessionData = () => {

    setCurrentSessionCount(0);
    let dvgwResets = parseInt(localStorage.getItem("dvgw-session-count"));
    if(isNaN(dvgwResets)){
      dvgwResets = 0;
    }
    dvgwResets += 1;
    localStorage.setItem("dvgw-session-count",dvgwResets);

    localStorage.setItem("dvgw-session-"+dvgwResets+"-"+Date.now(),sessionHistory+":"+seconds+"x")
    setSessionHistory("0");
  }

  useEffect(() => {
    setSessionHistory(sessionHistory + ":" + seconds + "," + pageIDs[location.pathname] );
    setCurrentSessionCount(currentSessionCount+1);
    //console.log(sessionHistory);
  }, [location]);

  useEffect(() => {
    let interval = null;
    if(lastLocation != location.pathname){
      setLastLocation(location.pathname);
      reset();
    }
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
        
        if(seconds > 120 && location.pathname != '/'){
          reset();
          
          saveSessionData();
          window.appHistory.push("/");
          setShowScreenSaver(true);

        }else if(seconds > 180 && location.pathname == '/'){
          if(currentSessionCount > 1){
            // console.log("currentSessionCount:"+currentSessionCount);
            setCurrentSessionCount(0);
            saveSessionData();
          }
        }else if(seconds > 90 && location.pathname == '/' && posterVisible === true){ // 2022-09-14 screensaver

          reset();
          setShowScreenSaver(true);
          // window.appHistory.push("/");          
          // window.location = '/';
        }
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    // console.log('set init route');
    // window.appHistory.push("/about")
   }, [])

  const navToggle = () => {
    setShowNav(!showNav)
  }

  const toggleDebug = () => {
    setShowDebug(!showDebug)
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const decreaseZoom = () => {
    if(zoomLevel > 0){
      setZoomLevel(zoomLevel - 1)
    }
  }
  const increaseZoom = () => {
    if(zoomLevel < 3){
      setZoomLevel(zoomLevel + 1)
    }
  }

  let config = {
    delta: 10,                            // min distance(px) before a swipe starts. *See Notes*
    preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
    trackTouch: true,                     // track touch input
    trackMouse: false,                    // track mouse input
    rotationAngle: 0,                     // set a rotation angle
  };

  const pageIDs = {
    '/': 0,
    '/about': 10,
    '/about/membership1': 11,
    '/about/membership2': 12,
    '/about/groups1': 15,
    '/about/groups2': 16,
    '/about/youth': 17,
    '/about/research': 19,
    '/activities': 20,
    '/activities/rulesetting-standardization': 21,
    '/activities/research-development': 22,
    '/activities/examination-certification': 23,
    '/activities/training': 24,
    '/activities/technical-information': 25,
    '/activities/safety-quality-management': 26,
    '/activities/occupational-health-safety': 27,
    '/activities/it-services': 28,
    '/activities/technical-advice': 29,
    '/water': 30,
    '/water/climate-change': 31,
    '/water/climate-change/2': 32,
    '/water/climate-change/3': 33,
    '/water/climate-change/4': 34,
    '/water/climate-change/5': 35,
    '/water/nitrates': 36,
    '/water/nitrates/2': 37,
    '/water/nitrates/3': 38,
    '/water/responsibility': 39,
    '/gas': 40,
    '/gas/problem1': 41,
    '/gas/problem2': 42,
    '/gas/problem3': 43,
    '/gas/solutions': 44,
    '/gas/hydrogen': 45,
    '/gas/hydrogen-production': 46,
    '/gas/hydrogen-transport': 47,
    '/gas/hydrogen-application': 48
  };

  const swipePaths = {
    '/': {left:'', right:'', up:''},
    '/about': { left: '', right:'', up: '/' },
    '/about/membership1': { left: '/about/membership', right:'/about/membership2', up: '/about' },
    '/about/membership2': { left: '/about/membership1', right:'/about/membership1', up: '/about' },
    '/about/groups1': { left: '/about/groups2', right:'/about/groups2', up: '/about' },
    '/about/groups2': { left: '/about/groups1', right:'/about/groups1', up: '/about' },
    '/about/youth': { left: '', right:'', up: '/about' },
    '/about/research': {left: '', right: '', up: '/about'},
    
    '/activities': { left: '/', right:'/', up: '/' },
    '/activities/rulesetting-standardization': { left: '/activities/technical-advice', right:'/activities/research-development', up: '/activities' },
    '/activities/research-development': { left: '/activities/rulesetting-standardization', right:'/activities/examination-certification', up: '/activities'  },
    '/activities/examination-certification': { left: '/activities/research-development', right:'/activities/training', up: '/activities'  },
    '/activities/training': { left: '/activities/examination-certification', right:'/activities/technical-information', up: '/activities'  },
    '/activities/technical-information': { left: '/activities/training', right:'/activities/safety-quality-management', up: '/activities'  },
    '/activities/safety-quality-management': { left: '/activities/technical-information', right:'/activities/occupational-health-safety', up: '/activities'  },
    '/activities/occupational-health-safety': { left: '/activities/safety-quality-management', right:'/activities/it-services', up: '/activities'  },
    '/activities/it-services': { left: '/activities/occupational-health-safety', right:'/activities/technical-advice', up: '/activities'  },
    '/activities/technical-advice': { left: '/activities/it-services', right:'/activities/rulesetting-standardization', up: '/activities'  },
    
    '/water': { left: '', right:'', up: '/' },
    '/water/climate-change': { left: '/water/climate-change/5', right:'/water/climate-change/2', up: '/water' },
    '/water/climate-change/2': { left: '/water/climate-change', right:'/water/climate-change/3', up: '/water' },
    '/water/climate-change/3': { left: '/water/climate-change/2', right:'/water/climate-change/4', up: '/water' },
    '/water/climate-change/4': { left: '/water/climate-change/3', right:'/water/climate-change/5', up: '/water' },
    '/water/climate-change/5': { left: '/water/climate-change/4', right:'/water', up: '/water' },
    '/water/nitrates': { left: '', right:'/water/nitrates/2', up: '/water' },
    '/water/nitrates/2': { left: '/water/nitrates', right:'/water/nitrates/2', up: '/water' },
    '/water/nitrates/3': { left: '/water/nitrates/2', right:'/water', up: '/water' },
    '/water/responsibility': { left: '', right:'', up: '/water' },
    
    '/gas': { left: '', right:'', up: '/' },
    '/gas/problem1': { left: '', right:'', up: '/' },
    '/gas/problem2': { left: '', right:'', up: '/' },
    '/gas/problem3': { left: '', right:'', up: '/' },
    '/gas/solutions': { left: '', right:'', up: '/' },
    '/gas/hydrogen': { left: '', right:'/gas/hydrogen-production', up: '/' },
    '/gas/hydrogen-production': { left: '/gas/hydrogen', right:'/gas/hydrogen-transport', up: '/' },
    '/gas/hydrogen-transport': { left: '/gas/hydrogen-production', right:'/gas/hydrogen-application', up: '/' },
    '/gas/hydrogen-application': { left: '/gas/hydrogen-transport', right:'/', up: '/' }
  };

  const handlers = useSwipeable({


    onSwiping: (eventData) => {

      location.prevPath = location.pathname;
      
      if(transitioning == true){
        return;
      }

      setTransitioning(true);

      if(eventData.dir == "Right"){
        if(swipePaths[location.pathname].left == ''){
          return;
        }
        setSwipeDir('left');
        window.appHistory.push(swipePaths[location.pathname].left, { from: 'left'});
      }else if(eventData.dir == "Left"){
        if(swipePaths[location.pathname].right == ''){
          return;
        }
        setSwipeDir('right');
        window.appHistory.push(swipePaths[location.pathname].right, { from: 'right'});
      }else if(eventData.dir == "Up"){
        if(swipePaths[location.pathname].up == '/'){
          setSwipeDir('home');
        }else if(swipePaths[location.pathname].up == ''){
          return;
        }else{
          setSwipeDir('up');
        }
        window.appHistory.push(swipePaths[location.pathname].up, { from: 'up'});
      }
    },
    onSwiped: (eventData) => {

      setTransitioning(false);

      // location.prevPath = location.pathname;

      // console.log("User Swiped!"+location.pathname, eventData.dir)
      // if(eventData.dir == "Right"){
      //   if(swipePaths[location.pathname].left == ''){
      //     return;
      //   }
      //   setSwipeDir('left');
      //   window.appHistory.push(swipePaths[location.pathname].left, { from: 'left'});
      // }else if(eventData.dir == "Left"){
      //   if(swipePaths[location.pathname].right == ''){
      //     return;
      //   }
      //   setSwipeDir('right');
      //   window.appHistory.push(swipePaths[location.pathname].right, { from: 'right'});
      // }else if(eventData.dir == "Up"){
      //   if(swipePaths[location.pathname].up == '/'){
      //     setSwipeDir('home');
      //   }else if(swipePaths[location.pathname].up == ''){
      //     return;
      //   }else{
      //     setSwipeDir('up');
      //   }
      //   window.appHistory.push(swipePaths[location.pathname].up, { from: 'up'});
      // }
      
    },
    ...config,
  });

  return (
      <LanguageContextProvider>
        <ThemeContextProvider direction={ swipeDir }>
          <div className={(showDebug ? 'debug' : '')+ ' zoom-'+zoomLevel} {...handlers}>
      {/* {posterVisible ? 'true' : 'false'} */}
          <VideoPlane location={location} />
            {/* <Header aspect={aspect} toggleAspect={toggleAspect}/> */}
            
            <Modal showModal={showModal} />
            
            <AnimatePresence onExitComplete={() => {setShowModal(false); setShowTouch(false); setShowNav(false) }}>
            <Switch location={location} key={location.key}>
                {/* exitBeforeEnter */}
                { /* DEPRECATED: DYNAMIC PAGE CREATION */ }
                {/* <Route path="/about/:page_id" component={SubPage}/> */}

                <Route exact path="/admin" component={ContentManagement}/>                

                { /* ABOUT */ }
                <Route exact path="/about" component={About}/>
                <Route path="/about/membership1" component={AboutMembership1}/>
                <Route path="/about/membership2" component={AboutMembership2}/>
                <Route path="/about/groups1" component={AboutGroups1}/>
                <Route path="/about/groups2" component={AboutGroups2}/>
                <Route path="/about/research" component={AboutResearch}/>
                <Route path="/about/youth" component={AboutYouth}/>

                { /* ACTIVITIES */ }
                <Route exact path="/activities" component={Activities}/>
                <Route path="/activities/rulesetting-standardization" component={ActivitiesRulesettingStandardization}/>
                <Route path="/activities/research-development" component={ActivitiesResearchDevelopment}/>
                <Route path="/activities/examination-certification" component={ActivitiesExaminationCertification}/>
                <Route path="/activities/training" component={ActivitiesTraining}/>
                <Route path="/activities/young-professionals" component={ActivitiesYoungProfessionals}/>
                <Route path="/activities/technical-information" component={ActivitiesTechnicalInformation}/>
                <Route path="/activities/safety-quality-management" component={ActivitiesSafetyQualityManagement}/>
                <Route path="/activities/occupational-health-safety" component={ActivitiesOccupationalHealthSafety}/>
                <Route path="/activities/it-services" component={ActivitiesITServices}/>
                <Route path="/activities/technical-advice" component={ActivitiesTechnicalAdvice}/>

                { /* WATER */ }
                <Route exact path="/water" component={Water}/>
                <Route exact path="/water/climate-change" component={WaterClimateChange}/>
                <Route path="/water/climate-change/2" component={WaterClimateChange2}/>
                <Route path="/water/climate-change/3" component={WaterClimateChange3}/>
                <Route path="/water/climate-change/4" component={WaterClimateChange4}/>
                <Route path="/water/climate-change/5" component={WaterClimateChange5}/>
                <Route exact path="/water/nitrates" component={WaterNitrates}/>
                <Route path="/water/nitrates/2" component={WaterNitrates2}/>
                <Route path="/water/nitrates/3" component={WaterNitrates3}/>
                <Route path="/water/responsibility" component={WaterResponsibility}/>
 
                { /* GAS */ }
                <Route exact path="/gas" component={Gas}/>
                {/* <Route path="/gas/problem1" component={GasProblem1}/>
                <Route path="/gas/problem2" component={GasProblem2}/>
                <Route path="/gas/problem3" component={GasProblem3}/>
                <Route path="/gas/solutions" component={GasSolutions}/> */}
                <Route path="/gas/hydrogen" component={GasHydrogen}/>
                <Route path="/gas/hydrogen-production" component={GasHydrogenProduction}/>
                <Route path="/gas/hydrogen-transport" component={GasHydrogenTransport}/>
                <Route path="/gas/hydrogen-application" component={GasHydrogenApplication}/>

                {/* DEBUG ROUTES */}
                <Route path="/poster" component={Poster} />
                <Route path="/shader" component={Shader} />
                <Route path="/ripples" component={RippleSurface} />
                {/* <Route path="/styleguide" component={StyleGuide} /> */}
                <Route path="/changelog" component={Changelog} />

                <Route path="/">
                  <Poster/>
                </Route>

              </Switch>
            </AnimatePresence>
            <UserInterface 
            showDebug={showDebug}
            toggleDebug={toggleDebug}
            showModal={showModal}
            toggleModal={toggleModal}
            showNav={showNav} 
            navToggle={navToggle}
            setShowNav={setShowNav}
            zoomLevel={zoomLevel}
            increaseZoom={increaseZoom} 
            decreaseZoom={decreaseZoom}
            location={location}
             /> 

            <ScreenSaver showScreenSaver={showScreenSaver} setShowScreenSaver={setShowScreenSaver}/>

{/* <div className="time">{seconds}</div> */}

            </div>

        </ThemeContextProvider>
      </LanguageContextProvider>
  );
}

export default App;