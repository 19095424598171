import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';
import CountUp, { useCountUp } from 'react-countup';

const AboutMembership1 = ( prevPath ) => {

  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)

  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  useEffect(() => {
    // code to run after render goes here
        

  }, []); // <-- empty array means 'run once'

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 

  // consume theme context
  // const { pages } = useContext(ThemeContext)
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);


  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }
  
  return (
    <motion.div id="about-membership-1" className="content-block wide"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

      <h1 className="mb-6">{lang.title_about_membership1}</h1>

      <div className="d-grid-2x2 triple-gap-v d-title-twoline text-center mb-4">
          {/* <div><img src={placeholder}/><h4>2.000+</h4><h5>Lorem sit amet</h5></div>
          <div><img src={placeholder}/><h4>1.300+</h4><h5>Ipsum amet</h5></div>
          <div><img src={placeholder}/><h4>260+</h4><h5>Consequetur amet</h5></div>
          <div><img src={placeholder}/><h4>10.000+</h4><h5>Amet amet amet</h5></div> */}
          <div>
          <img src={lang.image_members_card1}/>
          
          <CountUp 
          start={0}
          end={lang.number_members_card1}
          duration={2.0}
          separator={lang.decimal}
          decimals={0}
          decimal={lang.decimal}
          prefix=""
          suffix="+"
          delay={0}
          >
            {({ countUpRef }) => (
              <h4>
                <span ref={countUpRef} />
              </h4>
            )}
        </CountUp>
        <h5>{lang.text_members_card1}</h5>

          </div>

          <div>
          <img src={lang.image_members_card2}/>
          
          <CountUp 
          start={0}
          end={lang.number_members_card2}
          duration={2.0}
          separator={lang.decimal}
          decimals={0}
          decimal={lang.decimal}
          prefix=""
          suffix="+"
          delay={0}
          >
            {({ countUpRef }) => (
              <h4>
                <span ref={countUpRef} />
              </h4>
            )}
        </CountUp>
        <h5>{lang.text_members_card2}</h5>

          </div>

          <div>
          <img src={lang.image_members_card3}/>
          
          <CountUp 
          start={0}
          end={lang.number_members_card3}
          duration={2.0}
          separator={lang.decimal}
          decimals={0}
          decimal={lang.decimal}
          prefix=""
          suffix="+"
          delay={0}
          >
            {({ countUpRef }) => (
              <h4>
                <span ref={countUpRef} />
              </h4>
            )}
        </CountUp>
        <h5>{lang.text_members_card3}</h5>

          </div>

          <div>
          <img src={lang.image_members_card4}/>
          
          <CountUp 
          start={0}
          end={lang.number_members_card4}
          duration={2.0}
          separator={lang.decimal}
          decimals={0}
          decimal={lang.decimal}
          prefix=""
          suffix="+"
          delay={0}
          >
            {({ countUpRef }) => (
              <h4>
                <span ref={countUpRef} />
              </h4>
            )}
        </CountUp>
        <h5>{lang.text_members_card4}</h5>

          </div>                    

      </div>
   

    </motion.div>
  )
}


export default AboutMembership1;