import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';


const ActivitiesResearchDevelopment = ( prevPath ) => {


  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);
  
  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }

  return (
    <motion.div id="activities-2" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_2}/>
      <div className={"b-caption "+lang.credit_activities_2.color}>{lang.credit_activities_2.text}</div>      
    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_2}/> */}
    <svg version="1.1" id="d-icon-research" className="icon-round-large" x="0px" y="0px"
          viewBox="0 0 36.9 36.9">
        <circle className="blue" cx="18.4" cy="18.4" r="18.4"/>
        <path className="white" d="M21.3,7.4h0.9V5.3h-6.8v2.1h0.9v8.3c0,2.6-6.9,7.6-6.9,11.1c-0.1,1.9,1.1,3.7,3,4.3h13c0,0,2.3-0.8,2.3-4.3
          s-6.3-8.5-6.3-11.1C21.3,15.6,21.3,7.4,21.3,7.4z"/>
        <path className="blue" d="M16.7,17.9c0,1.9-5.4,5.6-5.4,8.1c0,1.5,0.9,2.8,2.3,3.2h10.1c1.2-0.6,1.9-1.8,1.8-3.2c0-2.6-4.9-6.2-4.9-8.1
          L16.7,17.9z"/>
        <circle className="d-icon-bubble d-icon-bubble-1" cx="16.5" cy="22.5" r="1.25"/>
        <circle className="d-icon-bubble d-icon-bubble-2" cx="18" cy="27" r="0.85"/>
        <circle className="d-icon-bubble d-icon-bubble-3" cx="22" cy="25" r="0.9"/>
        </svg>

    <h1>{lang.title_activities_2}</h1>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_2}}></div>

    {/* <div className="debug-box">
    <div onClick={() => {toggleWhereAreWeGoing('exitRight')}}>whereAreWeGoing = exitRight</div>
    <div onClick={() => {toggleWhereAreWeGoing('exitLeft')}}>whereAreWeGoing = exitLeft</div>
    {whereAreWeGoing}
    </div> */}
    </motion.div>
  )
}

export default ActivitiesResearchDevelopment;