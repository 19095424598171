import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';
import Modal from './../../elements/Modal';

const ActivitiesTechnicalInformation = ( prevPath ) => {

  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  const [showModal, setShowModal] = useState(false)

  const toggleModal = (id, e) => {
    // e.preventDefault()
    
    let x = (e.clientX < 240) ? 240 : e.clientX;
    let y = (e.clientY < 120) ? 120 : e.clientY;

    lang.modal_activities[id].x = x;
    lang.modal_activities[id].y = y;

    setModalContent(lang.modal_activities[id])
   
    setShowModal(!showModal)
  }

  const hideModal = () => {
    setShowModal(false)
    setModalContent("banana");
  }

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);

  const [modalContent, setModalContent] = useState('banana');
      
  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }

  
  return (
    <motion.div id="activities-6" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <Modal showModal={showModal} content={modalContent} toggle={hideModal} />

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_6}/>
      <div className={"b-caption "+lang.credit_activities_6.color}>{lang.credit_activities_6.text}</div>
      <div className="hotspots">
      <div>
        <div className={"hotspot ti1"+ (modalContent.id == 1 ? ' active' : '')}></div>
        <div className="hotspot-clicktarget ti1" onClick={(e) => toggleModal(0,e) }></div>
        <div className={"hotspot ti2"+ (modalContent.id == 2 ? ' active' : '')}></div>
        <div className="hotspot-clicktarget ti2" onClick={(e) => toggleModal(1,e) }></div>
        <div className={"hotspot ti3"+ (modalContent.id == 3 ? ' active' : '')}></div>
        <div className="hotspot-clicktarget ti3" onClick={(e) => toggleModal(2,e) }></div>
        <div className={"hotspot ti4"+ (modalContent.id == 4 ? ' active' : '')}></div>
        <div className="hotspot-clicktarget ti4" onClick={(e) => toggleModal(3,e) }></div>
        <div className={"hotspot ti5"+ (modalContent.id == 5 ? ' active' : '')}></div>
        <div className="hotspot-clicktarget ti5" onClick={(e) => toggleModal(4,e) }></div>
      </div>
      </div>

    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_6}/> */}

    
<svg version="1.1" id="svg-activities-ti" className="icon-round-large" x="0px" y="0px" viewBox="0 0 36.9 36.9" xmlSpace="preserve">

  <circle className="blue" cx="18.4" cy="18.4" r="18.4"/>


<path className="blue" d="M1.5,12.3h33.9C32.9,5.4,26.2,0.4,18.4,0.4C10.6,0.4,4,5.4,1.5,12.3z"/>
<polygon className="white" points="10.2,11.6 26.6,11.6 25,7.8 11.8,7.8 "/>
<g className="drawer">
<polygon className="white" points="29.7,18.7 27.6,13.9 27.6,18.5 9.2,18.5 9.2,13.9 7.2,18.7 7.2,18.7 7.2,27.3 29.7,27.3 29.7,18.7 "/>
<polygon className="blue" points="23,24.2 23,20.7 21.7,20.7 21.7,22.9 15.1,22.9 15.1,20.7 13.8,20.7 13.8,24.2 23,24.2 "/>
</g>

</svg>


    <h1 className="mb-0p5">{lang.title_activities_6}</h1>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_6}}></div>

    </motion.div>
  )
}

export default ActivitiesTechnicalInformation;