import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

const modal = {
  hidden: { scale:0.9, opacity: 0 },
  visible: { 
    scale:1,
    opacity: 1,
    x: 0,
    y: 0,
    transition: { delay: 0.05 }
  },
}

const Modal = ({ showModal, content, toggle }) => {

  return (
    <AnimatePresence>
      { showModal && (
        <motion.div className={'backdrop '+content.type}
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.div className="modal"
            variants={{
              hidden: { 
                scale:0.9, 
                opacity: 0,
                x: content.x-240+12.5+content.offset[0],
                y: content.y-120+content.offset[1]
               },
              visible: { 
                scale:1,
                opacity: 1,
                x: content.x-240+12.5+content.offset[0],
                y: content.y-120+content.offset[1],
                transition: { delay: 0.05, type: 'spring', stiffness: 100, damping: 300, duration: 0.2 }
              }}}
          >
            <div className="modal-header">
              <h1 dangerouslySetInnerHTML={{__html: content.title}}></h1>
              <img src='../../dvgw-button-close.svg' onClick={toggle}/>
            </div>
            <div className="modal-content">

            <ul className="arrow-list">
              
              { content.list.map(function(item) {
                        return <li key={item}>{item}</li>
                    })
              }
              
            </ul>

            <div dangerouslySetInnerHTML={{__html: content.text}}></div>

            </div>

            <div className={'modal-footer showFooter-'+content.showFooter}>
              <img src={content.logo}/>
              <img src={content.qr}/>
            </div>

          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal;