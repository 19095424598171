import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';

const ActivitiesRulesettingStandardization = ( prevPath ) => {

  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);
  
  
  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }

  return (
    <motion.div id="activities-1" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_1}/>
      <div className={"b-caption "+lang.credit_activities_1.color}>{lang.credit_activities_1.text}</div>
    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_1}/> */}
    <svg id="d-icon-rs" className="icon-round-large" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.85039 36.85039">
    
    <circle className="blue" cx="18.4" cy="18.4" r="18.4"/>
    <g className="rs-line1">
    <rect className="white" x="14.24499" y="12.0068" width="1.36914" height="1.36914"/>
    <rect className="white" x="16.98327" y="12.04098" width="5.62207" height="1.30078"/>
    </g>
    <g className ="rs-line2">    
    <rect className="white " x="14.24499" y="15.36221" width="1.36914" height="1.36908"/>
    <rect className="white" x="16.98327" y="15.39639" width="5.62207" height="1.30078"/>
    </g>
    <g className="rs-line3">
    <rect className="white" x="14.24499" y="18.72689" width="1.36914" height="1.36914"/>
    <rect className="white" x="16.98327" y="18.76107" width="5.62207" height="1.30078"/>
    </g>
    <path className="white" d="M29.29193,23.65152V8.44522H7.55847v15.2063h7.85425V27.1044H12.75531v1.30078H24.09515V27.1044H21.43774V23.65152Zm-20.43341-1.3V9.7452H27.99194V22.35153Z"/>
    {/* <circle className="green" cx="18.4" cy="16" r="1"/> */}
    </svg>

    

    <h1>{lang.title_activities_1}</h1>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_1}}></div>

    <img className="image-activities-1" src={lang.image_activities_2} />


    </motion.div>
  )
}

export default ActivitiesRulesettingStandardization;