import React, {useRef} from "react"
import { useFrame } from 'react-three-fiber';

export default function Spawned(props) {

  const refMesh = useRef()

  
  useFrame(() => {
    if(refMesh.current) {
      // rotates the object
      //refMesh.current.y += deltatime
      let age = (Date.now()-props.time)/1000;

      refMesh.current.position.y += 0.03+props.size/10+age/16;
      refMesh.current.position.x += Math.sin((Date.now()-props.time)/1000-(props.time%230)) * 0.1 * age/8;
      
      // console.log(age/40);
      

      if(refMesh.current.position.y > 40){
        // this should get deleted, but how?
        
      }
    }
  });

  return (
    
    <mesh {...props} ref={refMesh}>
      <sphereGeometry attach="geometry" args={[props.size*0.5, 12, 12]} />
{/* //      <meshStandardMaterial attach="material" color="#3366ef" transparent /> */}
      <meshBasicMaterial attach="material" color={props.color} />

    </mesh>
  )
}
