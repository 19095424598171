import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';


const ActivitiesOccupationalHealthSafety = ( prevPath ) => {


  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);
  
  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }


  return (
    <motion.div id="activities-8" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_8}/>
      <div className={"b-caption "+lang.credit_activities_8.color}>{lang.credit_activities_8.text}</div>      
    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_8}/> */}
    <svg id="Ebene_1" className="icon-round-large" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.85039 36.85039">
    {/* <defs><style>.cls-1{fill:#002b55;}.cls-2{fill:none;}.cls-3{fill:#fff;}</style></defs> */}
    <circle className="blue" cx="18.4252" cy="18.42519" r="18.4042"/>
    
    <path className="white svg-hat" d="M12.9735,19.28585H24.00475l1.19208,3.09626H11.7813Zm2.4068-6.25091-1.208,3.13733H22.806l-1.20789-3.13733Zm2.30938-5.99781L16.57915,9.92129h3.82013L19.28887,7.03713ZM7.70611,25.14123v2.40428H29.14429V25.14123Z"/>
    {/* <circle className="green" cx="8" cy="27.5" r="1"/> */}
    </svg>

    <h1 className='d-narrow-spacing'>{lang.title_activities_8}</h1>
    <h2>{lang.subtitle_activities_8}</h2>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_8}}></div>

    </motion.div>
  )
}

export default ActivitiesOccupationalHealthSafety;