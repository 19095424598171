import React from 'react'
import useCanvas from './../hooks/useCanvas';


const WaterCanvas = props => {  

  const { draw, lastClick, options, ...rest } = props
  const { context  } = options
  const canvasRef = useCanvas(draw, lastClick, {context})

  return <canvas ref={canvasRef} {...rest}/>
}

export default WaterCanvas
