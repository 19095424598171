import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation } from 'react-router-dom';
import Modal from '../../elements/Modal';

const AboutResearch = ( prevPath ) => {

  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)

  const [showModal, setShowModal] = useState(false)

  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  const toggleModal = (id, e) => {
    // e.preventDefault()
    // console.log("toggleModal "+e.clientX);
    let x = (e.clientX < 240) ? 240 : e.clientX;
    let y = (e.clientY < 120) ? 120 : e.clientY;

    lang.modal_about_research[id].x = x;
    lang.modal_about_research[id].y = y;

    setModalContent(lang.modal_about_research[id]);
    
    setShowModal(!showModal)
  }

  const hideModal = () => {
    setShowModal(false)
    setModalContent("banana");
  }

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 

  // get location
  const location = useLocation()

  const [modalContent, setModalContent] = useState('banana');

  // let exitString = "exit"
  let initString = "hidden"

  if(prevPath.location.state){
    if(prevPath.location.state.direction === 'back'){
      initString = "enterLeft"
    }else if(prevPath.location.state.direction === 'forward'){
      initString = "enterRight"
    }
  }
  
  return (
    <motion.div id="about-research" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >
      <Modal showModal={showModal} content={modalContent} toggle={hideModal} />
      <div className="billboard mb-4">
      <svg version="1.1" id="svg-research" x="0px" y="0px" viewBox="0 0 2160 2060" >
        
        <path className="st0" d="M914.8,216.3c6.5-1.9,13-4.6,19.5-7.4c6.5-3.7,12.1-7.4,17.7-11.2c5.6,9.3,14.9,15.8,26,15.8
          c6.5,1.9,13,5.6,17.7,9.3c9.3-4.6,12.1-3.7,15.8,8.4c1.9,2.8,3.7,5.6,5.6,8.4c1.9,2.8,2.8,4.6,3.7,7.4c-5.6,1.9-10.2,2.8-16.7,3.7
          c6.5,2.8,14.9,4.6,14.9,7.4c0,7.4-0.9,14.9-3.7,22.3c-1.9,3.7-3.7,7.4-6.5,11.2c11.2,3.7,32.5-12.1,31.6,15.8c0.9,0,1.9,0.9,1.9,0.9
          c9.3-9.3,18.6-4.6,27.9,0c10.2,6.5,21.4,13,32.5,18.6c6.5,2.8,14.9,1.9,21.4-0.9c8.4-4.6,12.1-16.7,26-11.2
          c1.9,0.9,6.5-2.8,10.2-3.7c-0.9-2.8-1.9-5.6-2.8-5.6c-14.9-1.9-6.5-12.1-5.6-18.6c0.9-9.3,9.3-6.5,13.9-5.6c13.9,0.9,25.1,13,26,27
          c0,1.9-3.7,4.6-5.6,4.6c-4.6,0-9.3-0.9-13.9-2.8c-11.2,13.9-10.2,27.9-7.4,42.8c0,2.8,0,6.5-1.9,8.4c-7.4,8.4-15.8,16.7-25.1,25.1
          c-2.8,1.9-8.4,0.9-13,1.9c-6.5,6.5-2.8,11.2,4.6,17.7c6.5,6.5,17.7,6.5,24.2,0.9c9.3-4.6,18.6-10.2,28.8-2.8
          c3.7,2.8,7.4,6.5,10.2,10.2c3.7,0,7.4,0.9,12.1,0.9c-1.9-3.7-2.8-6.5-3.7-9.3c0.9-0.9,0.9-1.9,0.9-1.9c11.2-3.7,16.7-13.9,24.2-22.3
          c9.3-11.2,22.3-17.7,36.3-18.6c13-1.9,25.1-3.7,37.2-6.5c1.9,0,3.7-0.9,5.6-2.8c14.9-18.6,29.8-38.1,44.6-57.6
          c2.8-5.6,10.2-9.3,16.7-6.5c9.3,0.9,18.6,1.9,28.8,1.9c6.5,0.9,13,3.7,19.5,3.7c3.7,0,8.4-0.9,11.2-2.8c3.7,14.9,11.2,18.6,25.1,13
          c-2.8-3.7-7.4-7.4-7.4-11.2s5.6-7.4,9.3-12.1c-15.8-1.9-15.8-2.8-9.3-13.9c0.9-0.9,0-2.8,0-3.7c4.6-21.4,13.9-28.8,38.1-27.9
          c-1.9,2.8-3.7,5.6-5.6,9.3c-2.8,6.5,3.7,12.1,10.2,12.1c10.2,0,19.5,0.9,29.8,0.9c0.9,0.9,0.9,2.8,1.9,3.7
          c-2.8,5.6-6.5,11.2-10.2,16.7c-4.6,3.7-4.6,11.2-0.9,15.8c0.9,0.9,2.8,1.9,4.6,2.8c13.9,6.5,15.8,11.2,13,26
          c-0.9,2.8-2.8,6.5-3.7,6.5c-3.7-0.9-7.4-1.9-11.2-4.6c-2.8-3.7-4.6-6.5-6.5-11.2c-8.4,4.6-15.8,9.3-24.2,13.9
          c7.4,13.9,3.7,17.7-12.1,16.7c2.8,3.7,5.6,8.4,6.5,7.4c11.2-2.8,10.2,6.5,14.9,13c13-6.5,27.9-5.6,40.9-14.9
          c4.6-2.8,6.5-1.9,10.2,2.8c5.6,7.4,13,14.9,20.5,20.4c12.1,10.2,26,18.6,37.2,27c0,5.6-0.9,10.2-2.8,14.9c-1.9,2.8-4.6,4.6-7.4,4.6
          c-12.1,2.8-23.2,5.6-38.1,9.3c13.9,12.1,26,19.5,42.8,16.7c10.2-1.9,13.9,1.9,15.8,14.9c6.5,30.7,15.8,61.3,27.9,91.1
          c0,4.6,0,9.3-1.9,13c-1.9,4.6-2.8,10.2-3.7,14.9c0.9,14.9,0.9,29.7-14.9,38.1c-1.9,0.9-2.8,1.9-4.6,2.8c-8.4,2.8-13,13-10.2,21.4
          c0,0,0,0,0,0.9c0.9,4.6,0.9,9.3,0.9,13.9c8.4,5.6,15.8,11.2,22.3,16.7c13,10.2,25.1,21.4,38.1,31.6c10.2,8.4,15.8,26,8.4,37.2
          c-10.2,14.9-2.8,27,2.8,40c1.9,3.7,7.4,5.6,11.2,7.4c4.6,0.9,8.4,5.6,7.4,10.2v0.9c-1.9,10.2-6.5,21.4,5.6,29.7
          c0.9,0.9,0.9,4.6,0.9,7.4c-3.7,17.7-0.9,38.1-19.5,51.1c9.3,12.1,17.7,25.1,26,39c1.9,6.5,1.9,13-0.9,18.6
          c-2.8,5.6-2.8,10.2,2.8,12.1c23.2,5.6,31.6,23.2,40.9,42.8c7.4,15.8,0.9,27.9,0.9,41.8c0,27.9-13.9,51.1-23.2,76.2
          c-0.9,1.9-1.9,4.6-2.8,4.6c-9.3-0.9-18.6-3.7-27-6.5c-2.8-0.9-2.8-7.4-4.6-13.9c-4.6,0-7.4-3.7-5.6-11.2c2.8-8.4-3.7-10.2-10.2-11.2
          c-9.3-0.9-18.6-1.9-29.8-2.8c0,0.9-0.9,4.6-2.8,7.4c6.5,3.7,13,8.4,18.6,13.9c3.7,3.7,1.9,9.3-4.6,11.2c-22.3,3.7-39.1,21.4-61.4,26
          c-1.9,0-3.7,4.6-3.7,7.4c-0.9,9.3-5.6,13-14.9,12.1c-2.8,0-5.6,0-8.4,0c-14.9-0.9-27,0.9-32.5,18.6c-3.7,13-5.6,13-20.5,4.6
          c-4.6,5.6-6.5,13.9-16.7,13c-1.9,0-3.7,3.7-5.6,6.5c-2.8,8.4-11.2,13.9-19.5,11.2c-1.9,0-4.6,0-6.5,0.9c0,2.8,0,5.6-0.9,8.4
          c-0.9,8.4-7.4,13.9-15.8,13c-1.9,0-3.7-0.9-5.6-1.9c-8.4-4.6-18.6-3.7-24.2,3.7c-5.6,2.8-11.2,4.6-17.7,3.7c-7.4,0-13.9,0-13.9,9.3
          c0,1.9-0.9,3.7-2.8,4.6c-16.7,5.6-15.8,21.4-19.5,34.4c-0.9,2.8-3.7,4.6-6.5,4.6c-1.9,0-4.6-3.7-5.6-5.6
          c-3.7-5.6-7.4-10.2-11.2-14.9c-1.9-1.9-6.5,0.9-11.2,0.9c5.6,6.5,9.3,10.2,13,14.9c0.9,0.9,2.8,3.7,2.8,4.6
          c-5.6,13,5.6,19.5,12.1,26c6.5,4.6,13.9,8.4,20.5,12.1c4.6,2.8,13,2.8,10.2,13c0,1.9,6.5,5.6,11.2,8.4c-2.8,3.7-5.6,7.4-7.4,12.1
          c-1.9,4.6-2.8,8.4-3.7,13c0,2.8-0.9,4.6-1.9,7.4c-4.6,3.7-5.6,10.2-0.9,14.9c0.9,0.9,2.8,2.8,3.7,2.8c2.8,2.8,5.6,5.6,7.4,9.3
          c1.9,1.9,2.8,4.6,3.7,6.5c0.9,0.9,2.8,0.9,3.7,1.9c3.7,10.2,7.4,20.4,12.1,31.6c0.9,1.9,0,5.6,1.9,5.6c11.2,6.5,18.6,20.4,32.5,21.4
          c9.3,0,17.7,3.7,23.2,11.2c6.5,7.4,13,13.9,19.5,21.4c3.7,4.6,7.4,10.2,12.1,17.7c18.6,0,28.8,13,34.4,31.6
          c0.9,4.6,3.7,10.2,12.1,6.5c9.3-2.8,19.5,1.9,24.2,10.2c1.9,1.9,3.7,5.6,5.6,5.6c10.2-0.9,13,7.4,15.8,13.9
          c2.8,6.5,4.6,13.9,5.6,20.4c0.9,5.6,1.9,12.1,1.9,18.6c1.9,13-6.5,23.2-13,32.5c-2.8,1.9-6.5,2.8-10.2,1.9
          c-6.5-2.8-13-7.4-19.5-10.2c-2.8-0.9-5.6-0.9-8.4,0c-2.8,1.9-3.7,5.6-3.7,9.3c1.9,23.2-4.6,42.8-25.1,55.8c-1.9,1.9-4.6,2.8-7.4,2.8
          c-13,0-26,5.6-35.3,13.9c-10.2,7.4-20.5,14.9-29.8,22.3c-4.6,2.8-6.5,8.4-3.7,13.9c0.9,0.9,1.9,2.8,2.8,3.7
          c9.3,8.4,13,21.4,25.1,28.8c10.2,6.5,11.2,23.2,6.5,34.4c-1.9,4.6-4.6,8.4-5.6,12.1c7.4,1.9,14.9,4.6,22.3,8.4
          c6.5,2.8,9.3,10.2,6.5,16.7c-0.9,1.9-1.9,2.8-2.8,3.7c-4.6,4.6-6.5,12.1-4.6,17.7c0.9,2.8,0.9,4.6,0.9,7.4c0,9.3-8.4,15.8-15.8,10.2
          c-10.2-5.6-19.5-13-27.9-20.5c-1.9-1.9,0-8.4,0-14.9c-4.6-4.6-10.2-14.9-22.3-10.2c-2.8,0.9-5.6,2.8-8.4,5.6
          c-8.4,8.4-17.7,7.4-23.2-2.8c-5.6-9.3-13.9-5.6-19.5-3.7c-9.3,2.8-13,0.9-14.9-8.4c-9.3,2.8-9.3,7.4-6.5,14.9
          c4.6,13,3.7,13.9-10.2,12.1c-13-1.9-27,0-39.1,6.5c-8.4,2.8-16.7,2.8-25.1,0c-9.3-1.9-14.9-0.9-17.7,9.3c-0.9,6.5-6.5,7.4-12.1,7.4
          c-9.3,0-18.6-0.9-16.7,13c0,1.9-0.9,2.8-1.9,3.7c-6.5,4.6-13.9,7.4-20.5,11.2c-2.8,0.9-7.4-1.9-11.2-3.7c-9.3,0.9-19.5,2.8-29.8,4.6
          c-7.4,0.9-9.3-2.8-11.2-8.4c-2.8-9.3-3.7-9.3-13-12.1c0.9-1.9,1.9-3.7,1.9-5.6c0.9-1.9,0.9-2.8,2.8-5.6c-1.9,0-3.7,0-5.6,0.9
          c-7.4,4.6-17.7,3.7-24.2-2.8c-5.6-4.6-12.1-10.2-18.6,0.9c-0.9,1.9-10.2,0-18.6-0.9c0,4.6,0.9,10.2,1.9,14.9
          c1.9,9.3-0.9,18.6-8.4,24.2c-2.8,0.9-4.6,2.8-6.5,4.6c-10.2,13.9-13,15.8-36.3,19.5c2.8-9.3,5.6-17.7,9.3-28.8
          c-8.4,4.6-13,7.4-22.3,13c0-9.3,0-15.8,0-21.4c-0.9-2.8-1.9-5.6-2.8-7.4c0-0.9-0.9-0.9-1.9-0.9c-11.2-7.4-16.7-23.2-34.4-16.7
          c-1.9,0.9-4.6-3.7-8.4-6.5c-3.7,10.2-9.3,11.2-17.7,3.7c-7.4-6.5-15.8-12.1-24.2-17.7c-3.7-2.8-7.4-4.6-12.1-6.5
          c-10.2-4.6-21.4-8.4-31.6-12.1c-0.9,2.8,0,8.4-2.8,10.2c-1.9,1.9-7.4,0-11.2-0.9c-11.2-3.7-22.3-2.8-33.5,0.9
          c-13,4.6-31.6-6.5-36.3-19.5c-1.9-4.6-4.6-10.2-12.1-5.6c-5.6-8.4-13-9.3-20.5-0.9c-2.8,3.7-6.5,6.5-8.4,11.2
          c-0.9,2.8-0.9,5.6,0.9,8.4c0.9,1.9,5.6,1.9,8.4,1.9s5.6-1.9,8.4-2.8c3.7,0,8.4-0.9,10.2,0.9c1.9,0.9,0.9,6.5,0,10.2
          c-3.7,12.1-5.6,12.1-16.7,4.6c-4.6,10.2-15.8,14.9-26,12.1c-2.8-0.9-4.6-2.8-5.6-4.6c-5.6-9.3-12.1-11.2-20.5-4.6
          c-10.2,7.4-22.3,12.1-35.3,13c-2.8,0-7.4-0.9-8.4-2.8c-4.6-12.1-12.1-7.4-18.6-2.8c-6.5,4.6-13,9.3-20.5,1.9
          c-6.5-4.6-12.1-10.2-16.7-16.7c-4.6-7.4-2.8-16.7-3.7-25.1c-1.9-20.4,6.5-36.3,13.9-53c0.9-2.8,0.9-6.5,0-9.3
          c-6.5-13.9-5.6-30.7,1.9-43.7c4.6-8.4,7.4-18.6,11.2-27.9c1.9-2.8,5.6-4.6,6.5-5.6c0.9-11.2,2.8-22.3,6.5-33.5
          c9.3-18.6,13.9-39,28.8-54.8c7.4-8.4,12.1-17.7,23.2-21.4c1.9-0.9,3.7-3.7,4.6-5.6c4.6-10.2,9.3-19.5,13.9-28.8
          c-10.2-6.5-21.4-13-32.5-20.4c-1.9-0.9-2.8-1.9-4.6-1.9c-13.9,0.9-27.9,0-41.8-1.9c-10.2-3.7-19.5-9.3-27-16.7
          c-1.9-1.9-1.9-6.5-3.7-13c-7.4,2.8-13.9,6.5-20.5,11.2c-10.2,7.4-18.6,0.9-27.9,0c-4.6-0.9-8.4-1.9-13-1.9
          c-4.6-6.5-2.8-18.6-14.9-20.4c-3.7,0-7.4,0-11.2,0c-0.9,7.4,0,16.7-10.2,14.9c-14.9-1.9-19.5-2.8-19.5-14.9c0-3.7-0.9-7.4-2.8-11.2
          c-12.1-14.9-13-36.3-29.8-47.4c-1.9-1.9-3.7-2.8-6.5-3.7c-13,0-13-12.1-11.2-17.7c3.7-15.8,11.2-30.7,22.3-41.8
          c4.6-4.6,4.6-14.9,7.4-24.2c-13,2.8-26-1.9-33.5-12.1c-12.1-10.2-20.5-24.2-24.2-40c-1.9-13.9,0-27.9,7.4-39
          c5.6-11.2,19.5-13.9,22.3-27c0.9-2.8,6.5-4.6,10.2-6.5c0-5.6,0-11.2,0-16.7c1.9-11.2-0.9-14.9-12.1-15.8c-10.2-0.9-13-6.5-7.4-15.8
          c0.9-0.9,1.9-2.8,1.9-3.7c1.9-3.7,3.7-8.4,5.6-11.2c-13.9-15.8-34.4-25.1-39-49.3c2.8-2.8,7.4-7.4,12.1-11.2
          c5.6-4.6,6.5-7.4-0.9-12.1c-2.8-1.9-4.6-6.5-7.4-11.2c1.9,0,0.9,0,0,0c-14.9-0.9-18.6-4.6-16.7-19.5c0.9-6.5,5.6-7.4,13.9-4.6
          c10.2-6.5,19.5-13,29.8-20.4c-16.7-7.4-17.7-14.9-6.5-27.9c1.9-2.8,3.7-4.6,6.5-7.4c16.7-18.6,16.7-36.3,2.8-57.6
          c-3.7-6.5-5.6-13-7.4-20.4c-1.9-4.6-4.6-9.3-6.5-13c-0.9-1.9-2.8-3.7-3.7-5.6c-1.9-3.7-2.8-7.4-4.6-11.2c0-0.9,0.9-2.8,0-3.7
          c-0.9-3.7-3.7-10.2-2.8-11.2c4.6-3.7,10.2-7.4,15.8-10.2c2.8-1.9,6.5-3.7,10.2-4.6c3.7-0.9,7.4-0.9,11.2,0
          c7.4,1.9,17.7,9.3,22.3,7.4c10.2-5.6,18.6,6.5,27.9,0.9c8.4-4.6,18.6-5.6,27-9.3c3.7-1.9,6.5-5.6,8.4-9.3c3.7-4.6,2.8-8.4-3.7-12.1
          c-3.7-1.9-9.3-8.4-8.4-9.3c3.7-6.5,8.4-15.8,13.9-16.7c8.4-0.9,14.9-5.6,19.5-12.1c4.6-4.6,10.2-9.3,13.9-13.9
          c1.9-7.4,3.7-14.9,4.6-23.2c0-7.4-1.9-15.8-3.7-23.2c-16.7,6.5-26-4.6-38.1-7.4c-9.3-1.9-11.2-8.4-9.3-15.8
          c0.9-3.7,4.6-6.5,6.5-10.2c0-4.6-1.9-11.2,0-14.9c4.6-6.5,12.1-8.4,18.6-4.6c1.9,0.9,3.7,0.9,6.5,0.9c20.5,4.6,21.4,4.6,23.2-14.9
          c3.7-18.6,9.3-36.3,16.7-53c2.8-7.4,4.6-15.8,5.6-24.2c1.9-9.3-5.6-19.5,2.8-29.7c3.7-4.6-0.9-10.2-7.4-10.2s-9.3-2.8-8.4-10.2
          c0-2.8-0.9-4.6-2.8-6.5c-7.4-6.5-10.2-16.7-7.4-26c2.8-9.3,4.6-19.5,7.4-30.7c3.7,0.9,6.5,1.9,10.2,2.8
          c-4.6-11.2,0.9-23.2,11.2-27.9c10.2-6.5,23.2-9.3,35.3-8.4c11.2,0.9,22.3,0,33.5-0.9c13.9-0.9,27-2.8,40.9-3.7
          c5.6,0,8.4,1.9,8.4,7.4c0.9,3.7,4.6,7.4,7.4,11.2c1.9,1.9,3.7,4.6,5.6,7.4c2.8,5.6,5.6,11.2,8.4,16.7c0.9-0.9,2.8-0.9,2.8-0.9
          c7.4-18.6,9.3-18.6,26-8.4c5.6,4.6,13,7.4,19.5,9.3c-0.9-2.8-1.9-6.5-3.7-9.3c-7.4-9.3-9.3-22.3-4.6-33.5
          c3.7-10.2,9.3-20.4,13.9-29.7c1.9-6.5,9.3-10.2,15.8-8.4c2.8,0.9,4.6,2.8,6.5,4.6c9.3,8.4,21.4,12.1,33.5,10.2
          c5.6,0,10.2-6.5,15.8-10.2l-0.9-0.9c-15.8,3.7-25.1-3.7-29.8-18.6c-2.8-5.6-5.6-12.1-9.3-17.7c7.4-0.9,13.9-1.9,20.5-1.9
          c-0.9-8.4,0-14.9-11.2-16.7c-7.4-1.9-11.2-9.3-9.3-16.7c0-0.9,0-0.9,0.9-1.9c1.9-6.5,2.8-12.1,3.7-18.6c-6.5,0.9-13,1.9-19.5,3.7
          c-8.4,2.8-12.1-3.7-14.9-8.4c-1.9-2.8,0-9.3,1.9-13c0.9-1.9,5.6-1.9,8.4-1.9c-3.7-8.4,0.9-11.2,9.3-12.1c9.3-0.9,17.7-2.8,26-3.7
          c0-0.9,0-2.8,0-3.7c-2.8,0-4.6,0-7.4-0.9c-4.6-0.9-8.4-4.6-7.4-9.3c0-1.9,0.9-3.7,1.9-4.6c9.3-13.9,8.4-20.4,0-29.7
          c-13-15.8-23.2-34.4-29.8-53.9c-10.2,0.9-19.5,13.9-32.5,0.9c-1.9,9.3-3.7,17.7-4.6,26c-10.2-13,1.9-64.1,16.7-70.6
          c1.9,3.7,2.8,6.5,3.7,8.4c-2.8,4.6-5.6,10.2-7.4,15.8c-1.9,9.3,5.6,14.9,14.9,13c30.7-6.5,60.4-0.9,90.2,5.6
          c1.9,4.6,6.5,11.2,11.2,14.9C915.7,213.6,914.8,215.4,914.8,216.3"/> 
        <text x="1080.5" y="1965" className="st1 st2 st3">8</text>
        <circle id="svg-research-c1" className={"st5"+ (modalContent.id == 8 ? ' c-active' : '')} cx="955.5" cy="502" r="30" />
        {/* <circle className="st4no" cx="955.5" cy="502" r="18" /> */}
        <circle id="c1_clicktarget" className="sti" cx="955.5" cy="502" r="80" onClick={(e) => toggleModal(0,e) }/>

        <circle id="svg-research-c2" className={"st4"+ (modalContent.id == 5 ? ' c-active' : '')} cx="700.5" cy="936" r="30" />
        <circle id="c2_clicktarget" className="sti" cx="700.5" cy="916" r="80" onClick={(e) => toggleModal(1,e) }/>

        <circle id="svg-research-c3" className={"st5"+ (modalContent.id == 9 ? ' c-active' : '')} cx="597.5" cy="1035" r="30"/>
        <circle id="c3_clicktarget" className="sti" cx="587.5" cy="1025" r="80" onClick={(e) => toggleModal(2,e) }/>

        {/* <circle id="svg-research-c4" className={"st5"+ (modalContent.id == 4 ? ' c-active' : '')} cx="567.5" cy="1151" r="30" /> */}
        <circle id="svg-research-c5" className={"st5"+ (modalContent.id == 1 ? ' c-active' : '')} cx="631.5" cy="1151" r="30" /> 
        <circle className="st4 no-b" cx="631.5" cy="1151" r="18" />
        <ellipse id="c4_5_clicktarget" className="sti" cx="601.5" cy="1171" rx="120" ry="80" onClick={(e) => toggleModal(3,e) }/>

        <circle id="svg-research-c6" className={"st5"+ (modalContent.id == 2 ? ' c-active' : '')} cx="782.5" cy="1599" r="30" />
        <circle className="st4no" cx="782.5" cy="1599" r="18" />
        <circle id="svg-research-c7" className={"st5"+ (modalContent.id == 2 ? ' c-active' : '')} cx="846.5" cy="1599" r="30" />
        {/* <circle id="svg-research-c8" className={"st4"+ (modalContent.id == 5 ? ' c-active' : '')} cx="910.5" cy="1599" r="30" /> */}
        <ellipse id="c5_6_clicktarget" className="sti" cx="717" cy="1599" rx="100" ry="100" onClick={(e) => toggleModal(4,e) }/>
        <ellipse id="c7_clicktarget" className="sti" cx="911" cy="1599" rx="100" ry="100" onClick={(e) => toggleModal(5,e) }/>

        <circle id="svg-research-c9" className={"st4"+ (modalContent.id == 3 ? ' c-active' : '')} cx="1403.5" cy="1065" r="30"/>
        <circle id="c9_clicktarget" className="sti" cx="1390.5" cy="1055" r="80" onClick={(e) => toggleModal(6,e) }/>
        
        <circle id="svg-research-c10" className={"st4"+ (modalContent.id == 4 ? ' c-active' : '')} cx="1497.5" cy="1125" r="30"/>
        <circle id="c10_clicktarget" className="sti" cx="1497.5" cy="1135" r="80" onClick={(e) => toggleModal(7,e) }/>

        <circle id="svg-research-c11" className={"st5"+ (modalContent.id == 7 ? ' c-active' : '')} cx="1590.5" cy="1095" r="30" />
        <circle id="c11_clicktarget" className="sti" cx="1600" cy="1085" r="80" onClick={(e) => toggleModal(8,e) }/>

        <text x={lang.svg_labels.research0.p1.x} y={lang.svg_labels.research0.p1.y} className="st6 st7 st8 label bold">{lang.svg_labels.research0.l1}</text>

        <circle className="st4 no-b" cx={lang.svg_labels.research1.p1.x-30} cy={lang.svg_labels.research1.p1.y-8} r="15"/>
        <circle className="st5 no-b" cx={lang.svg_labels.research1.p1.x-30} cy={lang.svg_labels.research2.p1.y-8} r="15"/>
        
        <text x={lang.svg_labels.research1.p1.x} y={lang.svg_labels.research1.p1.y} className="st6 st7 st8 label">{lang.svg_labels.research1.l1}</text>
        <text x={lang.svg_labels.research2.p1.x} y={lang.svg_labels.research2.p1.y} className="st6 st7 st8 label">{lang.svg_labels.research2.l1}</text>

      </svg>
      </div>

      <h1 dangerouslySetInnerHTML={{__html: lang.title_about_research}} className="mb-1"></h1>

      <p>{lang.text_about_research}</p>

    </motion.div>
  )
}

export default AboutResearch;