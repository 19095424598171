import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import Modal from './../../elements/Modal';

const AboutGroups1 = ( prevPath ) => {

  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)

  const [showModal, setShowModal] = useState(false)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitRight")

  const toggleModal = (id, e) => {
    // e.preventDefault()
    // console.log("toggleModal ",e)

    let x = (e.clientX < 240) ? 240 : e.clientX;
    let y = (e.clientY < 120) ? 120 : e.clientY;

    lang.modal_about_groups[id].x = x;
    lang.modal_about_groups[id].y = y;

    // console.log(e.clientX, e.clientY);

    setModalContent(lang.modal_about_groups[id])
   
    setShowModal(!showModal)
  }

  const hideModal = () => {
    setShowModal(false)
    setModalContent("banana");
  }

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 

  // get location
  const location = useLocation()
  // set page along location
  const [modalContent, setModalContent] = useState('banana');

  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
	if(history.location.state){
        if(history.location.state.from == 'left'){
          if(initString != "enterLeft") initString = "enterLeft";
        }else if(history.location.state.from == 'right'){
          if(initString != "enterRight") initString = "enterRight";
        }
      }
  // }, []);


  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }
  
  return (
    <motion.div id="about-groups-1" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >
      <Modal showModal={showModal} content={modalContent} toggle={hideModal} />

      <div className="billboard mb-4">
        

<svg version="1.1" id="svg-research" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 2160 2060">
{/* <style type="text/css">
	.st0{fill:#67ACBF;}
	.st1{enable-background:new    ;}
	.st2{fill:#003264;}
</style> */}
<path className="st0" d="M557.4,492.4c-6.7-10.4-8.2-14.5,9.7-14.5c7.9,5.9-12.6,3.1-6.6,9C560.6,491,561.2,491.7,557.4,492.4z M587,475
	c4.4-6.1-11.3-5.6-6.5,0.3L587,475z M609.6,467.3c1.1-0.6,1.5-2,0.8-3.1c-0.2-0.4-0.5-0.6-0.8-0.8c-5.8,0-22.8-3-25.7,2.5
	c0.6,0.5,1.1,1,1.7,1.4L609.6,467.3z M757.9,462.7c6-0.8,6-3.4-0.2-5.3C753.3,458.8,755.2,460.3,757.9,462.7z M640.4,455.3
	c-5.8,0-17.9-1.2-21.1,4.8C621.4,462.3,648.3,458.3,640.4,455.3z M657.7,459.1c4.4-6.1-11.3-5.7-6.5,0.3L657.7,459.1z M669.7,454.1
	c4.4-0.3,8.7-0.7,13-0.9c6.6-6.9-26.3-6.7-16.7,5C668.9,457.6,668.7,457,669.7,454.1L669.7,454.1z M708.2,446.2
	c-3.7,3.3-13.6-3-17.1,3.7C695.4,456.1,719.4,447.5,708.2,446.2L708.2,446.2z M730.5,450.2c15.6-3.1-18.1-13.7-10-3.7
	C725.4,446.8,727.1,446.3,730.5,450.2L730.5,450.2z M806.3,417.1l2-0.3C812.2,412.2,802.1,411.8,806.3,417.1L806.3,417.1z
	 M796.1,410.3c3.9,0,8.9-6,2.6-6C795.9,405.9,793.5,407.2,796.1,410.3z M833.7,389.7c0.8-0.2,1.6-0.3,2.4-0.5
	C842.3,377.2,826.5,380.8,833.7,389.7L833.7,389.7z M735,343c6.2-4.8-10.3-6.8-5.1,0H735z M812.9,295.1c-4.5-9-16.7-6.7-4.8,5.7
	C811.8,300,813.1,299.1,812.9,295.1z M832,283.1c6-1,11.7-19.6-4.6-8C827.6,279.9,828,280.5,832,283.1z M793.8,254.9
	c10.5-3,1.1-13.7-1.1-20.1C777.1,235.7,797.4,251.3,793.8,254.9z M815.4,238.9c19.9-3.3,1.1-31.3-14.8-10.6
	C800.2,238.9,813.7,234.6,815.4,238.9L815.4,238.9z M1036.7,1070.8c1.2-3.3,3-6.5,5.3-9.2c-0.1-1.8,0.2-3.6,0.9-5.3
	c-0.3-2.6-0.7-5.2-0.9-6.1c-4.6,0.1-9.1-0.8-13.2-2.6c-2.6-1.1-4.4-3.4-4.7-6.2c-1.4-0.8-2.5-2.1-3-3.7c-2.1-0.7-3.9-1.8-5.4-3.4
	c-1.7-0.6-3.3-1.6-4.5-3c-1-0.2-1.9-0.6-2.7-1.2c-1.8-1.6-2.9-3.9-2.8-6.3c-0.2-1.4-0.2-2.9-0.1-4.4c-0.9-1.3-1.6-2.8-1.9-4.4
	c-0.6-1.8-0.9-3.6-1-5.5c-3.1-0.9-6-2.4-8.6-4.3c-0.2,0.2-0.4,0.5-0.6,0.8c-0.4,0.4-0.7,0.9-0.9,1.4c2.4,1.5,3.3,4.5,2.3,7.2
	c-3.3,8.3-21,7.6-28.1,14.4c-9.7,7.4-17.4,0.3-14.4-10.5l0.8-4.7c0.2-6.8,3.7-13.1,9.4-16.8c-0.4-1-0.9-2.2-1.6-3.6
	c-1.3-3.4-3.3-6.4-6-8.8c-5.9-5-2.7-9.8-0.2-13.7c1.9-3.3,4.3-6.4,7-9.2c-0.9-0.2-1.7-0.4-2.3-0.6c-5.6-1.7-11.5-2.4-17.4-1.9
	c-2.6,0.4-5.2,0-7.6-1.2c-1.6,1.5-2.6,2.2-3.9,3.4c4.3,5.8-2.6,14.2-5.9,19.4c-1.3,2.1-8.7,4.2-10.8,5.2c-3.8,6.9-10.4,15.1-19,14.8
	c-6.1,1.3-12.1-2.6-13.4-8.6c-0.4-2.1-0.3-4.2,0.4-6.2c-2.1-1.1-4.1-2.6-5.8-4.4c-3.2,2.1-5.7,3.5-7.7,4.5l0.4,4.9
	c1.8,4.3,5.6,11.6-0.5,14.4c-4.6,2.5-8,7.5-13.8,6.6c-2.9-0.3-5.8-0.3-8.7,0c-6.2,1.7-12.6,2.7-19.1,2.8c0,4.5-1.4,8.9-4,12.5
	c0.6,0.1,1.1,0.1,1.7,0c1.9-1.8,4.4-2.7,7-2.6c4.9-0.1,8,8.5,11.4,10.3c7.5,2.3,5.4,9.4,4.6,12.1c-1.1,3.9-2.7,7.6-4.7,11.1
	c1.5,2,2,4.6,1.6,7.1c-0.9,3.7-4.1,5.5-7.7,7.1c-5,2.6-10.5,4.1-16.1,4.3l-6,1.3l-1.5,4c-0.4,2.2-0.2,9-1.6,10.8l-5.1,8.1
	c-1.8,2-3.2,4.2-4.3,6.7c-0.4,2.6-1.9,5-4,6.6c-1.4,1.2-2.6,2.6-3.4,4.3c-0.7,0.9-1,2-0.9,3.2c0,2.2-0.8,4.4-2.2,6.1
	c-2.5,1.9-5.7,2.5-8.7,1.8c-3.8,0-7.5-0.8-11-2.1c-1.2-0.8-1.6-1-2.6-0.1c-2.1,2-4.8,3-7.6,3.1c-1.2,3.5-3.5,6.5-6.6,8.6l0.1,0.4
	c2,4.6,8.3,6.3,7.9,12.2c-0.5,3.8-2.6,7.1-5.9,9c-2.1,1.5-3.9,3.5-5.2,5.8c0.9,1.8,1.4,3.8,1.6,5.8c0.1,1.2,0.3,3.3,0.3,4.2
	c1.4,1.4,2.4,3.2,2.8,5.1c0.1,0.2,0.2,0.4,0.4,0.6c1.2,2.8,1,6.1-0.6,8.7c1.2,2.5,1.3,5.3,0.2,7.9c-1.9,2.9-4.9,5-8.4,5.8
	c-4.3,4.3-9.1,2-13.7,0.1c-0.4,1.3-0.8,2.7-1.1,4c0.3,4.6-1.3,9.3-1.2,13.9c0.3,1.9,0.2,3.9-0.1,5.8c0.9,4.6-1.1,9.2-4.9,11.8
	c0.4,1.9,0.5,3.8,0.4,5.7c0.3,4.3-0.2,8.5-1.3,12.7c-0.7,1.7-2,3-3.7,3.6c-1,3-1.6,4.8-1.9,5.9c0,3.5-0.9,6.8-2.6,9.8l0.4,0.2
	c3.1,1.2,5.5,3.8,6.4,7c1.2,5.5-3.2,9.4-5.6,13.8c-2.8,5.1-9.2,7-14.3,4.2c0,0,0,0-0.1,0l-2.1,2.3c-0.8,1-1.8,2-2.8,2.8
	c0,0.7-0.1,1.4-0.1,2.2l0.3,0.4c4.3,2,8.4,4.5,12.1,7.5c1.9-0.1,3.7,0,5.6,0.2c5.4-0.4,10.8-0.3,16.2,0.4c1.8-2.7,4.7-4.5,8-4.9
	c3.3-0.5,6.2-2.8,9.6-2.4c3-0.8,6.1-0.6,8.9,0.7c0.8,0.3,1.7,0.3,2.5,0c2.1-0.4,4.3-0.2,6.3,0.7c2,1.3,3.3,3.4,3.7,5.7
	c0.1,0.6,0.2,1.1,0.3,1.7c0.3,0.2,0.7,0.4,1.1,0.7c4.2,2.4,7.9,5.5,10.9,9.2c1.7,2.2,0.5,8.8,0.6,11.3c0.3,1.6,0.4,3.2,0.4,4.7
	c1.5,1.9,2.5,4.1,3,6.5h0c3.1,2.9,2.5,8.9,1.5,13.1c0.4,0.6,0.7,1.2,1,1.8l0.4-0.1c2.5-0.7,5.1-0.3,7.3,1.1c1.9,1.5,3.1,3.8,3.2,6.3
	c0.3,2.3,0,4.5-0.6,6.7c0.9,5.2-2.5,10.1-7.7,11c-0.4,0.1-0.9,0.1-1.3,0.1c-1.6,1-3.3,1.7-5.1,2.3c0.2,1.4,0.3,2.7,0.3,4.1l1.1,5.8
	c0.3,0.2,0.7,0.4,1,0.6c2.3,1.1,4.1,3.2,4.9,5.6c4.5,2,8.8,4.5,12.8,7.5c-2.5-9.7-1.4-11.7,12.8-16.5c3.6-1.8,7.8-1.5,11.1,0.6
	c2.2,2.1,3.1,5.2,2.4,8.1c1,3.1,1,6.5,1.7,9.7c2.6,1.3,4.5,3.7,5.2,6.5v0c3.6,1.5,12.3,2.3,14,7.2c0.7,2.2,0.5,4.5-0.5,6.5
	c1.7,2,2.9,4.4,3.6,6.9c0.5-0.2,0.9-0.5,1.4-0.7c1.3-4.5,3.4-8.8,6.1-12.7c3.3-5.6,10.9-5.5,16.3-8.3c0-4.7,0.3-10.3,0.5-15.1
	c-1.9-4.8-2.5-10.1-1.7-15.3c0.1-3.5,1.9-6.8,4.7-8.9l1.1-3c-0.3-2.2-0.4-4.4-0.4-6.6c-1.2-1.1-2.1-2.6-2.5-4.2
	c-0.9-2.2-0.9-4.7-0.2-6.9c-6.8-2.2-10.5-9.5-8.3-16.2c0.1-0.2,0.1-0.4,0.2-0.6c-2.7-8.1-4.1-16.6-4.4-25.1
	c-0.8-2.9-0.3-5.9,1.2-8.5c-3.1-4.5-4.7-9.8-4.7-15.3c-0.6-3.4,0.6-6.8,3.1-9.2c2.5-1.7,5.8-2,8.6-0.8c1.4,0.8,3.2,0.8,4.6,0.1
	c3.2-2.1,7-2.8,10.7-2c1.1,0.4,2.1,0.9,3,1.7c2.5-3.7,7.2-5.4,11.4-3.9c9.6-0.2,19.5-2.2,27.2,5.8c2.5,1.6,4.4,3.9,5.3,6.7l0.7,0
	c1.2-1.1,2.5-2,4-2.6c-0.5-1.3-0.8-2.7-0.8-4c-0.4-2-1.1-4-2.2-5.7l-1.7-3.3c-2.2-3.1-2.5-7.2-0.8-10.6c1.5-2.2,4-3.4,6.6-3.3
	c0.5-2.3,2.2-4.3,4.4-5.1c3.8-1.4,6.6,2.2,10.1,2l3.2-0.9c0.2-1.2,0.4-2.6,0.7-4.2c0.8-8.4,7-7.5,12.6-10.3
	c-1.7-6.7-5.5-15.9,2.5-20.3c2-1.4,3.7-3.1,5.1-5.1c1.5-3,4.3-5.1,7.6-5.8c4.8-0.8,6.8,4.8,11.2,4.8c0.6,0.1,1.1,0.1,1.5,0.1
	c0.9-0.9,1.9-1.5,3-2c0.1-1.3,0.4-2.5,1-3.7c1.2-2.9,3.9-4.9,7.1-5.2c-0.2-0.7-0.3-1.4-0.4-2.1c-1.1-4.5,1.6-9.1,6.1-10.2
	c0.4-0.1,0.8-0.2,1.2-0.2c0.5-1.4,1-2.8,1.7-4.1c-0.3-0.4-0.5-0.7-0.7-1c-3.9-1.6-5.9-5.8-4.8-9.9c-0.5-3.9,1.6-7.7,5.2-9.3
	c-0.1-0.8-0.2-1.5-0.2-2.3c-0.5-0.2-1-0.4-1.4-0.6c-1.5,1.3-3.1,2.5-4.9,3.4c-3,3.5-7.8,8.4-13.9,5.7c-2.9-0.4-5.3-2.6-6.1-5.4
	c-2-2.1-2.4-5.3-0.9-7.9c0.4-0.9,0.9-1.8,1.4-2.6c0.9-2.9,1.7-5.8,2.3-8.7c0.6-5.5-1.1-13.1,5-15.5c0.3-1,0.5-1.7,0.7-2.3
	c-0.6-3.8,1-7.6,4.2-9.8c1.6-1.2,3.5-2.2,5.4-2.8c0.7-0.3,2.7-1.3,3.4-1.7c0.2-2.2,0.5-4.3,1-6.5l-0.2-0.9c-3.5-0.8-5.7-4.4-4.9-7.9
	c0.2-0.8,0.5-1.5,1-2.2c0.6-1.2,1.3-2.3,2.2-3.3c-2.3-2.1-3.2-5.4-2.4-8.4c-0.3-1.7,7.5-7.3,11.9-9.8c2.7-1.1,5.7-1.5,8.6-1.3
	c-0.1-0.4-0.2-0.7-0.3-1.1c-2.7-2.9-3.3-7.1-1.4-10.6C1036.2,1072.7,1036.5,1071.8,1036.7,1070.8L1036.7,1070.8z M478.5,1389.9
	c-7.4,6.3-13.2,14.1-17.2,22.9c1.3,0.2,2.6,0.4,3.8,0.8l1.7-0.5c3.1-1.2,6.6-0.4,8.8,2.1c2.5-0.2,5,0,7.4,0.7
	c0.8-0.7,1.6-1.2,2.6-1.5c2.2-0.3,4.3,0.2,6.2,1.4c0.5,0.4,1.1,0.7,1.7,1c1.2-0.8,2.6-1.3,4.1-1.3c1.8,0.2,3.5,1.1,4.6,2.6
	c0.7,0,1.6,0,2.5-0.1c3.5-0.8,7.2,0.5,9.4,3.4c3.3-1.8,7.1-2.3,10.8-1.5c1.1-0.2,1.8-0.3,2.2-0.5c0.3-0.4,0.7-0.8,1.3-1.3
	c1.5-1.7,3.5-2.9,5.6-3.5c1.8-0.6,3.7-0.9,5.6-0.6c0.3-0.3,4.7-4.1,5.1-4.4c1.8-2.2,4.5-3.5,7.3-3.5c1.6-3,5-4.5,8.3-3.6
	c2.7-2.6,6.8-3.1,10.1-1.3c5.9-3.3,13.1-3.6,19.2-0.8c2.6,0.9,4.9,2.4,6.7,4.4c1,0,1.9,0,2.9-0.2c3.9-0.4,8.2-0.8,10.6,3.1
	c0.8,1.6,1,3.3,0.6,5c1.1-0.4,2.3-0.8,3.5-1c2.9-1,6.2-0.3,8.4,1.8c1.4,1.9,2,4.3,1.5,6.6l0.5,1.6c2.2,2.7,3.2,6.3,2.5,9.8
	c0.9,4.6,5.2,11.7-3.9,15.3c-0.6,0.6-1.1,1.1-1.5,1.4c0.1,0.3,0.1,0.6,0.1,1c2.3,1.6,3.7,4.3,3.6,7.1l-0.2,0.4
	c6.5,1.9,11.9,6.2,15.3,12.1c1.9,2.9,2.2,6.6,0.7,9.8c-1.2,1.6-3,2.6-4.9,3c-1.6,2.7-3,5.6-4,8.5c0.5,1.1,0.8,2.3,0.7,3.5
	c0.5,5.3-2.8,10.2-8,11.7c-0.9,1.1-1.9,2.2-3,3.1c2.5,3.7-0.7,8-2.8,11.1c6.1,4.8,8.9,14.4,13.8,20.4c10.3,1,9,6.3,16,9.1
	c12.9,0,18.4-3.6,29.6,3.4c6,0,10-4.8,15.7-0.9c4.3,7.5,29,14.4,37.7,19.6c1.3,0,2.6-0.2,4-0.4c0.1-0.6,0.8-1.3,2.6-2.8l0.2,0.2
	c0.3-0.9,0.8-1.8,1.4-2.6c1.2-1.8,2.9-3.3,4.9-4.3l0.7-0.4c0.5-3,2.7-5.4,5.6-6.2c1.7-0.6,3.3-1.5,4.7-2.6c1.7-1.6,3.7-2.7,6-3.2
	c0.4-0.1,0.7-0.3,1-0.4l0.5-0.7c-0.2-1.2-0.1-2.5,0.4-3.6c0.5-0.9,1.2-1.7,2-2.3c0-0.5-0.1-1-0.1-1.5c0.1-5.2,2.6-10,6.7-13.1
	c0.5-2.4,1.9-4.6,3.8-6.1c-1.1-4.9-1.1-10,0-15c0-1.2,0.4-2.3,1.1-3.3c0.8-2,2-3.8,3.7-5.2c-0.1-0.6-0.1-1.1-0.1-1.7
	c-0.2-2.4,0.6-4.8,2.2-6.7c1.3-2.3,3.3-4.1,5.8-5c-2.3-2.3-3.1-5.7-2.2-8.8c0.9-2,2.7-3.5,4.8-4.1c0.4-0.5,0.8-1,1.2-1.4
	c-2-1.5-2.9-4.1-2.3-6.5c0.3-2.7,0.7-5.4,1.3-8.1c-2.2-0.7-4.1-2.3-5.3-4.4c-0.4-1.2-0.5-2.6-0.3-3.8c-0.3-1.2-0.4-2.5-0.5-3.8
	c-0.6-1.6-0.6-3.4,0-5c-2.4-7.5-3.6-15.4-3.6-23.2c-5.8-2.5-5.9-10.2-6.9-15.4c-0.2-2.4-0.4-4.9-0.8-7.3c-0.6-2.7,0-5.5,1.7-7.7
	c3.3-3.5,8.5-1.4,12.2-4.4l0-0.5c-0.4,0-0.8-0.1-1.2-0.1c-2.6-0.1-5-1.3-6.5-3.4c-0.7-1.2-1.1-2.6-1-4.1c-2.1-2.9-4.9-7-1.1-11.4
	l-0.1-0.8c-0.9-1.5-1.6-3.1-2-4.8c-4.4-2.8-2.8-8.8-3.6-13.2l0.1-6.7c-3.7-3.5-12.3-7.1-13.1-11c-2.1-0.3-4.1-0.8-6.1-1.3
	c0.5,0.2-0.7,0.5-2.6,0.5c-1.6,0.7-3.2,1.2-4.9,1.6c-3.4,11.5-15.6,3.6-23.5,5.1c-6.3-0.5-17.3,2.5-20.3-4.3
	c-0.7-0.4-5.3-2.9-6.3-3.4c-3-1.2-5.6-3.4-7.4-6.1c-2.2-2.3-2.8-5.7-1.5-8.5c-0.1-4.4-0.1-9,5.2-10.4c4.9-5.3,8.6-12.6,16.5-6.7
	c0.5-0.8,1.1-1.7,1.8-2.8c-2.6-1.4-4.6-3.5-6-6c-1.9-2.5-2.2-5.8-0.9-8.6c0.8-1.3,2-2.3,3.4-2.8c0.3-0.6,0.5-1.2,0.6-1.9
	c-0.1-0.8-0.1-1.6,0-2.4c0.8-3.2,1.7-6.4,2.9-9.4c0.7-2.5,2.1-4.7,4-6.5c0.1-1.4,0.2-4.5,0.1-6.5c-0.6-1.8-0.7-3.7-0.4-5.6
	c-0.2-3.7,1.7-7.1,4.9-9c-0.1-1-0.1-2.1,0.1-3.1c-0.2-8.2,0.6-16.3,2.4-24.3c0.7-2.9,2-5.6,3.7-8c1.2-3,3.8-5.1,7-5.4
	c2.1-0.7,5,1.7,6.2,3.5c0.4,0.7,1,1.2,1.8,1.4l0.6,0.2c0.8-0.5,1.6-0.9,2.4-1.3c-0.3-2.1-0.1-4.2,0.7-6.2c-0.1-0.4-0.2-0.9-0.3-1.3
	c-0.5-0.6-1-1.3-1.4-2c-2.2-2.8-1.4-6.5-1.9-9.8c-0.2-0.9-0.6-1.8-0.9-2.6c-0.2-0.7-0.5-1.4-0.6-2.1c-0.7-0.4-1.3-0.7-2-0.9
	c-2.2-0.6-4.3-1.7-6-3.2c-6.2,0.1-8.2-8.8-11.5-15c-1.2-1.6-1.9-3.4-2.1-5.4l-0.7-3.2c-0.4-2.9-1.4-5.7-2.9-8.2l-7.7-9.9l-5.1-7.7
	c-0.9,2-1.5,4.1-1.9,6.2c-0.5,4.3,0,12-5.9,12.7l-3.8,0.7c-0.1,7.1-7.1,10.1-13.3,10.6c-0.9,5.1-3.7,9.8-7.9,12.9
	c-1.7,1-3.8,1.2-5.7,0.4c-0.2-0.1-0.5-0.2-0.7-0.4c-0.7,0.3-1.4,0.6-2.1,0.9c-8.7,4.3-9.6,4.3-10.9,4.3l-5.5,0.5l-1.8,2.3
	c0.3,0.6,0.7,1.1,1.2,1.6c1.9,2.1,2.6,5,2,7.7c-1.6,4.1-5.5,4.4-9.2,4.8c-1.3,0-2.7,0.2-3.9,0.5c-2.4,1-5.1,1.6-7.7,1.7
	c-2.5,0.4-5,0-7.2-1.1c-0.9,0.6-1.6,1.6-2,2.7c-4,9.4-12.1,5.3-17.3,2.9c-2.5,8-13.1,10.2-21.6,10.5c-0.2,0.5-0.6,1.2-0.8,1.7
	c1.2,4.7-9.8,12.7-13.1,16.8c-3.6,3-8.1,4.9-12.8,5.4c-2.4,0.5-4.9,0.4-7.2-0.2c-1,11-10.3,9.6-17.1,10.3c-1.5,2.8-1.4,6.7-4.6,8.3
	l-6.6,3.7c-3.4,2.4-9.4-1.6-12.7-2.6c-2.9-1.4-5.3-3.6-6.9-6.4c-4.2,1.6-8.5,2.9-12.9,3.8l-11.9,3.6c-1.9,10.2-12.5-1.8-15.6,12.9
	c-4.6,6.5-17.1,3.4-17.1,14.3c4.6,9.3-6,7-6.8,17.1c-5.8,25.7,1.6,38.1,14.4,58.8c10.3,6.1,18.9,17.7,31,18.4
	C487.1,1366.2,479.3,1380.1,478.5,1389.9z M442.8,1162.8c1.8,14.6,19.5,1.8,8.9,18.4c-28.4,16.8,1,15,9.4,26.8
	c8.4,7.5,4,14.2,4.7,21.6c1.2-0.3,12.9-4.2,13.9-4c-0.2,0,2.3-0.4,10.4-3.3c3.3-1.7,7.1-1.9,10.6-0.6c2.2,1.2,3.7,3.4,4.1,5.9
	c0.3,1,0.3,1.1,1.4,1.8l3.8,1.5l1.7-1c0.7-2.2,1.7-4.3,2.8-6.3c2.5-6.1,9.1-4.6,14.3-5.2c0.6-2.1,1.6-4.1,3-5.8
	c2.4-4.7,8.2-6.6,13-4.2c0.1,0.1,0.2,0.1,0.4,0.2c2.8-0.1,5.5-0.8,8-2.1c1.5-1.5,7.8-7.8,9.1-9.1c2-6.3,4.3-13.4,12.7-12.8
	c3.4,0,6.9-0.6,10.1-1.8c2.7-4.8,7.9-11.5,13.5-11c2.2,0.6,4.2,2,5.3,4c0.2,0.1,0.4,0.2,0.6,0.2c1.3-1.9,2.8-3.6,4.6-5
	c2.3-2.5,5.5-3.9,8.9-3.8c1.7,0.4,3.2,1.2,4.4,2.4c1.3,0,2.6-0.2,3.9-0.5c0.9-0.4,1.9-0.7,2.9-1c-2.2-5.5,3-10.6,6-14.5
	c2.1-3.4,9.9-2.5,13.2-3.2c-0.7-0.4,1.1-0.7,5.8-3c2.7-1.6,5.7-2.4,8.8-2.5c0.5-1.1,0.8-2.2,1-3.4c0.3-7.2,6.9-9.5,13.1-9.6l0.9-4.4
	c0.6-4.1,4.9-5.2,8.4-5.6l0.3-2.6c1.8-6.8,4.1-20.7,13.6-18.5c2.7,1.3,10,2.6,11.3,5.7l5.1,8.8l5.7,7.5c3.8,4.1,6.1,9.4,6.8,14.9
	l0.9,4.3c1.8,3.1,3.3,6.4,4.6,9.7c2.1,0.9,4.1,2.1,5.9,3.5c0.9,0.1,1.8,0.4,2.7,0.9c1.4-1.9,3.1-3.5,4.9-5
	c-6.5-5.4-9.5-18.5-1.1-22.5c1.2-0.6,2.1-1.7,2.4-3c2-4.9,5-9.6,11.8-8.3c4.2-4.2,10.8-4.9,15.8-1.7c0.6,0.1,2,0.4,4.3,0.7
	c1.6-4.8,4.5-9.1,8.4-12.4c1.1-4.1,3.2-7.8,6.1-10.9l3.8-6c0.1-5.9,1.4-11.7,3.9-17c0.7-2,2.5-3.6,4.6-4c4.1-1.1,8.4-1.9,12.6-2.3
	c3.1-0.3,6.2-1.2,9-2.6c-0.6-2.9-0.3-5.9,1.1-8.5c0.8-2,1.8-3.9,2.9-5.8c0.3-0.7,0.5-1.4,0.7-2.2c-2.2-1.6-4.1-3.7-5.5-6.1
	c-6.9,2.4-19.5,0.1-20.7-8.4c0.7-4.9,2.5-9.6,5.2-13.6l0.6-8.9c0.2-3.3,3-5.9,6.3-5.9c7.9,0.1,15.8-0.9,23.4-2.9
	c3.4-0.4,6.9-0.4,10.3,0c1-0.6,2-1.4,3.2-2.2c-0.7-1.4-1.1-2.8-1.4-4.3c0.1-4.9-3.2-15.4,4-16.8c8.2-2.8,16-13.9,24.2-4
	c1.2,1.6,2.9,2.9,4.8,3.6c4,1.4,10.1,5.3,8.3,11c5.5-3.8,4.2-8.7,8.9-11l9.3-3.7c1.3-2.1,2.5-4.2,3.6-6.4c-0.5-1.5-0.8-3-0.7-4.6
	c-0.2-4,1.8-7.9,5.2-10.1c0.5-0.4,1-0.8,1.6-1.4c-2.2-2.5-2.9-5.9-2-9c0.9-7.4,0.8-10.6,1.5-16c0.3-4.3,2.4-8.3,5.8-11
	c-7.5-8.8-4.2-15.2-12.7-21.4c-5.2-4.2-9.9-8.7-10.2-13.6l-6.5-6.5c-10.3-6.3-5.9-18.4-8.8-27.9c-2.2-1.7-11.1-5.6-12-8.5
	c-3.1-4.9-3.6-11.1-1.3-16.4c-2.5-3.6-5.8-6.5-9.6-8.6c-2.5-1-4.4-3.2-5.1-5.8c-0.8-3.7,1.2-6.2,4.4-10.3c2.5-4.2,6.7-7.1,11.5-7.9
	c0.5-1.3,5.7-26.9,6.2-29c-1,0.1-1.6,0.1-1.9,0.2l-11,7l-6.1,10.2c-4.1,5.7-12.3,2-18.1,1.3c-4.2,1.9-9,1.9-13.1-0.1
	c-5.3-3.1-5-9.6-4.2-15c0.7-6.1,0.9-7-4.4-8.9c-3.1-1-6-2.6-8.5-4.6c-0.9,0.5-2.2,1.2-3.7,2.1c0.8,7.1-21.8,9.1-31,12.2l2.5,4.2
	l8.2,9c0.9,0.3,1.7,0.8,2.4,1.4c4.1,4.2,6,10.1,5.2,15.9c-0.2,5.3,0.2,10.6,1,15.8l4.4,13.3c0.8,2.5,0,5.3-2.1,7
	c-8.7,6.2-18.5,10.6-28.8,13.1c-5.1,3.2-9.8,7-14,11.3c-2.9,3.2-6.9,5-11.2,5.2c-5.5,0.8-10.9,2-16.3,3.5
	c-4.6,2.6-12.7-5.7-16.4-7.5c-5.5-3.7-1.8-10.2,2.5-12.9c-4-3.1-3.6-8.8-3-13.9c1.8-10.7-8.3-21.8,0.9-31.4c1.7-2.7,3.9-5.1,6.6-6.8
	c-2.1-1.9-3.8-4.1-5.1-6.5c-10,0-19.1,3.8-22.7-8.4c-0.8-2.5-2.5-4.5-4.9-5.6l-8.6-2.3l-9,12.6c-8.4,7.7-11.6,10.7-12.7,11.8
	c-2.7,5.1-7.4,8.8-13,10.3c-4.5,0.9-19.4,5.2-24.7,6.8c-6.5,0.4-12.5-4.6-19.4-3.2c-9.1,1.1-26.9,4.1-26.9,4.1l0,0
	c-12,7.9-12.7,21.7-32.2,22.6c-2.4,2.6-1.3,5.8-4.4,7.8c-15,2.3,6.2,11.9,10.1,18.7c9.5,5.5-10.3,24.1-16.4,24.1
	c-8.9-5.8-14.9,0.2-24.5,2.8c-8.4,4.8-15.9-2.8-21.9,2c-1.7,6.7-25.7-4.9-30.2-8.5c-1.9-11.7-15.5-0.4-1.9,8.9
	c0,0.5-16.3-3-22.9,3.9c-13.1,0-3.6,6.2-3.9,13c-9,7.4,7.6,6.2,7.6,14.7c-8.4,7.6,9.7,8.5,9.7,14.8c-6.6,14,13.1,26.2,13.1,45
	c-4.9,6.9-1.1,14.7-1.1,22.2c-6.5,9.7-21.7,19.5-24.1,30.9c-7.2,5.9,3.3,19.7,5.4,3.8c11.4-0.5,7.8,12.1,3.6,16.4
	c-12.4,5.1-23.2,13.4-31.4,24c-3.9-0.5-2.3-4.6-7.4-4.6c-12.1,9.8,10.8,13.9,16.5,14.3c4.3,3.2-1,5.5,1,10.7
	c10.5,5.5,8.1,5.8,7.3,18.1c-2.9,3.9-23.1,14.6-12.1,20.4C424.2,1146.4,434.8,1152.6,442.8,1162.8L442.8,1162.8z M1625,533.2
	c-2,2-4.5,3.5-7.2,4.4l-5.9,2.8c0.2-0.2-2.7,1.6-6.4,4.4c-0.6,1.9-1.6,3.7-3,5.1c-2.5,2.9-3.8,4.3-6.8,4.6
	c-3.1,2.8-7.6,3.6-11.5,1.9c-2.8-1-5.8-1.7-8.8-2.2c-3.4-0.1-6.2-2.6-6.8-5.9c-0.1-2.5,1.1-4.9,3.2-6.2c-0.2-2.9,0.9-5.7,3.1-7.6
	c1.3-2.7,4.1-3.4,6.5-4.7c1.5-4,3.6-7.8,6.2-11.3c-0.1-0.3-0.2-0.6-0.3-0.9c-3.3,0.1-6.5-0.5-9.5-1.8c-0.3,0.5-0.7,1.1-1.1,1.6
	c-1.4,2.5-3.9,4.2-6.7,4.5c-1.6-0.1-3.2-0.6-4.5-1.6c-6.3,0-12.5-1.5-18.2-4.3c-3.5-1.4-7.4-3-8-8.1c-1.6-1.5-2.5-3.5-2.6-5.6
	l0.2-1.9l-0.1-0.1c-0.3,3.5-0.7,8.4-4.8,9.5c-3.8,0.8-7.6,1.5-11.4,1.9l-3.7,3.8c-2.2,1.4-4.3,3-6.2,4.8c-4,4.5-6.1,10.7-11.3,14.2
	c-3.3,9.6-9.5,13.2-14.6,24.6c-4.4,7.2-10.6,2.9-16.4,0.9c-3.6,3.4-7.5,6.5-11.6,9.4c-2.5,2.5-6,3.7-9.5,3.2c-1-0.3-1.9-0.9-2.7-1.6
	c-3.8,3.5-8,6.4-12.6,8.7c-2.9,2.6-7.1,3.1-10.5,1.2h0c-1,0.7-2.1,1.2-3.2,1.5c-2.3,1.3-4.9,1.9-7.5,1.7c-4,0.2-8.1-0.1-12-1
	c-4.7,0-9.1-2.3-11.9-6.1c-3-1.7-3.9-2.3-12.5-8c-9.5,3.3-22.9,4.3-27.7-7.5c-6.4,0.3-17.9,0.8-20.6-4.9c-4.1-2.1-8.4-4-12.7-5.7
	c-2.2,1.3-4.9,1.7-7.4,1c-4.6-1.1-5.5-0.8-11.6,1.8c0.3,6.5-2.5,16.2-8.4,18.7c-2.1,0.5-4.3,0.3-6.3-0.6c-1.2,0.8-2.6,1.4-4.1,1.6
	c-2.6,0.6-5.3,1.4-7.8,2.3c-1.4,0.4-2.7,1-4,1.7c-2.3,2.5-5.2,4.5-8.3,5.7c-5.6,3.4-12.9,3.8-13.6-3.6c-0.9-0.1-1.8,0-2.7,0.2
	c-1,0.2-2,0.4-2.9,0.7c-0.7,0.8-1.4,1.5-2.2,2.1c1.2,1.2,1.9,2.7,2.1,4.4c0.8,0.7-7.2,19.4-13.5,18.1c-0.6,0.4-1.3,0.8-1.9,1.2
	c-1.3,0.9-2.6,1.6-4,2.3c1.2,0.6,2.6,1.5,4.3,2.5c2.9,1.2,6,1.7,9.1,1.5c2.7,0,8.4,0.2,9.6,5.3c2.1,0.5,4.1,1.4,5.9,2.7
	c2.9,1.9,6.6,1.6,9.5,3.6c2.7,1.2,5.1,3.2,6.7,5.7c1.7,0.3,4,0.7,6.3,1c2.6,0.1,5.1,1.4,6.7,3.4c1.5,2.1,2.1,4.8,1.6,7.3
	c4.2,0.9,7.8,3.4,10.1,6.9c0.8-0.3,1.7-0.5,2.5-0.7c-0.7,0,0.8-0.4,4.9-1.8c5.7-2.4,12.2-0.8,16.1,3.9c0.8,0.5,1.7,0.9,2.6,1.1
	c3.9-0.9,7.9-0.6,11.6,0.6c4.5,0.4,6.3,8.4,8.1,11.5c2.2,6.4-5.1,17.2-7.8,22.8c0.1,1.1,0.2,2.6,0.4,4.5c1.9,1.5,3.5,3.2,4.8,5.2
	c4.7,4.4,2.4,10.3-0.5,15c-1.2,3.5-3.6,6.6-6.6,8.7c-0.4,1.1-0.8,2.4-1,3.4c0.2,2.5,1.2,4.8,3,6.5l3.8,3c0.9,0.2,2.7,0.6,5.7,1.1
	c2.9,0.2,5.5,1.8,7.1,4.2c0.9,2.1,0.9,4.5,0.1,6.7c1.3,1.6,1.8,3.6,1.5,5.6c0.1,0.6,0.2,1.3,0.4,1.9c1.1,2.6,1.2,5.5,0.2,8.1
	c-0.7,4.7-2,9.2-3.8,13.6c0.3,0.8,0.6,1.7,0.9,2.6c1.1,2.5,1.3,5.3,0.3,7.9c-0.1,1.5-0.1,3,0,4.5c0,2.2-0.8,4.4-2.1,6.2l0.7,2.7
	c1.8,4.7,3.5,10.8-1,14.6c-0.7,0.8-1.8,2-3.4,4c2.8,2.1,4.8,5,5.8,8.4c2,1.9,3.6,4.2,4.6,6.8c0.3,0.6,0.6,1.1,0.8,1.5
	c4.2,2.6,8.3,5.4,12.2,8.6c3.1-0.7,6.3-1,9.5-1c4.1-0.5,7.9,2.5,8.5,6.6c0.2,1,0.5,2,1,2.9c1.3-0.9,2.7-1.6,4.2-2.1
	c1.4-0.4,2.7-1,3.7-2c2.4-2.4,5.7-3.6,9.1-3.2c4.8-0.7,9.2,2.7,9.9,7.5c0,0.2,0,0.4,0.1,0.6c0.4,0.5,0.7,0.8,1,1.1
	c2.1-0.8,4.4-1.2,6.6-1.2c3.4-0.4,6.7,0.8,9,3.3c0.8,1.2,1.2,2.6,1.2,4.1c0.4,0.1,1,0.3,1.5,0.5c3.4,1.1,6.4,3.1,8.7,5.8
	c1.6-0.4,3.4-0.5,5-0.3c3.3-0.2,6.6,1,8.9,3.4l2.9,2.2c1.3,0.8,2.4,1.8,3.3,3l0.3,0.4c5.9-2.8,11.4,7.8,14.8,10.8
	c3.3,5.1,4.7,11.2,3.7,17.3c0,0.7,0,1.3-0.1,1.8c3.7,5.5,1.2,13.3-0.4,19.3c-1,5.1-5.8,7.2-9.2,10.4c0.1,0.2,0.2,0.4,0.3,0.6
	c2.5,1.2,5,2.6,7.4,4.1c2.3,1.2,4.4,2.9,6.2,4.8c6.9,3.2,3.3,10.7,2.7,16c0.5,3.7,1.5,9.1,2,12.8c1,2,1.6,4.2,1.7,6.5l0.6-0.1
	c1.2-1.3,2.5-2.5,3.9-3.5c4-4.4,9.5-2.9,14.5-1.8c-0.3,0-0.2,0.5-0.1,0.9c1.3-2.2,3.9-3.4,6.5-3c5,1.5,9.6,4.1,13.4,7.6
	c0.4,0.3,0.8,0.6,1.1,0.8c5.6-1,11,2.5,16.4,2.1c6.8-0.6,13.7-0.7,20.5-0.5c5.7-1,11.3-2.4,16.8-4.1c2.7-0.8,5.5-1.1,8.4-1.1
	c2.6-0.5,3.5-0.6,4.7-0.8c-1.3-2.8-0.5-6.2,1.9-8.2c2.6-7.5-1.7-24.7,11.1-22.5c3.9-3.3,8.8-5,13.9-4.8c3.1,0,6.1,0.6,8.9,1.7
	l4.2,1.3c3.9-1.6,8-2.6,12.1-2.9c0.2-0.4,0.4-0.8,0.7-1.2c2.6-3.4,6.6-5.5,10.9-5.5c0.9-0.1,1.7-0.2,2.6-0.4
	c7.4-3.1,15.4-4.1,23.3-3c0.9,0.1,1.8,0,2.6-0.3c2.4-0.6,3.4-0.8,3.9-1c-0.1,0,0.4-0.4,1.3-0.9c0.2-1.5,0.7-2.9,1.5-4.2
	c1.6-1.9,3.2-3.7,5-5.4c-0.6-2.9-0.9-5.8-0.8-8.7c-10.9-10.9-12.8-9.2-0.7-23c9.9-6.8,7.1-13,7.1-24.2c1.9-6.9,6.9-16,3.3-23
	c-16.5-8.5-7.3-22.4-7.3-36.3c-3.9-5-9.6-3.9-14.7-6.7c-3-16.9-13.3-21.2-10.4-39.5c11.5-21,9-36.9-11.7-48.2
	c-16.7-13.6-29.2-34.7-51.9-38.8c-5.9-3.6-5.4-7.3-1.4-12.8c2.2-7.7-5.5-17.9,0.3-23.7c16-8,35-26,26.5-45.3
	c0-8.9,4.1-10.7,7.4-18.3c0.4-11.9-1.5-23.7-5.6-34.9c-1.8,0.2-3.7,0.3-5.5,0.2C1626.7,532.9,1625.9,533.1,1625,533.2z M542.7,727.5
	c-1.8,20.5,13.6,21,29.8,25.5c5.1,0,5.1-2.4,9.2-5.1c4,0.2,14,22,14,28.3c-3.1,5.9-5.7,12-7.7,18.4c12-3.5,24.7-3.3,36.6,0.5
	c5.4-1.6,18.8-5.4,23.6-6.4c1.8-0.4,4-3,4.8-4.7c2.8-3.8,0.2-0.5,14-13.4c5.1-5.9,10.8-20.1,17.4-17.5c9.6,2.4,22.9,5.2,26,16.3
	l0.6,1.3c7.4-0.3,20-3.3,21.7,6c2.5,5.5,15.3,11.2,12.1,17c-2,6.9-11.3,3-14.1,9.2c-2.7,4.2-2.5,4.8-1.2,8
	c2.3,5.5,3.1,11.5,2.3,17.4c4-0.4,7.8,2.2,8.8,6.1c2.5,4.4,0.9,10-3.6,12.4c-0.4,0.2-0.8,0.4-1.3,0.6c-0.6,0.3-1.3,0.7-2.1,1.1
	l0.8,0.5c3.2-0.9,10.3-2.3,15.6-3.2c1.2-0.2,2.4-0.4,3.6-0.7c3.6-3.7,7.5-7.1,11.6-10.2c4-3.8,9.3-6.1,14.9-6.3
	c4.4-3.5,10.5-3.6,14.9-7.3l-3.1-9.4c-1.1-8.3-1.8-16.6-2-24.9c-2.1-0.3-11.2-11.8-12.8-13.2c-2.2-5.1-13.7-16.3-5.5-21.1
	c5.5-2.1,26-6.6,31.2-8.1c1.7-2.6,4.1-4.6,7-5.8c7.9-4.3,11.9-6.5,16.9-1.3c1.5,1.5,3.4,2.6,5.5,3.2c9.5,2.5,15.2,12.3,12.7,21.8
	c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.6-0.2,1.3-0.2,1.9c2.7-1.1,5.7-2.5,8.4-0.6c2,0.4,4,0.8,6,1c1.3-1.8,4.8-9.2,6.8-10.1l11.6-7.4
	c5.3-2.6,11.2-3.6,17.1-2.8c3.5,0,6.3,2.8,6.3,6.3c0,0.4,0,0.8-0.1,1.2l-6.9,33.5c-1.3,6.1-3,14.5-12,13.4c-0.7,0.5-1.3,1.1-1.8,1.8
	l-0.5,0.6c7,5.2,16.6,14.4,11.4,22.7c-0.3,0.5-0.6,1.2,0.5,4.2l9.8,5.9c4.7,6.5,6.6,14.7,5.3,22.6c-0.5,2.7,3,5.2,3.1,5.3
	c2.5,3.3,13.5,10.5,11.8,15.7c1.4,1.5,3,2.9,4.7,4.2c8.8,4.5,8.9,14.8,13.9,22.1c4.2,6,7,12.3-0.4,18.3c-1.2,0.7-1.9,1.9-2,3.2
	c-0.9,7.7-0.4,5.9-1.5,15.6c1.9,1.8,3.6,3.9,5.2,6c7.2-1.4,14.6-0.9,21.4,1.5c11.5,2.9,16.3,4.1,17.3,9.4c0.3,3.8-1.7,7.4-5.1,9.1
	c-3.8,2.6-6.9,6-9.1,10c7,8.4,18.7,29.7,2.7,31.2c-0.5,0.7-1.1,1.6-1.6,2.3c-0.5,1.4-0.8,2.8-1,4.3c4.6-2.5,9.5-4.3,14.6-5.5
	c-0.8-4,0.4-8.2,3.2-11.2c2-3.4,5.3-5.9,9.2-6.9c2.9-0.2,5.7,0.8,7.8,2.8c3.4,2.7,7.7,4.2,12,4.3c1-2.4,2.9-4.4,5.3-5.5
	c5.1-3.2,15.4-3.8,19.5-7.4c5.9-4.9,12.4-9.1,19.4-12.6c2.6-4.3,1.1-11.3,6.3-13.6c3.2-0.8,14.9-7.6,18.2-5.9
	c3.9,0.6,7.8,0.9,11.8,0.8l14.3-13.4c-1.2-7.6-3.6-15-7.1-21.9c-4.4-5.2-7.1-11.5-7.8-18.3c1-7.1,4.2-13.7,9.3-18.7
	c0.1-2.6-0.6-5.2-2-7.4c-3.8-3.7-5.4-9.1-4.1-14.2c-0.8-6.6,3.9-12.6,10.5-13.4c0.9-0.1,1.9-0.1,2.8,0c1.9,0.2,3.9-0.1,5.7-0.8
	c4.2-2.8,9.1-4.1,14.1-3.6c5.8,0.2,11.6-0.2,17.4-1.1c0.4-7.2,0.3-17.9,9.1-17.1l-0.4-0.8c-2.3-3.7-2.8-8.3-1.3-12.4
	c1.4-3,4.4-4.8,7.7-4.6c-0.5,0.1,0.1-1.3,0.3-2.4c-5.5-4.9-8.5-12-8.1-19.4c-0.4-3.6,0.8-7.1,3.3-9.7c-1.2-1.3-2.4-2.7-3.4-4.2
	c-2.9-4.9-11.4-3.2-13.5-10.5c-1.7-7.4,1.7-15.1,8.4-18.8c-3.8-6.2-6.4-13-7.7-20.1c-0.5-0.9-1-1.8-1.6-2.6
	c-1.8-1.6-3.4-3.5-4.6-5.6c-1.3-1.8-2.4-3.8-3.4-5.8c-5.2-7-15.3-13.5-14-23.3c0.1-0.9,0.2-1.3-0.8-3.1c-2.5-3.4-3.6-7.6-2.9-11.8
	c0.6-1.8,1.8-3.2,3.4-4.1c6.2-3.5,12.8-6.4,19.6-8.6c2.9-0.7,5.7-1.5,8.5-2.5c0.4-0.4,0.7-0.8,1-1.3c0.8-1,1.8-2.4,3.4-4.2
	c2-3.1,5.5-4.9,9.1-4.7c2.8,0.6,5.1,2.6,6,5.4l2.1,0.3c2.8-1.2,5.8-2.1,8.8-2.6c3.4-1.5,7.4-0.3,9.5,2.8c3.3-0.4,6.6-0.3,9.9,0.4
	c0.6,0.2,1.2,0.2,1.9,0.1c2.4-3.2,5.6-5.9,9.2-7.7c3-1.8,3.3-6.9,7.7-9.4c-0.4-3.8,0.7-7.7,3-10.7c-1.7-0.8-4.4-2.5-5.4-3.1
	c-7.9,0.6-27.7,3.5-24.8-9.4c3.4-12,11.4-12.8,11.4-12.8c4.1-6.4,11.6-4.4,17.1-2.5c1.5-0.9,3.1-1.7,4.8-2.3c0.9-1.5,2-3.1,2.5-4.1
	c-8.2-9.4,5.8-20.1,14.7-21.4c4.6-1.5,9.6-0.6,13.4,2.4c0.6,0.3,1.1,0.7,1.6,1.1c3.9-2.5,7.1-6.4,12-7.1c5.6-2.1,11.4-3.6,17.3-4.6
	c0.8-1.7,1.1-3.5,0.9-5.4c-0.4-5,0.9-9,8.3-12c4.8-2.9,10.5-3.9,16-2.8c3.6-3,7.7-1.6,13.7,0.8c6.1,2.1,11.9,5,17.2,8.9
	c8.6,1.4,18.6-3.7,21.3,7.8c-0.1,2.2,4.1,2.1,10,1.5c0.8-1.4,2.1-2.4,3.7-2.7c3.6-0.3,7.1,1,9.7,3.4c10.5,7.8,10,4.8,15.7,11.3
	c0.2,0,1.2,0.3,3.4,0.7c3.3,0.8,6.7,1.1,10,0.9c1-0.2,1.9-0.5,2.8-0.8c2.2-2.2,5.3-3.2,8.3-2.5c1.2,0.1,2.4,0.4,3.5,0.7
	c2.3-1.5,4.7-2.9,7.2-4.1c4-5.3,10.8-7.7,17.3-6.1l5.2-4c-1.6-11.5,14.1-8.2,20.9-5.6c3.3-9.2,9.9-13,13.7-23.3c0.9-1,2-1.8,3.2-2.4
	c3.9-7.2,9.4-13.3,16.2-17.8l4-4.3c2.6-2.7,9-2.2,11.2-2.9l0.1-1.2c-2.4-4.4-5.3-9.1-0.5-13.2c1.9-2.9,5.1-4.7,8.5-4.9
	c3.9,0.7,7.2,3.2,9,6.7c1.7,2.7,3.8,5.2,6.1,7.4c2,2,2.7,4.8,2,7.5c0.7,1,1.3,2,1.8,3.1c3.9,1.6,7.7,3,7.6,2.9c0,0,3.7,0.6,7.9,0.8
	c0.2-1.6,0.9-3.1,2.1-4.1c2.3-1.8,5.4-2.4,8.2-1.6c2.6,0.3,5.1,1.3,7.2,3c2.2,0,3.4,0,4,0.1c5.3-1.2,9.8,4,11.1,8.6
	c4,5.5-0.5,10.9-2.2,13c-3.8,5.4-4.9,13-12,15.4c-0.2,0.4-0.5,0.8-0.8,1.2c1.7,0.4,2.8,0.9,3.8,1.2c1.3-0.8,2.6-1.4,4-2l0.8-0.9
	l0.1,0c0.6-3,2.5-5.6,5.3-7.1c2.5-2,5.2-3.8,8-5.3c3.3-1.4,6.6-3,9.7-4.8c1.6-2.3,4.2-3.6,7-3.5c1.9-0.5,3.9-0.6,5.9-0.3
	c-5.3-13.1-8.7-26.8-10.3-40.8c-11.4-11.4,4.7-42-17.9-30c-10.1,0-61.3-21.6-28-21.6c10.6-6.1,22.8-8.6,34.9-7
	c14-14-36.3-42-42.3-48.9c-10.4-5.3-12.9-12.9-20-21.7c-18.6-9.8,1.9,25.2-8.5,9.7c-10-7.9-22.3,2.7-33.1,3.4
	c-2.6,2.1-3.2,6.7-4.7,6.7c-7.7-9.9-6.9-14.8-20.5-16c-2.4-13.4-10.7-12-19.8-19.8c-15.5-4.4-8.9-14.6-15.9-26.7
	c-13.9-4.8,5.5-32.1,8.4-32.1c2.2,2.2,19.8,2.6,9.7,6.8c-19.7,2.6-13.5,13.4,1.8,15.5c8.7,16.8,1.1,11.2-12.2,14.8
	c-3.6,6.6,14.5,21.3,20.8,22.2c4.8,8.3,28.8,11.7,11.8-5.2c9.9-12.5,25.3-19.3,41.2-18.1c3.4,5.9,0.1,6.2,8,6
	c2.7-20-46.7-18.9-11.2-47.5c2.8-7-0.3-8.7-5.4-13.4c-13.2,0-31.4,4-31-13.6c12.4-4.1-11.4-7.1-14.5-2c-0.1,0-11.7,37.2,6.7,16.1
	l1.7-0.3c2.1,13.2,35.2,3.9,20.5,25.3c-15.8,8.3-9.8-16.7-24.7-16.4c-6.6,5.6-16.6,4.8-22.2-1.8c-1.5-1.8-2.6-3.9-3.2-6.2
	c-4.4-4-7.6-0.4-5.5,4.7c0,11.3-20.3,51.6-33.9,51.6c-20.6-12.4-38-2.7-51.9,14.8c-9,5,4.1,17.9-8.3,10c-4.2-12.5,2.5-26.1,15-30.4
	c2-0.7,4.2-1.1,6.3-1.2c14.1-11,27-5.9,45.2-7c13.6-16.3-38-7.4-47.4-8.4c-5.3-3.1-6.9-2.4-7.9,3.8c-8.8,7.2-15,17.2-17.5,28.4
	c-6,9.9-16.8,14.3-24.5,22.8c-10.3,18.8-45.6,16.8-65.3,16.8c-3.8,3.4-7,7.3-9.5,11.7c-1.1,14-6.9,14.7-15.9,23.9
	c-0.1,7.3-2.2,14.5-6,20.8c-12,0,10.4-14.1-2.3-17.1c-14.4-10.6-20.4,26.9-23.2,4.1c-2.4-0.6-4.7-1.7-6.7-3.3
	c-5.3-22.3-38.5,10.4-46.2,2.3c-8.3-8.8-5.4-3.1-5.4-3.1c-19.5-0.8-13.6-27,1.1-22.8c5.7-0.5,10.7-10.5,15.4-14.2
	c17.7-8,14.5-14.3,14.5-31.7c-3.9-18.3,8.3-22.4-19.7-19.7c-4,2.3-6.3,4.6-8.5,5.7c-25.5,23.8-25.9-2.1-50.2-7.4
	c-19.8-9.8-27.6-20.1-43.8,3.9c-10.1,7.4,1.6-12.2,1.9-12.7c-0.5-3.9-2.5,0.1-4-2.8c0-27.8-45.2,6.7-44.3-9.9
	c1.3-1.3,1.3-1.3,15-8.1c6.9-5.6,19.2-32.4,6.1-32.4c-9.8,11.2-20.6,21.5-32.3,30.7c-15.6,9.2-4.1,11.6-25.7,2.8
	c-14.1-12.2,15.8-8.2,20-10.3c13.2-11.5,45.8-35.1,31.4-54.7c-4.3,0-6.1,3.3-9.7,5.1c-8.7,0-12.7-14.1-19.4-7.4
	c-14.4-1.4-17.5,5.5-19.3-14.7c-4.7-0.1-6.6,2.3-10.9,4.4c-9.4,7.4-7.4,2-15.6,2.4c-3.3,6.1-10.8,8.4-16.9,5.1
	c-4.1-2.2-6.7-6.6-6.6-11.3c-6.9-9.8-22.5-4.5-32.8-6.6c-15.7-10.3-31.4,0.8-45.1-8c-15.5-2.1-55.7,9.3-37.8-22.7
	c5.7-4,8.9-3.3,6.7-11.8c-9.1-10.5-10.8,1.6-16.5,10.3c-7.8,18.6-10.5,38.9-7.9,58.8c5.9,5.9,3.3-39.1,13.3-25.5
	c11,4.6,29.9-13.5,30.5,3.2c4,7.2,12,14.8,12,23.4c-2.3,2.3,0,8.2,0,11.4c-8.5,9.9-13.8,6.4-23.8,11.8c-6.8,12.8,20.2,3.7,22.2-2.8
	c4.3-4.3,6.3-1.3,7.3-9c3.4,3.4,2.6,8.9,9.6,10.7c7.9,9.9-9.1,12.8,9.1,15.4c1,4.3,9.1,11.1,7.9,12.4c-14.8-0.9-19.3,1.4-18.4,15.8
	c15.6,12.4,18.3-4.3,24.4-10.4c8,8-4.8,11.7-9.4,17.4c-11.6,9.1-44.8-4.1-44.8,17.4c-9.4,11.9,3.1,23.4,15.2,23
	c2-7.3,8.2-2.7,13.6-2.7c11.2-8.1,7,2.6,16.1-3.6c0,5.1-8.3,4.5-11.1,6c-2.6,14.4-14.9,32.4,9.9,32.4c14.6-10.2,16,28.6,1.7,18.3
	c-14.6-4.6-18.8,1.8-8.9,14.5c3.9,23.4,23,28.2,44.2,23.1c21.3,3.5,29.2,15,36.3,35.2c3.6,3.3,7.6,6.1,11.8,8.5
	c2.6,5.1,1.9,25.1,11.8,24.1c3.1-5.7,4.6,8.5,5.9,11.3c7.7,17.3,11.5,22.1,21,20.6c2.2-0.4,1.8,1.8,0.1,2c-2.9,0.6-5.9,0.5-8.8-0.1
	c-9.7-4.3-13-20.4-18.1-29.5c-12.8-6.7-11.2-18.6-20.1-28.6c-19.5-11.2-9.9-35.7-38.8-37.1c-16.4,1.2-54.7,15.4-65.5-1
	c-17.4-17.1-21.5,4.1-23.8,20.7c-4,7.9-16.3,23.6-7.4,32.5c0,18,19.9,22.5,0.3,33.9c-8.5-0.8,7.6-8.9,2.8-13.7
	c-10.3-1.1-19.7-6.3-26.2-14.3c-11.9-8.1-19.1,13.8-13.1,17.7c19.3,1.9,5.8,33.7-7,28.3c-3.9-8.5-9-8.5-17.1-11.4
	c-16-21.9,11.3-10.7,11.3-23.5c-4.1-8.3-12.2-33.7-19.7-38.5c-20.9-2.8-42.1-0.8-62.1,5.7c-18.7,0-44.8-9.6-56.3,10.1
	c-21,12.4,0.3,18.3-7.6,23.5c-17.9,2-16.3,25.8-16.1,39.7c3.2,3.2,15.6,1.8,21.8,5.3c16.7,2.6,9.3,3.1,8.3,14
	c-3.3,12.4-5.3,25-6.1,37.8c9.7,6-2.6,35.7-5.8,42.1c-17.2,17.2-16.5,41.8-18.8,64.4c-4,10.1-37.5-4.2-49,1.6
	C547,719.5,561.2,725.7,542.7,727.5z M1604.5,1613.3c2.5-9.9-9.8-22.5-18.5-23.1c-6-2.6-8.8-9.1-12.6-14.2c-12-10.9-22,10-32.2-9.5
	c-0.5-12.3-7.9-23.2-19.1-28.2c-14.4,0-13.6-2-19.8-13.6c-13.3-10-28.3-43.3-45.2-34.9c-12.1,0-16-2.8-19.3-13.6
	c-15.3-13.1-16.7-4.4-18.8-29.6c-12-8.3-9-15.2-18.6-24.9c-0.9-12.8-2.4-11.5-13-17c-11.3-11.3-4.9-12.7,2.7-20.1
	c0.9-16.5,20.7-26,1.2-34.7c-2.7-7.1,4.8-5.2-2.7-11.3c-10.7-1.6-40.8-24-40.8-36.2c6.4-16-23.7-19.1-12-30.8c0-2.5,0.5-4.9,1.5-7.2
	c-8.5-0.2-18.5-8.9-10.9-16.8c0.5-0.7,1.1-1.5,1.8-2.6c-6.9-0.4-16.8-0.1-21.3-5.8c-2-4.2,0.8-5.5-0.6-10.3l0-0.1
	c-2.3-0.6-4.3-1.9-5.8-3.7c-0.1,0.1-0.2,0.3-0.3,0.4c-1.1,1.6-6.9,3.6-8.6,4.5c-2.8,1-5.9,0.4-8-1.7c-3.3,2.5-7.6,3.1-11.4,1.6
	c-2.2,1.5-5.2,4.2-8.2,3.5c-3.9-0.4-7.7-1.2-11.3-2.4c-1.5,7.3-10.3,5.6-13.7,0.7l-9.2-8.2c-9.2-1.7-6.8-14-7.4-18.7
	c-1.3,0.6-2.8,0.9-4.2,1c0.3,0-0.1,0.4-0.9,0.9c-1.4,3.5-4.7,6-8.6,6.2c0.3,0.6,0.5,1.3,0.7,2c2.2,2.4,3.6,5.5,3.9,8.7
	c0.1,0.7,0.3,1.4,0.6,2.1c4.2,4,0,16.4-0.8,22.6c-0.1,5.4,1.7,16.1-6.9,15.5c-11.3-1.6-8.1,0.6-13.6-2.4l-6.7-5.9
	c-2-1.4-2.5-5.5-3.2-7.7c-3.2,0.3-6.5-0.6-9.1-2.5c-1.1-0.7-2.3-1.3-3.4-1.8c-2.3-0.3-4.6-1-6.8-2.1c-3.4-1.3-6.6-2.8-9.7-4.6
	c-3.2,0.2-6.3,0-9.5-0.4c-2.3,1.8-4.9,3.3-7.7,4.4c6.7,6.9,21,16.2,17.8,23.8c-1.2,3.5-4.5,5.9-8.3,6c-3.2,0.5-6.5,0.6-9.7,0.2
	l-1.7,1.5c2.6,8.2-6.5,11.3-12.1,6.5l-2.7-1.6c-3.8-3.3-9.6-1.2-13.3-4.6c-3.2-3.7-1.7-10.2-0.3-14.9c-1.5-4.4-3.5-8.6-6-12.5
	l-0.1-1c-2.8,1.1-6,0.7-8.4-1.1c-6.7-3.7-9-8.7-17.8-12.6c-4.2-1.4-7-5.4-6.8-9.8c-0.5-2.1-1.3-4.1-2.4-6
	c-11.6-5.2-10.8-3.5-16.6-13h-3.7c-1.6,0.2-3.3,0.1-4.9-0.1c-0.8-0.2-1.4-0.3-2-0.4c-1.9,3.7-3.3,8.2-7.3,10.2l0.5,0.7
	c1.9,2.3,2.4,5.4,1.4,8.3c-1.2,2.6-3.6,4.4-6.5,4.8c-1.1,0.2-2.1,0.4-3.2,0.4c-0.1,0.9-0.3,1.8-0.7,2.6c-1.4,2.4-3.6,4-6.3,4.6
	c-3.5,6-14.7,3.6-20.5,0.6c-1.8,2.3-3.9,4.3-6.2,6.1c1.6,7.4,5,13.4,1.2,17.8c-2.1,1.8-4.5,3.4-7.1,4.5c-1.5,0.9-3.2,1.5-4.9,1.8
	c-1,4.3-0.1,10.4-5.6,11.7l-9,2.3c-2,0.1-4.1,0-6.1-0.4c-0.7,1-1.7,1.9-2.8,2.5l0.1,0.1c2.3,3.3,3.5,7.3,3.5,11.4
	c3.2,4.2,2.4,10.1-1.8,13.3c-1,0.8-2.2,1.4-3.5,1.7c-4,2-5.2,4.9-10.1,4.3c-3.5,1.1-7.3,0.2-9.9-2.3c-2.4-1.4-3.9-4-4-6.8
	c-3.3-3.6-8.4-4.9-13.1-3.5c-2.9,0.6-5.9,0.6-8.7,0l-0.3,0.5c-0.8,0.9-1.4,2-1.7,3.2c-0.2,2.7-2,4.9-4.5,5.8
	c-2.6,0.6-5.4-0.3-7.1-2.3c-1.3-1.3-2.4-2.8-3.4-4.4l-0.2,0.1c-2.8,1.6-5.9,2.2-9.1,2c0.2,1.2,0.5,2.4,1,3.5c4.9,3.3,6.2,9.9,3,14.8
	c-0.3,0.5-0.6,0.9-1,1.3c0.3,8.6,1.9,17.1,4.7,25.2c0.7,2.2,0.1,4.7-1.5,6.4c1.4,0,2.8,0.4,4,1.1c3.7,1.5,6,5.1,5.7,9.1
	c0,1.8-0.3,3.6-1.1,5.3c2.3,1.6,3.6,4.3,3.4,7.1c0.4,3.2,0.5,6.3,0.2,9.5c-1.2,2.6-2.2,8.2-5,9.5c-0.5,1.3-0.8,2.3-1.1,3.2
	c0.1,0.7,0.8,4.7,1.1,6.2c1,1.7,1.4,3.7,1.1,5.7c0,0.8-0.3,5.6-0.3,6.3c1.1,0.1,1.7,0.2,2.2,0.3c1.5,0.1,3,0,4.4-0.3
	c1.1-0.1,2.2-0.2,3.3-0.1l1.7-3.2c3-6.1,2.7-11.7,10.2-12.7c4.3-1.4,10.4-2.9,14.5-4.6c-7.1-5.2-23.5-3.8-24.2-14.1
	c0-6.4,7.1-8.3,13.9-10.3c8.8-2.2,23.5-8.2,29.2-4.8c2.9,0.8,5.9,1.2,9,1.3c3.7-1.9,8.1-2.3,12-0.9c4.9,3.2,6.4,9.6,3.3,14.6l0,0.8
	c1.1-0.1,3-0.3,4.1-0.5c6,0.5,9.2-6.5,15.1-5.6c3.9,1,4.8,4.1,6.2,8.6c2.3,6.9,4.9,13.8,7.8,20.5c1.5,2.1,1.9,4.8,0.9,7.1
	c1.7,0.9,3.2,2.1,4.3,3.7c2.4,2.3,3.4,5.7,2.6,8.9c1,2,1.8,4,2.4,6.2c1.3-1.2,2.5-2.5,3.6-4c2.1-3.9,6.1-6.4,10.6-6.5
	c6.4,0.6,7.4,7.2,11,11c3.6,1.6,5.9,13.4,7,18.8c0.9,3,1,6.1,0.2,9.1c-1,4.5-1.4,9.2-1.3,13.8c2.7,4.2,3.8,9.2,3.1,14.2
	c-0.1,4.2,0.3,8.4,1.4,12.5l6.1,4.7l6.4,7.8c0.5,0.2,1,0.5,1.5,0.8c3.3,3.4,4.9,8.1,4.4,12.8c0,1.6-0.2,3.2-0.8,4.7
	c4.3,2,8,5.2,10.7,9.2c0.5,0.9,1.2,1.7,2.1,2.3c7.5,6.1,12.2,15,13.1,24.6c6.7,4.5,4.9,10.6,3.4,16.1c-3.1,6.8,0,14.6-3.6,21.2
	c1.6,1.3,3.2,2.5,4.3,3.3c4.1,0.1,7.7,2.7,9.1,6.6c2.2,9-12,15.3-19.9,15.7c-4.7,1.5-12.6,5.6-15.2-1.2c0,0.6,0.2,1.2,0.3,1.7
	c3.9,3.6,6.1,8.6,5.9,13.9c-0.1,0.5-0.3,1-0.5,1.4l0.8,2.1c2,4.2,1.9,9-0.4,13.1c-2.9,3.5-7.4,5.2-11.9,4.7
	c-1.4,2.9-3.3,5.6-5.6,7.8c-2.9,5.2-9.1,7.4-14.6,5.4l-0.5,0.5c-2.8,3.6-7.2,5.4-11.7,4.9c-1.6,3-3.5,5.9-5.8,8.4
	c3.8,4.5,6.4,9.8,7.5,15.5c2.8,9,3.4,10.8,3.4,10.9c0.2,0.6,0.3,1.3,0.3,1.9c0.4,7.7,8.2,12.8,7.2,20.7c0,1.5-0.3,3-0.8,4.5
	c1.5,1.4,2.7,2.5,3.7,3.4c4.9,2.7,6.7,8.9,4,13.8c-0.2,0.4-0.5,0.8-0.8,1.2c-1.1,1.2-1.8,2.8-1.8,4.4c1.4,3.8,1.2,7.9-0.4,11.6
	c-0.8,2.4-2.1,4.6-3.8,6.4l-0.3,2.3c0.8,1.7,1.4,3.5,1.6,5.4c0.3,1.8,0.4,3.7,0.1,5.6c0,0.8,0.1,1.5,0.4,2.2
	c0.9,3.2,1.1,6.6,0.6,9.9l3.7,5.8c3.3,4.8-1.8,9-5.4,11.6c0.5,1,1,2,1.6,2.9c0.5,0.9,1,1.9,1.3,3l1,2.8c2.3,2.8,2.6,6.7,0.9,9.9
	c0.4,0.9,0.6,1.9,0.6,2.9c-0.5,3.1-2.2,5.8-4.8,7.5c-0.8,4.3-4.9,7.1-9.2,6.3c-0.6-0.1-1.3-0.3-1.9-0.6c-3.5-0.5-6.8-1.6-9.9-3.2
	c-3.9,2.7-8.6,4-13.3,3.9c-4.1,0.7-8.3,0.4-12.3-0.8c-1.1,1.2-1.8,2.7-2.1,4.3c-2.1,10.2-12.2,7.9-19.4,9.7
	c-1.8-0.3-3.5-0.9-5.1-1.7c-1.2,1.2-2.6,2.2-4,3.1c-1.5,1.9-3.6,3.2-6,3.8c-0.3,0.1-0.7,0.2-1,0.3c-0.2,4.2-3.8,7.4-8,7.2
	c-0.4,0-0.8-0.1-1.3-0.2c-0.3,0.1-0.7,0.2-1.1,0.3c-0.6,1.2-1.5,2.2-2.5,3.1c-1.6,2.3-3.9,4-6.6,4.6c-0.3,0.4-0.6,0.8-1,1.2
	c-1.1,0.9-2.3,1.7-3.6,2.3c1,1.7,1.5,3.7,1.2,5.6c0,12.5,12.6,12.6,15.5,1.5c10.2-0.4,8,0,12.5-8.5c2.4-0.1,16.4,1.6,10.8,5.1
	c-3.5,12.5,17.7,3.4,22.7,13.3c7.4,7.5,20.5,11.2,20.5,24c-7.6,10.3,5.3,14.6,11.4,6.2c18.8-2.2,2.7,13.6,11.7,23.4
	c14.9-0.9,28.8-19.3,35.1-32c0-3.3-11.1-36,1.6-31.5c10.6,14,32.5-4.7,39.8,5c12.7,3.2,7.3,7.3,21.7,2.9c21.6,0-1.6,9.6,11.4,11.4
	c19.7,13.6-1.2,20.5,29.7,20.5c6.9-3.4,23.1-18.1,23.8-2.5c6.2,0.8,9-8.2,15.1-10.8c2-20.2,23.3-10.7,36.7-18.3
	c6.6-21.3,21.2-16.3,41.9-15.7c12.2-6.7,26.2-9.1,39.9-6.9c14.4-0.2,22.2-0.3,11.1-14.5c0-10,14.8-18.6,15.5-3.9
	c10.1,1,28.1-8.4,31.8,4.4c9.2,9.8,7.1,6.9,18.7,5.8c3.6-12.2,20.3-12.6,30.8-9.4c2.4,2.4,1.5,6.2,3.1,9.4
	c16.3,6.5-11.5,17.2,11.6,21.3c7.1,7.1,20.4,21.9,29.4,10.6c-1.2-11.4,1.4-22.8,7.4-32.5c0-6.6-6.8-22.8-15.1-16.3
	c-22.3,4.6-12.2-17.1-4.3-27.6c4-10.5-4.7-17.4-8.8-26.5c-12.1-9.7-14.7-20.2-27.1-31.1c-10.3-17.9,27.6-34.6,36.6-45.2
	c8.4-6.1,17.9-10.2,28.1-12.1c36.7-2,39.3-35.9,34.9-63.7c-3-1.1,14.2-9.1,21.6-1.8c23,0,18.9,20.8,36.2-4.3
	c1.3-9.4,5.3-22.9-1.7-30.2C1605.1,1621.7,1612,1618.2,1604.5,1613.3L1604.5,1613.3z M1745.9,992.9c0-25.9-40.5-14.1-40.5-35.9
	c10.8-16,5.4-22.7-4.8-31.1c-0.6,0.7-1.3,1.3-1.9,2c-0.8,3.5-2.9,6.5-5.8,8.5c-2.5,1.8-5.5,3-8.6,3.4c-4,1.7-8.1,0-12.2,0.2
	c-7.1,0.4-13.5,3.6-20.5,4.4c-1.5,7.2-10.5,5.6-15.9,7.5c-3.6,2.2-8.1,2.4-12,0.7l-2.5-0.8c-1.7-0.7-3.4-1.1-5.2-1.1
	c-1.6,0.1-3.2,0.4-4.7,0.9c-0.9,0.7-5.5,5.3-6.5,5.7c0,5.1-1,10.2-3.1,14.9c1.1,4.3-0.5,8.9-4.2,11.5c-2.1,1.3-4.4,2.1-6.8,2.3
	c-0.9,0.1-2,0.3-3.6,0.6c-1.5,0.4-3.1,0.5-4.6,0.3c-7.6,2.2-15.3,3.9-23.2,5.1h-15.4c-6.2,0.8-12.6,0.3-18.6-1.5
	c-7.2,2.9-11.8-4.8-17.3-7.5c-4.2,1.4-8.7,1.4-12.9,0c-1.1,0.8-2,1.6-2.9,2.6c-1.8,2.2-4.4,3.5-7.2,3.7c-2.5,2.8-6.4,3.8-9.9,2.6
	c-3-1.7-4.8-4.9-4.7-8.4c-0.1-1.1-0.2-4.8-0.4-5.8c-1.1-2.9-1.9-6-2.2-9.1l-1-5.9c-0.8-4-0.8-8.1,0.2-12.1c-0.7-0.5-1.3-1.2-1.8-1.9
	c-2.7-1.8-5.5-3.4-8.4-4.8c-2.2-0.8-4-2.4-5.1-4.5c-2.3-1-4-3-4.6-5.4c-0.4-2.8,0.5-5.6,2.4-7.6c0.9-1.3,2-2.3,3.4-3
	c2-1.9,4.1-3.6,6.3-5.1c-0.1,0,0.1-0.6,0.4-1.3c0.7-2.1,1.1-4.3,1.2-6.5c-3.6-6.1,0.1-13.2-2.6-19.4l-4.3-4.9h0
	c-3.5,0.2-6.9-1.3-9-4.2l-1.4-1.7c-1.6-1.5-3.5-2.5-5.1-4c-0.4,0-0.9-0.1-1.5-0.1c-4.2,1.9-9.1,0.8-12.1-2.6
	c-1.5-1.3-2.3-2.1-2.9-2.7c-3.5-1.7-8-1.7-10-5.5c-0.5-0.6-1-1.3-1.3-2.1c-4,2.5-9.1,1.8-12.4-1.5c-2.1-1.5-3.8-3.5-4.8-5.9
	c-3,2.8-7.7,3-10.4,6.1c-7,8.2-14.9-1.8-17.3-8.9c-0.6,0.1-1.3,0.2-2.4,0.3c-0.9,0.9-1.9,1.5-3.1,1.8c-2.8,0.4-5.6-0.6-7.4-2.8
	c-2.4-2.1-7.1-5.4-9.7-7.3c-4.6-0.7-7-5.8-8.3-8.7c-2.3-4.6-4.8-5.2-6-10c-3.3-0.5-6.2-2.7-7.6-5.8c-0.7-3.8,0.4-7.6,3.1-10.4
	c2.2-3,3.8-4.8,4.8-6c-0.4-1.5-0.9-2.7-0.9-2.8c-1.3-4.5-3.6-10.1,0.6-13.8c-0.2-2.4,0-4.7,0.5-7c-2-3.5-2.3-7.7-0.9-11.5
	c0.7-2.5,2.2-6.8,2.7-7.8l0.4-2c0-1.1,0.1-2.3,0.4-3.4c-0.9-2.4-1.2-4.9-1-7.4c-0.6-1-1.1-2-1.3-3.2c-1.9-0.3-3.7-0.8-5.5-1.3
	c-2.8-1.7-5.4-3.7-7.8-5.9c-4.8-4.6-7.2-11.2-6.5-17.8c1.4-4.2,1.7-10.5,6.8-11.8c1.2-1.9,2.2-3.9,3.1-5.9
	c-2.8-3.2-6.7-5.8-6.7-10.6c-3-10.8,4.7-15.8,7.4-25.9l-1.3-2.9c-0.5-0.1-1.1-0.2-1.6-0.3c-0.9,0.2-1.8,0.4-2.8,0.5
	c-5.1,0.2-10.1-1.8-13.7-5.5c-0.4-0.4-1.1-0.6-2.6-0.1c-4.3,1.4-6.3,2-7.4,2.3c-5.7,1.7-12-0.5-15.3-5.5c-2.1,1.9-5.1,2.5-7.7,1.4
	c-3.1-1.8-4.9-5.3-4.4-8.9c0-1.4,0.1-2.8,0.3-4.3c-8.5-1.2-9.2-1.3-13-7c-0.8-0.3-1.5-0.6-2.2-1.1c-3.6-0.6-7.1-2-10.1-4
	c-2-0.7-3.9-1.3-6-1.7c-1.9,1.3-4,2.3-6.1,3.1c0.2,4.5,0.9,10.5-5,11.5c-4.2,5.7-4.5,8.6-11.9,12c-3.5,5.8-10.2,8.8-16.8,7.4
	c-4.3-1-8.9,0.5-12.9-1.9c-0.8-0.3-1.6-0.8-2.4-1.2c-2,0.3-3.9,0.9-5.8,1.7c-4.8,2.5-10.6,1.9-14.8-1.6c-2.7,4.3-7.2,7.2-12.3,7.9
	l-4.7,1.2c-4.4,1.2-8.7,3.1-12.6,5.6c2.3,3.4,3.2,7.6,2.6,11.6c5.6,6.4,10.8,13.1,15.4,20.2c1.9,3.3,5.3,5.4,6.7,9.1
	c2.1,4.3,3.4,8.8,3.9,13.5c2,3.2,3.7,6.5,5.2,9.9c1.2,6.3-2,12.6-7.9,15.2c-0.2,0.4-0.4,0.9-0.6,1.3c4.7,1,8.8,3.9,11.3,8
	c1,1.7,2.4,3.2,4.1,4.3c4,2.2,5.5,7.2,3.3,11.2c-0.1,0.2-0.3,0.5-0.4,0.7c-0.9,2-2.5,3.6-4.4,4.7c-0.5,3.6,0.7,7.3,3.3,9.9
	c6.4,2.6,5.3,8.6,4.5,12.5c-0.6,4.8-3.5,9-7.8,11.2c2.7,4.3,3.4,9.5,2,14.3c-1.5,3.9-5.1,6.7-9.2,7.2c0.4,7-3.3,19.3-12.5,14.7
	c-5.9,0.8-11.9,1.2-17.9,1c-2.9-0.3-5.9,0.5-8.2,2.2c-3.7,1.6-7.8,2.4-11.8,2.2c-0.2,1.6-0.6,3.7-0.6,4.9c0.5,0.7,0.9,1.3,1.5,1.9
	c3.9,5.5,5.6,12.1,4.9,18.8c-1.7,5.7-8.1,9.1-9.2,15.5c1.1,4.6,3.3,8.9,6.4,12.5c4.8,9.4,7.7,19.6,8.6,30.2c0.6,4.1-9,10.9-11,13.7
	l-9.5,8.3c-5.7,3.4-13.3,0.9-19.6,0.7l-9,3.7c-3.9,16.2-8,13.2-17.3,19.4c-5.9,3.3-10.2,9.1-16.9,10.7c-3.8,1.8-8.2,2-12,3.8
	c-0.1,4.2-3.5,7.6-7.7,7.6l-0.3,0.4c1.5,2.2,2.1,4.8,1.6,7.4c0,0.4-0.1,0.9-0.1,1.4c0.6,0.4,1.1,0.9,1.6,1.4l0.4,0.2
	c1.4,0.7,2.7,1.6,3.9,2.6l0.1,0.2c1.8-0.2,3.7,0,5.5,0.7c2.8,0.9,5,3.2,5.6,6.1l0,0.3c1.5,1.2,2.3,3.1,2.2,5
	c0.4,0.1,0.8,0.1,1.3,0.2c2,0.4,4.1,0.5,6.2,0.3c4.7-0.6,8.9,2.7,9.5,7.4c0.1,0.9,0.1,1.8-0.1,2.6c0.3,1.6,0.6,3.9,1,6.7
	c0.5,2.5,0.3,5.2-0.7,7.6c0,4.1-0.4,7-3.5,8.8c-0.4,0.6-1.3,1.9-1.7,2.4c-0.6,1.5-0.9,2.5-1.5,3.9c0.3,0.6,0.6,1.3,0.8,2
	c9.8,2.3,6.7,10.6,2.1,16.4c-1.8,1.5-4,2.4-6.3,2.7c-2,0.6-4.2,0.5-6.1-0.3c-0.8,0-2.7,0.1-4,0.2l-2.4,1.5c1.2,1.4,1.8,3.3,1.5,5.2
	c0.5,2.4-0.4,4.8-2.2,6.3c1.2,1.5,2,3.3,2.3,5.1c0.8,2.4,0.6,5-0.6,7.3c0.1,2.6-0.3,5.2-1.1,7.6c-1.3,2.7-3.6,4.9-6.5,6
	c-1.6,0.9-3.4,1.7-5.2,2.3c0.1,1.2-0.2,2.3-0.7,3.4c0.1-0.1-0.1,0.6-0.8,3.1c-0.5,2.7-2.1,5.1-4.3,6.6c0.2,4-0.2,8-1.4,11.8
	l-1.5,1.6c0.7-0.5,1.6-0.9,2.5-1.1c0.4-0.9,0.9-1.8,1.5-2.7c1.2-2.2,3.4-3.7,5.9-4c2.3,0.1,4.4,1.1,6,2.6c5.6,2.3,11,5.2,10.3,12.4
	c0.6,1.8,0.9,3.7,0.9,5.7c0.2,3.3-1.7,6.3-4.8,7.5c0.6,0.6,1.2,1.3,1.7,2.1c1.8,0.1,3.6,0.3,5.3,0.7l9.9,0c2.7-0.3,5.3,0.8,7.1,2.8
	c0.8,1.2,1.2,2.6,1.2,4.1c0.7,1.1,1.7,2.6,2.4,3.6c4.5,3,12.2,2.8,14.3,9.7c1.7,3.1,2.8,6.5,3.5,9.9c6.5,3.3,12.4,7.6,17.7,12.5
	c1.3-0.2,2.5-0.2,3.8,0c4.5,0,8.6,2.9,10.1,7.1c3.2,6.6,10.7,17,6.7,23.5c-0.1,0.1-0.1,0.3-0.1,0.4c2.2,0.6,4.3,1.4,6.4,2.4
	c2.8-2.8,7-8,11.2-8.5c-6-6.3-20.8-15.8-16.6-25.3c3.8-6.1,10.7-0.3,15.7-3.5c3.5-2.5,9.8-7.2,15.1-3.9l0.2,0.1
	c3,0.2,8.3-0.8,10.5,1.8c2.5,1.5,5.2,2.8,8,3.7c4,1,8,2.5,11.6,4.5l0.2,0.1c4.3-2.3,11.4-3,12.9,2.9l2.8,10l3.7,3.3l1.2,0.1
	c-0.1-1.8,0-3.5,0.3-5.2c1-4.4,1.7-8.9,2.2-13.4c-1.2-2.5-2.1-5.1-2.5-7.9c-1.6-1.2-2.7-3-3-5c-0.7-3-3.7-5.4-3-8.7
	c1.3-5.2,5.7-9,11-9.7c1.2-1.7,2.7-3.2,4.5-4.4c2.1-1.9,4.9-3,7.7-3.2c0-0.1,0.7-0.4,2.1-1.2c3.3-2.5,7.7-2.7,11.2-0.4
	c3,2.5,4.4,6.4,3.7,10.2c0.4,3.4,0.6,6.8,0.5,10.3l0.1,0.1l5.3,3.5l5,4.9c0.8-0.6,1.7-1,2.6-1.4c3.5-2,7.8-1.7,10.9,0.9
	c1.7,0.2,3.3,0.5,4.5,0.7c2.8-3.2,7.3-4.4,11.3-3c3.9-6.1,6.4-5.5,14.7-0.5l2.1-0.9c2.1-2.3,4.4-4.4,7-6.1l3.2,5.4l5.6-2.9
	c0.7,1.3,1.5,2.6,2.3,3.9c8.5,1.4,8.9,9.1,9.7,15.1c2.4,0.5,4.9,0.9,7.3,1.1c5.2-1.3,10.6,0.3,14.3,4.1c2.3,3.5,2.3,8.1-0.1,11.6
	c-0.8,1.5-1.5,2.7-2.2,3.7c5,0.9,11.3-0.1,13.2,6.1c0.2,2.5-0.7,4.9-2.4,6.7c0.7,1.7,1.1,3.5,1.3,5.3c10.3,2.5,17,40.1,17,11.9
	c2.1-6.6,2.5-11,8-15.4c5.3-12.3,15.2-22.1,27.7-27.1c7.5-3.4,17.3,0.1,22-3.1c14.7-29.4,40.4,24.9,45.1-21.1
	c8.1-2.5,16.5-4,24.9-4.4c4.7-4.7,4.6-25.9,14.7-15.8c5.4-0.5,12.8-25.7,18.7-9.3c12,6.2,15.5-12.7,14.8-20.8
	c12-10,51.8-0.3,51.8-18.1c4.7-5.3,10.7-9.4,17.4-11.7c8.5,0.7,30.8-8.9,33.5-17.6c4-1.9,8.4-2.5,12.7-1.8
	c9.5-2.9,13.7-8.8,0.6-11.4c-5.3-2.9-5.8-7.1-12.2-10.6c0-27.7,17.2-6.2,29.4-12.8c6.8,0.8,13.2,3.5,18.5,7.7
	c6.1,7.4-4.4,25,7.7,16.3c14.6,0.4-1.9,12.9,5.1,17.7c15.2,3,21.7,12.3,24.8-8.3c14.8-28.6,23.2-60.2,24.5-92.4
	C1751.9,1009.1,1748.3,1009.7,1745.9,992.9L1745.9,992.9z M1074,1626.8c0,0.3,0.2,0.6,0.2,0.9
	C1074.1,1627.4,1074.1,1627.1,1074,1626.8L1074,1626.8L1074,1626.8z M615.5,1497.1c1.1-1.5,2.5-2.7,4.2-3.5c-0.7-3-0.3-6.2,1.2-8.9
	c1.5-4.4,3.7-8.5,6.4-12.1l0.3-0.3c-5.9-4.2-13.2-4.9-14.5-13.6c-2.5-1.8-4-4.8-3.8-7.9c-2.6-6.1,2.1-10.5,6.1-14
	c-0.8-2.3-1.1-4.7-0.8-7c-0.8-1.3-1.5-2.6-2.2-4c-4.7,2.7-10.6,1.1-13.3-3.6c-0.1-0.1-0.2-0.3-0.2-0.4c-0.9-1.3-1.6-2.7-2-4.3
	c-4,0.8-8-1-10.1-4.5c-4.3-2.2-5.5-2.5-9.2-0.8c-2.5,1.2-5.2,1.8-8,1.7c-1.2,3.6-5.1,5.6-8.7,4.4c-0.1,0-0.2-0.1-0.3-0.1
	c-2.2,3.2-6.2,4.5-9.9,3.2l-1.9,1.7c-2.5,3.1-6.5,4.6-10.5,3.9l-1.3,0.8c-1.9,2.4-4.6,3.9-7.6,4.4c-2.3,0.8-4.8,0.8-7.2,0.3
	l-1.8,0.2c-3.3,3.2-8.5,3.3-12,0.3c-1-0.6-2-1.3-2.8-2.1l-0.1,0c-9.1,0-5.8,1.2-10.6-0.8c-1.5,0-3-0.2-4.4-0.8l-0.5-0.2
	c-2.7,0.7-5.6,0.6-8.3-0.5c-1.2-0.3-2.3-0.5-3.5-0.7c-3.4,2.5-8.1,1.8-10.6-1.6c0-0.1-0.1-0.1-0.1-0.2c-2.2,0.9-4.7,0.7-6.7-0.6
	c-1.3-0.2-2.7-0.5-3.9-0.7c-2.6,9.4-2.2,17.9,4.6,21.7c25.3,6.8,13.3,27.1,29.8,36.1c1.3,1.3,1.3,1.3,3.6,5.9
	c2.9,18,8.9,29.9,27.6,29.9c3.4-1.8,3.2-7.2,3.2-10.5c-6.8-9.2,20,2.5,23.2,3.8c1.6,1.6,5,30.1,11.3,11.6
	c14.1,10.6,33.8,9.7,46.9-2.2l-2.5-1.6l5.1-7.9C607.2,1505.5,611.4,1500.8,615.5,1497.1z M944.4,1886.3c1-3,3.5-5.3,6.6-6l1.6-0.6
	c0.3-0.1,0.5-0.2,0.8-0.3c0.1-1.6,0.6-3.1,1.7-4.3c2.1-2.6,5.5-2.1,8.4-2.9c1.4-1.3,2.9-2.5,4.6-3.5c4.1-4.1,10.4-5.1,15.6-2.5
	c1.1-0.2,3-0.5,6.2-1.1c1.9-5.5,6.7-13.4,12.1-14.2c4.7-0.3,4.1,2.8,9.5,2.6c2.6-0.2,8.4-0.2,9.5-1.3c4.8-2.2,10.4-7,15.4-0.8
	c1.2,0.4,2.3,0.8,3.5,1c0.5-0.7,1.1-1.3,1.8-1.9c-0.1-1.8,0.2-3.6,0.9-5.2c-0.9-1.7-1.6-3.6-2.1-5.5v0c-2.4-4.7-7.8-11.3-2.5-15.8
	l3.6-2.9c-1.6-2.4-3.9-5.5-2.7-8.6c0.4-1.9,0.4-3.8-0.1-5.6c-0.8-3.1-1.1-6.3-0.9-9.5c-1.4-3.3-1.9-6.9-1.4-10.4c0-4,3.2-6.6,4.6-10
	c0.3-0.6,0.4-1.2,0.6-1.9c-1.8-5.1-1.1-10.7,2-15.2l0,0c-1.3-1.1-2.8-2.5-4.7-4.3c-2.9-2.1-4.6-5.3-4.9-8.8c0.3-1.8,1.1-3.5,2.3-4.8
	c0.1-1-0.2-2-0.9-2.7c-3.4-4.8-5.6-10.4-6.3-16.3c-0.4-1.2-1.3-4-3.2-10.3c-1.3-4-1.6-5.3-1.9-5.9c-0.5-0.9-0.9-1.8-1.3-2.8
	c-0.4-0.9-0.9-1.8-1.4-2.6c-3.5-4.2-4.1-10.1-1.5-14.9c2-3.2,5.3-5.7,5.9-9.7c1.4-6.8,7.7-4.7,12.6-4.6c1.2-2,3-3.6,5.1-4.6
	c2.6-1.8,6-1.8,8.8-0.2c1.3-2.2,3.7-3.5,4.8-6c0.8-5.2,5.7-8.6,10.9-7.8c0.1,0,0.2,0,0.3,0h1.2c-1.9-5.2-3.4-8.9-1.9-11.5
	c-0.6-0.7-1.5-1.9-2.3-2.8c-2.9-3.6-4.3-8.2-3.9-12.8c-0.3-3.9,1.1-7.7,3.8-10.5c3.4-1.8,7.3-2.5,11.1-2.2c3.6,0,6.9,1.9,8.6,5
	c1.8-0.9,3.9-1.2,5.8-0.7c-0.1-0.1,0.2-0.2,1.1-0.5c-5.8-3.5-12-10.5-7.2-17.6c0.4-0.8,0.7-1.7,0.9-2.6c0.1-0.8,0.1-1.6,0-2.3
	c-0.3-5,0.5-10.1,2.2-14.8c0.3-1.1,0.5-1.8,0.6-2.4c-5.5-4.6-4.2-12.5-7.4-18.2c-1-1.8-1.6-2.9-2-3.8c-0.7-1-1.6-1.7-2.6-2.3
	c-5.1-3.4-7.3-10.3-13.8-11.8c-5.8-2.5-9.1-3.9-8.9-8.5c0.4-2.8,2.3-5.2,5-6.2c0.1-1,0-2-0.1-2.9c-3-3-5.8-6.3-8.3-9.8
	c-2.9-1.9-5.5-4-7.9-6.4c-2.7-6.2-3.9-13-3.3-19.7c0-1.7-0.1-3.4-0.2-5c-0.8-0.7-1.4-1.5-1.8-2.5c-1.6-5.9-1.8-12.1-0.4-18.1
	l0.7-4.5c0.2-0.7,0.3-1.4,0.3-2.2c-0.1-0.6-0.2-1.3-0.4-1.9c-0.3-1.4-0.8-3.4-1.4-6.6c-0.3-1.9-0.7-3.8-1.5-5.6
	c-1.7-1.5-3.2-3.3-4.4-5.2c-2.9,4.5-7.6,7.5-12.8,8.4c-3.4,0.9-7,0.2-9.8-2c-1.7-1.8-2.5-4.2-2.1-6.6c0.3-3.8-3-6.8-3-10.7
	c-16.4,2.1-28.9-11.5-7.8-17.5c-1.6-5.3-4.1-10.6-5.9-15.9c-4,1.7-8.3,2.6-12.7,2.8c-4.4,3.1-10.3,7.7-16.2,2.7
	c-2.4-3.2-2.3-7.5,0.2-10.6c0.5-0.9,0.9-1.8,1.3-2.7c-0.3-1.3-0.3-2.6,0-3.9c-5.2,0.2-10.4-0.4-15.5-1.9c-0.6-1.3-9.5,1.5-15.3,3.2
	c5.8,3.1,12.6,3.8,14.1,10.1c0.1,1.4-0.3,2.8-1.1,3.9c0.8,12.3-14.4,13.5-23.4,16.7c-1.3,3.7-3,7.3-5,10.8c-1.5,3-3.8,7.6-10,5.9
	c-3.5-0.6-7,0.8-10.5,0c0.4,3.2-1.8,6.2-5.1,6.7c-4.9,0.8-9,3.6-13.8,4.7c-1.2,2-2.6,4.6-3.4,5.9c-0.7,8.2-8.9,10.5-13.7,15.6
	c-11.8,11.8-22.7,0.1-14.2-10.7c3.7-4-2.6-6-2.6-11.9c-7.7-2.2-12-3.8-13.1-9.6c-5.3-3-5.4-9.2-6.7-14.4c-0.2-0.5-0.3-1-0.5-1.5
	c-0.7,0.2-1.2,0.4-1.7,0.6l1.3,5.8c0.9,3.4-2.3,6.1-2.2,9.5c0,3-1.8,5.8-4.6,7c-3.1,0.9-6.5,0-8.7-2.5c-3-2.5-7-3.9-8.6-7.8
	c-0.3-0.4-0.5-0.9-0.7-1.4l-0.7-0.4c0.2,2.6,0.7,5.2,1.5,7.7c1.6,2.3,1.9,5.2,0.9,7.9c0.2,0.8,0.3,2.4,0.3,3.3l0.1,0.2
	c0.3,0.2,0.7,0.3,1,0.5c2.4-0.8,5.1-0.4,7.2,1.1c4.6,5.2-0.5,10.2-3.1,14.3l0,0.2c1.1,0.9,2,2.1,2.5,3.5c1.8,2,2.7,4.8,2.3,7.5
	c-0.7,2.6-2.4,4.8-4.8,6c-0.8,0.7-1.4,1.3-1.9,1.9c0.5,1.6,0.2,3.4,0.9,5c0.7,1.9,0.8,4,0.2,6c-1.2,2.3-3.3,4-5.9,4.5
	c-0.8,0.3-2.5,0.9-3.2,1.1c1.1,4.5-0.8,9.1-4.7,11.6c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,1-0.2,1.9-0.4,2.8c-1.2,6.3,3.7,14.1-2.8,18.8
	c-0.4,3.1-2.4,5.8-5.1,7.2c-0.9,0.6-1.6,1.4-1.9,2.4c0.6,3.4,0,7-1.8,10l0,0c-0.3,2.1-1.2,4-2.7,5.4c-1.6,2.9-4.3,4.9-7.5,5.6
	c-1.2,0.4-1.9,0.7-2.3,0.8c0.9-0.3-4.3,3.4-5.5,3.8c-0.1,3.4-2.9,6.1-6.2,6.2l-1.2,0.8c-0.7,2.6-2.5,4.7-5,5.7
	c-0.3,0.2-0.5,0.4-0.7,0.7c-3,4.1-7.9,3.5-19.1,5c-6.8,11.6-7.7,28.1-20.9,31.6c-6.9,9.5-10.3,3.5-14,16.3
	c-3.9,3.9-7.7,7.7-11.6,11.6c-14.1,10.5-10.1,17.8-14,33.4c-8.7,12.9-12.8,28.4-11.4,43.9c-2.1,2.4-4.6,4.3-7.4,5.7
	c-1.9,12.1-7.3,23.3-15.4,32.5c-2.9,11-9.3,21.2-5,33.2c5,2.8,7.2,8.8,5,14.1c-6.3,12.3-11.1,25.3-14.3,38.8c0,6,2.5,12.6-0.3,18
	c-8.9,7.6,3.1,18.3,8.4,27.2c2.7,1.9,22.2-3,12.4,9.6c0,17.7,32.6-21.8,38.4-1c19.3,15.4,48.1-25.6,61-2.9
	c12.9,12.9,43.8-4.1,20-12.5c-8.1-13.5,5-29.8,20.2-31.4c3.8-4.6,7.1-8.2,12.1-3c1.5,5.9,2.4,1.4,6,1.4c13.1,6.7,11.1,9.7,11.3,22.7
	c5.9,5.9,6.2-5.5,9.9-8.4c11.4,0,16.4,30.8,29.4,10.6c-0.5-5.6-5.9-3.8-9.1-8.5c0.9-17.3,35.4,14.2,40.9,11
	c0.8-10.1-32-33.5-18.9-33.5c11.1,6.9,21.6,14.7,31.2,23.5c-0.7,17.5,32.7,11.1,39.8,16.1c1.7,3,3.6,5.9,5.7,8.7
	c0.7-0.3,1.4-0.7,2.1-1.1c0.5-0.7,1.1-1.4,1.7-2c1.1-0.8,2.4-1.4,3.8-1.8C943.5,1887.2,943.9,1886.7,944.4,1886.3z"/>
<g className="st1">
	<path className="st2" d="M1572.5,1800c0,53,17,85,65,85c76,0,88-76,88-180v-34h-2c-24,36-70,60-133,60c-125,0-173-93-173-221c0-163,70-253,229-253
		c197,0,223,158,223,331c0,201-5,391-238,391c-102,0-194-43-197-179H1572.5z M1639.5,1625c57,0,79-38,79-134c0-81-12-140-79-140
		c-69,0-78,60-78,140C1561.5,1587,1583.5,1625,1639.5,1625z"/>
</g>
<circle id="svg-groups-c1" className={"st5"+ (modalContent.id == 1 ? ' c-active' : '')} cx="997.5" cy="564.5" r="30" />
<circle id="c1_clicktarget" className="sti" cx="997.5" cy="564.5" r="80" onClick={(e) => toggleModal(0,e) }/>

<circle id="svg-groups-c2" className={"st5"+ (modalContent.id == 2 ? ' c-active' : '')} cx="1513.5" cy="750.5" r="30" />
<circle id="c2_clicktarget" className="sti" cx="1513.5" cy="750.5" r="80" onClick={(e) => toggleModal(1,e) }/>

<circle id="svg-groups-c3" className={"st5"+ (modalContent.id == 3 ? ' c-active' : '')} cx="1345.5" cy="1024.5" r="30" />
<circle id="c3_clicktarget" className="sti" cx="1345.5" cy="1024.5" r="80" onClick={(e) => toggleModal(2,e) }/>

<circle id="svg-groups-c4" className={"st5"+ (modalContent.id == 4 ? ' c-active' : '')} cx="892.5" cy="1165.5" r="30" />
<circle id="c4_clicktarget" className="sti" cx="892.5" cy="1165.5" r="80" onClick={(e) => toggleModal(3,e) }/>

<circle id="svg-groups-c5" className={"st5"+ (modalContent.id == 5 ? ' c-active' : '')} cx="616.5" cy="1000.5" r="30" />
<circle id="c5_clicktarget" className="sti" cx="616.5" cy="1000.5" r="80" onClick={(e) => toggleModal(4,e) }/>

<circle id="svg-groups-c6" className={"st5"+ (modalContent.id == 6 ? ' c-active' : '')} cx="600.5" cy="1300.5" r="30" />
<circle id="c6_clicktarget" className="sti" cx="600.5" cy="1300.5" r="80" onClick={(e) => toggleModal(5,e) }/>

<circle id="svg-groups-c7" className={"st5"+ (modalContent.id == 7 ? ' c-active' : '')} cx="550.5" cy="1494.5" r="30" />
<circle id="c7_clicktarget" className="sti" cx="550.5" cy="1494.5" r="80" onClick={(e) => toggleModal(6,e) }/>

<circle id="svg-groups-c8" className={"st5"+ (modalContent.id == 8 ? ' c-active' : '')} cx="878.5" cy="1668.5" r="30" />
<circle id="c8_clicktarget" className="sti" cx="878.5" cy="1668.5" r="80" onClick={(e) => toggleModal(7,e) }/>

<circle id="svg-groups-c9" className={"st5"+ (modalContent.id == 9 ? ' c-active' : '')} cx="1273.5" cy="1555.5" r="30" />
<circle id="c9_clicktarget" className="sti" cx="1273.5" cy="1555.5" r="80" onClick={(e) => toggleModal(8,e) }/>

</svg>

        
      </div>

      <h1 className="mb-1">{lang.title_about_groups1}</h1>

      <ul className="arrow-list mb-4">
        { lang.list_about_groups1.map(function(item) {
                  return <li key={item}>{item}</li>
              })
        }
      </ul>
      <p dangerouslySetInnerHTML={{__html: lang.text_about_groups1}}></p>

    </motion.div>
  )
}


export default AboutGroups1;