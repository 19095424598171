import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import MainMenu from './MainMenu';
// navigation
// import PageNavigation from './../navigation/PageNavigation';
import SubpageNavigation from './../navigation/SubpageNavigation';
// import SubpageButtonLeft from './../navigation/SubpageButtonLeft';
import SubpageButtonRight from './../navigation/SubpageButtonRight';

import LanguageToggle from './../toggles/LanguageToggle';
// import StyleGuide from './../pages/dev/StyleGuide';

import UpButton from './../navigation/UpButton';
import TouchIcon from './TouchIcon';


const UserInterface = ({ location, zoomLevel, increaseZoom, decreaseZoom, showNav, showModal, showDebug, toggleDebug, toggleModal, setShowNav, navToggle}) => {

  return ( 
    <div id="d-gui">
      <div className="d-head">
      </div>
      <div className="d-head-right">
        
        {/* <button className={'d-btn-round'+ (showNav ? ' active' : '') } onClick={navToggle}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg></button> */}
        {/* <p className="debug">location.pathname: {location.pathname} | touch: {showTouch.isVisible}</p>   */}
      </div>
      <div className="d-head-center">
        {/* <pre>zoom: {zoomLevel}</pre> */}
      </div>
      <div className="d-left">
        {/* <p onClick={toggleModal}>modal</p> */}
        {/* <Link to="/poster">poster</Link> */}
        {/* <Link to="/shader"><button className='d-btn-round'>shader</button></Link> */}
        {/* <Link to="/styleguide">style guide</Link> */}
        {/* <Link to="/changelog">development</Link> */}
        <MainMenu location={location} />
        { location.pathname !== '/' ? <LanguageToggle /> : ''}
        {/* <button className="d-btn-round" onClick={decreaseZoom}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus" className="svg-inline--fa fa-minus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></button> */}
        {/* <button className="d-btn-round" onClick={increaseZoom}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></button> */}

      </div>
      <div className="d-mid-left">
        {/* <SubpageButtonLeft location={location}/> */}
      </div>
      <div className="d-mid-mid">
       <SubpageNavigation location={location}/>
      </div>
      {/* <div className="d-mid-right"> */}
      {/* <PageNavigation location={location} showNav={showNav} setShowNav={setShowNav}/> */}
        {/* <UpButton location={location} /> */}
        <SubpageButtonRight location={location}/>
        {/* <TouchIcon location={location}/> */}
      {/* </div> */}
      <div className="d-right">
        <Logo/>
      </div>
      {/* <div className="d-foot">
        
        
      </div> */}
  </div>
   );
}
 
export default UserInterface;


