import React, { createContext, Component } from 'react';

export const ThemeContext = createContext()

class ThemeContextProvider extends Component {

  constructor(props) {
    super(props);
  };

  componentDidMount() {
    // let value = this.context;
    // console.log('understood. ', this.props);
  }

  componentDidUpdate() {
    
    // console.log('newly understood. ',this.props.direction);
    
    switch(this.props.direction){
      
      case 'left':
        if(this.state.whereAreWeGoing != 'exitRight') this.toggleWhereAreWeGoing('exitRight');        
      break;
      case 'right':
        if(this.state.whereAreWeGoing != 'exitLeft') this.toggleWhereAreWeGoing('exitLeft');        
      break;
      case 'up':
        if(this.state.whereAreWeGoing != 'hidden') this.toggleWhereAreWeGoing('hidden');        
      break;
      case 'home':
        if(this.state.whereAreWeGoing != 'hiddenDelayed') this.toggleWhereAreWeGoing('hiddenDelayed');        
      break;
    }
    
  }

  state = { 
    isPortrait: false,
    videoFinished: false,
    lastDirection: '',
    whereAreWeGoing: 'exitLeft',
    links: [
      {id: 1, locString: 'title_about', path: '/about', active: true, topLevel: true},
      {id: 2, locString: 'title_about_membership1', path: '/about/membership1', active: true, topLevel: false, parent: '/about'},
      {id: 3, locString: 'title_about_membership2', path: '/about/membership2', active: true, topLevel: false, parent: '/about'},
      {id: 4, locString: 'title_about_3', path: '/about/groups1', active: true, topLevel: false, parent: '/about'},
      {id: 5, locString: 'title_about_4', path: '/about/groups2', active: true, topLevel: false, parent: '/about'},
      {id: 6, locString: 'title_about_5', path: '/about/youth', active: true, topLevel: false, parent: '/about'},

      {id: 8, locString: 'title_activities', path: '/activities', active: true, topLevel: true},
      {id: 9, locString: 'title_activities_1', path: '/activities/rulesetting-standardization', active: true, topLevel: false, parent: '/activities'},
      {id: 10, locString: 'title_activities_2', path: '/activities/research-development', active: true, topLevel: false, parent: '/activities'},
      {id: 11, locString: 'title_activities_3', path: '/activities/examination-certification', active: true, topLevel: false, parent: '/activities'},
      {id: 12, locString: 'title_activities_4', path: '/activities/training', active: true, topLevel: false, parent: '/activities'},
      {id: 13, locString: 'title_activities_5', path: '/activities/young-professionals', active: true, topLevel: false, parent: '/activities'},
      {id: 14, locString: 'title_activities_6', path: '/activities/technical-information', active: true, topLevel: false, parent: '/activities'},
      {id: 15, locString: 'title_activities_7', path: '/activities/safety-quality-management', active: true, topLevel: false, parent: '/activities'},
      {id: 16, locString: 'title_activities_8', path: '/activities/occupational-health-safety', active: true, topLevel: false, parent: '/activities'},
      {id: 17, locString: 'title_activities_9', path: '/activities/it-services', active: true, topLevel: false, parent: '/activities'},
      {id: 18, locString: 'title_activities_10', path: '/activities/technical-advice', active: true, topLevel: false, parent: '/activities'},

      {id: 28, locString: 'title_water', path: '/water', active: true, topLevel: true},
      {id: 29, locString: 'title_water_1', path: '/water/climate-change', active: true, topLevel: false, parent: '/water'},
      {id: 30, locString: 'title_water_2', path: '/water/climate-change/2', active: true, topLevel: false, parent: '/water'},
      {id: 31, locString: 'title_water_3', path: '/water/climate-change/3', active: true, topLevel: false, parent: '/water'},
      {id: 31, locString: 'title_water_4', path: '/water/climate-change/4', active: true, topLevel: false, parent: '/water'},
      {id: 31, locString: 'title_water_5', path: '/water/climate-change/5', active: true, topLevel: false, parent: '/water'},
      {id: 32, locString: 'title_water_6', path: '/water/nitrates', active: true, topLevel: false, parent: '/water'},
      {id: 33, locString: 'title_water_7', path: '/water/nitrates/2', active: true, topLevel: false, parent: '/water'},
      {id: 33, locString: 'title_water_8', path: '/water/nitrates/3', active: true, topLevel: false, parent: '/water'},
      {id: 34, locString: 'title_water_9', path: '/water/responsibility', active: true, topLevel: false, parent: '/water'},

      {id: 45, locString: 'title_gas', path: '/gas', active: true, topLevel: true},
      {id: 46, locString: 'title_gas_1', path: '/gas/problem1', active: true, topLevel: false},
      {id: 47, locString: 'title_gas_2', path: '/gas/problem2', active: true, topLevel: false},
      {id: 48, locString: 'title_gas_3', path: '/gas/problem3', active: true, topLevel: false},
      {id: 49, locString: 'title_gas_4', path: '/gas/solutions', active: true, topLevel: false},
      {id: 50, locString: 'title_gas_5', path: '/gas/hydrogen', active: true, topLevel: false},
      {id: 51, locString: 'title_gas_6', path: '/gas/hydrogen-production', active: true, topLevel: false},
      {id: 52, locString: 'title_gas_7', path: '/gas/hydrogen-transport', active: true, topLevel: false},
      {id: 53, locString: 'title_gas_8', path: '/gas/hydrogen-application', active: true, topLevel: false}
    ],
    pages: {
      '/about/membership1': [
        {id: 1, path:'/about/membership1', current: true},
        {id: 2, path:'/about/membership2', current: false}
      ],
      '/about/membership2': [
        {id: 1, path:'/about/membership1', current: false},
        {id: 2, path:'/about/membership2', current: true}
      ],
      '/about/groups1': [
        {id: 1, path:'/about/groups1', current: true},
        {id: 2, path:'/about/groups2', current: false}
      ],
      '/about/groups2': [
        {id: 1, path:'/about/groups1', current: false},
        {id: 2, path:'/about/groups2', current: true}
      ],
      '/activities/rulesetting-standardization': [
        { id: 1, path:'/activities/rulesetting-standardization', current: true },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/research-development': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: true },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/examination-certification': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: true },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/training': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: true },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/young-professionals': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/technical-information': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: true },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/safety-quality-management': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: true },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/occupational-health-safety': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: true },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/it-services': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: true },
        { id:10, path:'/activities/technical-advice', current: false }
      ],
      '/activities/technical-advice': [
        { id: 1, path:'/activities/rulesetting-standardization', current: false },
        { id: 2, path:'/activities/research-development', current: false },
        { id: 3, path:'/activities/examination-certification', current: false },
        { id: 4, path:'/activities/training', current: false },
        { id: 6, path:'/activities/technical-information', current: false },
        { id: 7, path:'/activities/safety-quality-management', current: false },
        { id: 8, path:'/activities/occupational-health-safety', current: false },
        { id: 9, path:'/activities/it-services', current: false },
        { id:10, path:'/activities/technical-advice', current: true }
      ],
      '/water/climate-change': [
        {id: 1, path:'/water/climate-change', current: true},
        {id: 2, path:'/water/climate-change/2', current: false},
        {id: 3, path:'/water/climate-change/3', current: false},
        {id: 4, path:'/water/climate-change/4', current: false},
        {id: 5, path:'/water/climate-change/5', current: false}
      ],
      '/water/climate-change/2': [
        {id: 1, path:'/water/climate-change', current: false},
        {id: 2, path:'/water/climate-change/2', current: true},
        {id: 3, path:'/water/climate-change/3', current: false},
        {id: 4, path:'/water/climate-change/4', current: false},
        {id: 5, path:'/water/climate-change/5', current: false}
      ],
      '/water/climate-change/3': [
        {id: 1, path:'/water/climate-change', current: false},
        {id: 2, path:'/water/climate-change/2', current: false},
        {id: 3, path:'/water/climate-change/3', current: true},
        {id: 4, path:'/water/climate-change/4', current: false},
        {id: 5, path:'/water/climate-change/5', current: false}
      ],
      '/water/climate-change/4': [
        {id: 1, path:'/water/climate-change', current: false},
        {id: 2, path:'/water/climate-change/2', current: false},
        {id: 3, path:'/water/climate-change/3', current: false},
        {id: 4, path:'/water/climate-change/4', current: true},
        {id: 5, path:'/water/climate-change/5', current: false}
      ],
      '/water/climate-change/5': [
        {id: 1, path:'/water/climate-change', current: false},
        {id: 2, path:'/water/climate-change/2', current: false},
        {id: 3, path:'/water/climate-change/3', current: false},
        {id: 4, path:'/water/climate-change/4', current: false},
        {id: 5, path:'/water/climate-change/5', current: true}
      ],
      '/water/nitrates': [
        {id: 1, path:'/water/nitrates', current: true},
        {id: 2, path:'/water/nitrates/2', current: false},
        {id: 3, path:'/water/nitrates/3', current: false}
      ],
      '/water/nitrates/2': [
        {id: 1, path:'/water/nitrates', current: false},
        {id: 2, path:'/water/nitrates/2', current: true},
        {id: 3, path:'/water/nitrates/3', current: false}
      ],
      '/water/nitrates/3': [
        {id: 1, path:'/water/nitrates', current: false},
        {id: 2, path:'/water/nitrates/2', current: false},
        {id: 3, path:'/water/nitrates/3', current: true}
      ],
      // '/gas/problem1': [
      //   {id: 1, path:'/gas/problem1', current: true},
      //   {id: 2, path:'/gas/problem2', current: false},
      //   {id: 3, path:'/gas/problem3', current: false},
      //   {id: 4, path:'/gas/solutions', current: false},
      //   {id: 5, path:'/gas/hydrogen', current: false},
      //   {id: 6, path:'/gas/hydrogen-production', current: false},
      //   {id: 7, path:'/gas/hydrogen-transport', current: false},
      //   {id: 8, path:'/gas/hydrogen-application', current: false}
      // ],
      // '/gas/problem2': [
      //   {id: 1, path:'/gas/problem1', current: false},
      //   {id: 2, path:'/gas/problem2', current: true},
      //   {id: 3, path:'/gas/problem3', current: false},
      //   {id: 4, path:'/gas/solutions', current: false},
      //   {id: 5, path:'/gas/hydrogen', current: false},
      //   {id: 6, path:'/gas/hydrogen-production', current: false},
      //   {id: 7, path:'/gas/hydrogen-transport', current: false},
      //   {id: 8, path:'/gas/hydrogen-application', current: false}
      // ],
      // '/gas/problem3': [
      //   {id: 1, path:'/gas/problem1', current: false},
      //   {id: 2, path:'/gas/problem2', current: false},
      //   {id: 3, path:'/gas/problem3', current: true},
      //   {id: 4, path:'/gas/solutions', current: false},
      //   {id: 5, path:'/gas/hydrogen', current: false},
      //   {id: 6, path:'/gas/hydrogen-production', current: false},
      //   {id: 7, path:'/gas/hydrogen-transport', current: false},
      //   {id: 8, path:'/gas/hydrogen-application', current: false}
      // ],
      // '/gas/solutions': [
      //   {id: 1, path:'/gas/problem1', current: false},
      //   {id: 2, path:'/gas/problem2', current: false},
      //   {id: 3, path:'/gas/problem3', current: false},
      //   {id: 4, path:'/gas/solutions', current: true},
      //   {id: 5, path:'/gas/hydrogen', current: false},
      //   {id: 6, path:'/gas/hydrogen-production', current: false},
      //   {id: 7, path:'/gas/hydrogen-transport', current: false},
      //   {id: 8, path:'/gas/hydrogen-application', current: false}
      // ],
      '/gas/hydrogen': [
        // {id: 1, path:'/gas/problem1', current: false},
        // {id: 2, path:'/gas/problem2', current: false},
        // {id: 3, path:'/gas/problem3', current: false},
        // {id: 4, path:'/gas/solutions', current: false},
        {id: 5, path:'/gas/hydrogen', current: true},
        {id: 6, path:'/gas/hydrogen-production', current: false},
        {id: 7, path:'/gas/hydrogen-transport', current: false},
        {id: 8, path:'/gas/hydrogen-application', current: false}
      ],
      '/gas/hydrogen-production': [
        // {id: 1, path:'/gas/problem1', current: false},
        // {id: 2, path:'/gas/problem2', current: false},
        // {id: 3, path:'/gas/problem3', current: false},
        // {id: 4, path:'/gas/solutions', current: false},
        {id: 5, path:'/gas/hydrogen', current: false},
        {id: 6, path:'/gas/hydrogen-production', current: true},
        {id: 7, path:'/gas/hydrogen-transport', current: false},
        {id: 8, path:'/gas/hydrogen-application', current: false}
      ],
      '/gas/hydrogen-transport': [
        // {id: 1, path:'/gas/problem1', current: false},
        // {id: 2, path:'/gas/problem2', current: false},
        // {id: 3, path:'/gas/problem3', current: false},
        // {id: 4, path:'/gas/solutions', current: false},
        {id: 5, path:'/gas/hydrogen', current: false},
        {id: 6, path:'/gas/hydrogen-production', current: false},
        {id: 7, path:'/gas/hydrogen-transport', current: true},
        {id: 8, path:'/gas/hydrogen-application', current: false}
      ],
      '/gas/hydrogen-application': [
        // {id: 1, path:'/gas/problem1', current: false},
        // {id: 2, path:'/gas/problem2', current: false},
        // {id: 3, path:'/gas/problem3', current: false},
        // {id: 4, path:'/gas/solutions', current: false},
        {id: 5, path:'/gas/hydrogen', current: false},
        {id: 6, path:'/gas/hydrogen-production', current: false},
        {id: 7, path:'/gas/hydrogen-transport', current: false},
        {id: 8, path:'/gas/hydrogen-application', current: true}
      ]
    },
    parents: {
      '/': { path: 'none'},
      '/about': { path: '/'},
      '/about/membership1': { path: '/about'},
      '/about/membership2': { path: '/about'},
      '/about/groups1': { path: '/about'},
      '/about/groups2': { path: 'none'},
      '/about/research': {path: '/about'},
      '/about/youth': {path: '/about'},
      '/activities': { path: '/'},
      '/activities/rulesetting-standardization': { path: '/activities'},
      '/activities/research-development': { path: '/activities'},
      '/activities/examination-certification': { path: '/activities'},
      '/activities/training': { path: '/activities'},
      '/activities/young-professionals': { path: '/activities'},
      '/activities/technical-information': { path: '/activities'},
      '/activities/safety-quality-management': { path: '/activities'},
      '/activities/occupational-health-safety': { path: '/activities'},
      '/activities/it-services': { path: '/activities'},
      '/activities/technical-advice': { path: '/activities'},
      '/water': { path: '/'},
      '/water/climate-change': { path: '/water'},
      '/water/climate-change/2': { path: '/water'},
      '/water/climate-change/3': { path: '/water'},
      '/water/climate-change/4': { path: '/water'},
      '/water/climate-change/5': { path: '/water'},
      '/water/nitrates': { path: '/water'},
      '/water/nitrates/2': { path: '/water'},
      '/water/nitrates/3': { path: '/water'},
      '/gas': { path: '/'},
      '/gas/problem1': { path: '/'},
      '/gas/problem2': { path: '/'},
      '/gas/problem3': { path: '/'},
      '/gas/solutions': { path: '/'},
      '/gas/hydrogen': { path: '/'},
      '/gas/hydrogen-production': { path: '/'},
      '/gas/hydrogen-transport': { path: '/'},
      '/gas/hydrogen-application': { path: '/'},
      '/styleguide': { path: '/'},
      '/poster': { path: '/'},
      '/admin': { path: '/'},
      '/changelog': { path: '/'},
      '/shader': { path: '/'}
    },
    subpagelinks: {
      '/activities': [
        {id: 0, path:'/', direction: 'home', position: 'mid-bottom'}
      ],'/activities/rulesetting-standardization': [
        {id: 0, path:'/activities/technical-advice', direction: 'previous', position: 'mid-bottom'},
        {id: 1, path:'/activities/research-development', direction: 'next'}
      ],
      '/activities/research-development': [
        {id: 1, path:'/activities/rulesetting-standardization', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/activities/examination-certification', direction: 'next'}
      ],
      '/activities/examination-certification': [
        {id: 1, path:'/activities/research-development', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/activities/training', direction: 'next'}
      ],
      '/activities/training': [
        {id: 1, path:'/activities/examination-certification', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/activities/technical-information', direction: 'next'}
      ],
      // '/activities/young-professionals': [
      //   {id: 1, path:'/activities/training', direction: 'previous', position: 'mid-bottom'},
      //   {id: 2, path:'/activities/technical-information', direction: 'next'}
      // ],
      '/activities/technical-information': [
        {id: 1, path:'/activities/training', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/activities/safety-quality-management', direction: 'next'}
      ],
      '/activities/safety-quality-management': [
        {id: 1, path:'/activities/technical-information', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/activities/occupational-health-safety', direction: 'next'}
      ],
      '/activities/occupational-health-safety': [
        {id: 1, path:'/activities/safety-quality-management', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/activities/it-services', direction: 'next'}
      ],
      '/activities/it-services': [
        {id: 1, path:'/activities/occupational-health-safety', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/activities/technical-advice', direction: 'next'}
      ],
      '/activities/technical-advice': [
        {id: 1, path:'/activities/it-services', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/activities', direction: 'up'}
      ],
      '/about': [
        {id: 1, path:'/', direction: 'home', position: 'bottom'}
      ],  

      '/about/membership1': [
        {id: 1, path:'/about/membership2', direction: 'next', position: 'mid-bottom'}
      ],
      '/about/membership2': [
        {id: 1, path:'/about/membership1', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/about', direction: 'up'}
      ],
      '/about/groups1': [
        {id: 1, path:'/about/groups2', direction: 'next', position: 'mid-bottom'}
      ],
      '/about/groups2': [
        {id: 1, path:'/about/groups1', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/about', direction: 'up'}
      ],      
      '/about/research': [
        {id: 1, path:'/about', direction: 'up', position: 'mid-bottom'}
      ],      
      '/about/youth': [
        {id: 1, path:'/about', direction: 'up', position: 'mid-bottom'}
      ],      
      '/water': [
        {id: 1, path:'/', direction: 'home'}
      ],  
      '/water/climate-change': [
        {id: 1, path:'/water/climate-change/2', direction: 'next', position: 'mid-bottom'}
      ],
      '/water/climate-change/2': [
        {id: 1, path:'/water/climate-change', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/water/climate-change/3', direction: 'next'}
      ],
      '/water/climate-change/3': [
        {id: 1, path:'/water/climate-change/2', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/water/climate-change/4', direction: 'next'}
      ],  
      '/water/climate-change/4': [
        {id: 1, path:'/water/climate-change/3', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/water/climate-change/5', direction: 'next'}
      ],  
      '/water/climate-change/5': [
        {id: 1, path:'/water/climate-change/4', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/water', direction: 'up'}
      ],  
      '/water/nitrates': [
        {id: 1, path:'/water/nitrates/2', direction: 'next', position: 'mid-bottom'}
      ],
      '/water/nitrates/2': [
        {id: 1, path:'/water/nitrates', direction: 'previous', position: 'mid-bottom'},
        // {id: 2, path:'/water', direction: 'up'}
        {id: 2, path:'/water/nitrates/3', direction: 'next'}
      ],
      '/water/nitrates/3': [
        {id: 1, path:'/water/nitrates/2', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/water', direction: 'up'}
      ],  
      '/gas': [
        {id: 1, path:'/', direction: 'home'}
      ],  
      '/gas/problem1': [
        {id: 1, path:'/gas/problem2', direction: 'next', position: 'mid-bottom'}
      ],
      '/gas/problem2': [
        {id: 1, path:'/gas/problem1', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/gas/problem3', direction: 'next'}
      ],
      '/gas/problem3': [
        {id: 1, path:'/gas/problem2', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/gas/solutions', direction: 'next'}
      ],
      '/gas/solutions': [
        {id: 1, path:'/gas/problem3', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/gas/hydrogen', direction: 'next'}
      ],
      '/gas/hydrogen': [
        {id: 1, path:'/gas/hydrogen-application', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/gas/hydrogen-production', direction: 'next'}
      ],
      '/gas/hydrogen-production': [
        {id: 1, path:'/gas/hydrogen', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/gas/hydrogen-transport', direction: 'next'}
      ],
      '/gas/hydrogen-transport': [
        {id: 1, path:'/gas/hydrogen-production', direction: 'previous', position: 'mid-bottom'},
        {id: 2, path:'/gas/hydrogen-application', direction: 'next'}
      ], 
      '/gas/hydrogen-application': [
        {id: 1, path:'/gas/hydrogen-transport', direction: 'previous', position: 'mid-center'},
        {id: 2, path:'/', direction: 'home'}
      ]        
    },

    pageTransitions: {
      hidden: { 
        opacity: 0, 
        scale: 1
      },
      visible: { 
        opacity: 1, 
        scale: 1,
        x: 0,
        transition: { duration: 0.4, ease: "easeInOut" }
      },
      exit: {
        scale: 0.9,
        opacity:0,
        transition: { ease: 'easeInOut' }
      },
      exitR3F:{
        opacity: 0,
        transition: { ease: 'easeInOut', duration: 0.4 }
      },
      exitLeft:{
        x:'-100vw',
        transition: { ease: 'easeInOut', duration: 0.4 }
      },
      exitRight:{
        x:'100vw',
        transition: { ease: 'easeInOut', duration: 0.4 }
      },
      exitTop:{
        y:'-100vh',
        transition: { ease: 'easeInOut', duration: 0.4 }
      },
      hiddenDelayed:{
        opacity: 0,
        scale:0.9,
        transition: {
          delay: 0.4,
          duration:0.4
        }
      },
      nowhere:{
        opacity: 0,
        transition:{
          duration:0
        }
      },
      exitBottom:{
        y:'100vh',
        transition: { ease: 'easeInOut', duration: 0.4 }
      },
      enterRight:{
        x:'100vw',
        transition: { ease: 'easeInOut', duration: 0.4 }
      },
      enterLeft:{
        x:'-100vw',
        transition: { ease: 'easeInOut', duration: 0.4 }
      }
  }
  }
   
  toggleTheme = () => {
   this.setState({ isPortrait: !this.state.isPortrait})
  }

  toggleWhereAreWeGoing = (param) => {
    this.setState({ whereAreWeGoing: param})
  }

  render() { 
    return (
      <ThemeContext.Provider value={{...this.state, toggleTheme: this.toggleTheme, toggleWhereAreWeGoing: this.toggleWhereAreWeGoing, direction: this.direction}}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}
 
export default ThemeContextProvider;