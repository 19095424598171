import React, { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';

const LanguageToggle = () => {

  // destructuring language context
  const { toggleLanguage, current, de, en } = useContext(LanguageContext)

  // setting language
  const lang = (current === "de") ? de : en 

  return ( 
    <p onClick={toggleLanguage}>{lang.button_toggle_language}</p>
   );
}
 
export default LanguageToggle;