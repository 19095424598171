import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';


const ActivitiesITServices = ( prevPath ) => {


  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);
 
  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }

  return (
    <motion.div id="activities-9" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_9}/>
      <div className={"b-caption "+lang.credit_activities_9.color}>{lang.credit_activities_9.text}</div>      
    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_9}/> */}
    <svg version="1.1" id="d-icon-it_services" className="icon-round-large" x="0px" y="0px" viewBox="0 0 36.9 36.9" >

        <g>
          <path className="blue" d="M18.4,36.9c10.2,0,18.4-8.2,18.4-18.4C36.9,8.2,28.6,0,18.4,0C8.2,0,0,8.2,0,18.4C0,28.6,8.2,36.9,18.4,36.9
            C18.4,36.9,18.4,36.9,18.4,36.9z"/>
          <path className="white" d="M5.6,21.9h6.2v2.6H9.7v1.3h9v-1.3h-2.1v-2.6h6.2v-12H5.6V21.9z M6.9,11.2h14.5v9.4H6.9L6.9,11.2z"/>
        </g>
        <rect className="white" x="24.2" y="14.8" width="7.8" height="3.2"/>
        <rect className="white" x="24.2" y="18.6" width="7.8" height="3.2"/>
        <rect className="white" x="24.2" y="22.5" width="7.8" height="3.2"/>
        <circle className="blue d-icon-led d-icon-led-1" cx="25.8" cy="16.4" r="0.75"/>
        <circle className="blue d-icon-led d-icon-led-2" cx="25.8" cy="20.3" r="0.75"/>
        <circle className="blue d-icon-led d-icon-led-3" cx="25.8" cy="24.1" r="0.75"/>
        </svg>

    <h1>{lang.title_activities_9}</h1>
    <h2>{lang.subtitle_activities_9}</h2>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_9}}></div>
    <img className="image-activities-9" src={lang.image_activities_9}/>

    </motion.div>
  )
}

export default ActivitiesITServices;