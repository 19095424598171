import React, { Component, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from './../../contexts/LanguageContext';
import { ThemeContext } from './../../contexts/ThemeContext';


const Activities = () => {  

    // consume theme context
    const { pageTransitions, links, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)

    // destructuring language context
    const { current, de, en } = useContext(LanguageContext)
    // setting language
    const lang = (current === "de") ? de : en 

    return (
      <motion.div className={"content-block lang-"+current}
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit={whereAreWeGoing}
      >
        <h3 className="mb-0">{lang.title_activities}</h3>
        <h4>{lang.subtitle_activities}</h4>
        
        <div className="billboard mb-4">
        <svg version="1.1" id="svg-activities" x="0px" y="0px"
	 viewBox="0 0 1080 872">
	 {/* <circle cx="534.5" cy="401.5" r="175"></circle> */}
<path className="st1 ticktock" d="M378.1,483.4c-13.3-25.3-20.2-53.4-20.1-81.9c0-7.2,0.4-14.3,1.3-21.5l-23-4c-1,8.3-1.6,16.7-1.6,25.2
	c0,6.2,0.3,12.3,0.8,18.3l-20.6,21.3l26.6,13l-0.2-1.4c3.9,14.8,9.5,29.1,16.7,42.6L378.1,483.4z M362,363.8
	c7.6-34.8,25.6-66.4,51.5-90.7l-15.3-18c-10.5,9.8-19.9,20.7-28,32.5l-29.2,3.1l12,27c-6.2,13.5-10.9,27.6-14,42.2L362,363.8z
	 M386.5,497.7l-20.1,11.7c8.1,12.5,17.5,24.1,28.1,34.5l-1.6-1.4l-2.1,29.6l28.8-7.2l-1.3-1.1c12.6,9,26.3,16.6,40.6,22.4l7.9-21.9
	C434,550.8,406,527.6,386.5,497.7L386.5,497.7z M601.5,564.3l8,22.1c13.7-5.6,26.8-12.7,39-21.2l28.3,7l-2-29
	c10.1-9.9,19-20.9,26.8-32.7L681,498.4C661.7,527.8,634,550.7,601.5,564.3z M586.1,569.8c-33.8,10.4-70,10.5-103.9,0.1l-2.2,6.1
	l-5.7,15.8c14,4.4,28.5,7.3,43.2,8.5l16.9,23.7l17.6-23.8c14.3-1.2,28.4-4,42-8.3L586.1,569.8z M426,262.3
	c28.6-22.3,63.4-35.2,99.7-36.8v-24c-14.9,0.6-29.7,3-44.2,6.9l-24-16.2l-8,28c-13.8,6.5-26.8,14.6-38.8,24L426,262.3z M706.1,364.3
	l23.8-4c-3.2-15.2-8.1-29.9-14.6-43.9l11.5-25.9l-28.1-3c-8.7-12.5-18.7-24-30-34.3l-15.5,18.6C679.9,296.4,698.4,328.7,706.1,364.3
	z M655.3,242.1c-11.5-8.8-24-16.3-37.2-22.4l-7.9-27.7l-23.9,16.1c-14.5-3.9-29.3-6.1-44.3-6.7v24c35.4,1.6,69.5,13.8,97.8,35.2
	L655.3,242.1z M727.1,453.6c-4,14.9-9.8,29.3-17.1,42.8l-20.6-12.1c13.6-25.5,20.7-53.9,20.6-82.8c0-6.9-0.4-13.8-1.2-20.6l23.8-4
	c1,8.1,1.5,16.2,1.5,24.3c0,6.4-0.3,12.7-0.9,19l20,20.7L727.1,453.6z"/>
<path className="st1" d="M576.7,335c1.4,15.5-4.9,40,12.3,51.4c9.6,6.4,18.7,5.6,29,5.8v-29.6c-16.3,1.2-31.5-2.9-37.7-17.9
	c-1.5-3.6-2.2-9.7-2.2-9.7H576.7z"/>
<path className="st2" d="M569.1,335.4c-1.4,15.5,4.9,40-12.3,51.4c-9.6,6.4-18.7,5.6-29,5.8V363c16.3,1.2,31.6-2.9,37.7-17.9
	c1.5-3.6,2.2-9.7,2.2-9.7H569.1z"/>
<path className="st3" d="M466.9,430c-2.2,4.8-7.3,7.4-12.5,7.2v-23.5c3.8,0,7.5,0.6,10.3,3.6C467.5,420.6,468.3,425.8,466.9,430z
	 M441,402.1v35.8l36.6,0c0.1-0.2,0.3-0.4,0.4-0.6c3.8-7.2,4.2-17.9-0.2-24.7C470.3,402,454.9,401.3,441,402.1z M509.2,402.1
	l-8.3,28.5l-8.1-28.5h-13.7l11.7,35.8l3.4,10.4h13.5l3.5-10.4l11.9-35.8H509.2z M613.5,402.1l-6.9,22.7l-6.3-22.7h-11.9l-6.7,22.7
	l-6.5-22.7h-13.5l12.2,35.8l3.5,10.4h8.7l3.3-10.4l4.8-14.9l4.8,14.9l3.4,10.4h9.5l3.3-10.4l11.2-35.8H613.5z M525.2,411.9
	c-5.2,6.8-5.2,17.5-1,25c0.2,0.3,0.5,0.7,0.7,1c8.1,10.7,20.2,11.4,34.1,10.5v-24.2h-11.3v11.9c0,0.6-0.6,1.4-1.4,1.2
	c-4.8,0-9.5-3.6-11.3-8c-1.6-4.4-0.6-9.7,3.2-12.7c5.9-5.3,12.5-3.2,20.7-3.2v-11.7C546.3,401.7,533,401.4,525.2,411.9z
	 M477.6,437.9H441v10.4C454.7,448.7,469.6,449.7,477.6,437.9C477.6,437.9,477.6,437.9,477.6,437.9z"/>
<rect x="627.1" y="101.1" className="st4" width="3.5" height="3.5"/>
<rect x="627.1" y="109" className="st4" width="3.5" height="3.5"/>
<rect x="627.1" y="117.8" className="st4" width="3.5" height="3.5"/>
<NavLink key='ata' to={{pathname: '/activities/technical-advice'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-1">
	<path className="st5" d="M440.3,163.4c25.7,0,46.5-20.8,46.5-46.5c0-25.7-20.8-46.5-46.5-46.5c-25.7,0-46.5,20.8-46.5,46.5
		c0,12.3,4.9,24.2,13.6,32.9C416.1,158.5,428,163.4,440.3,163.4z"/>
	<polygon className="st4" points="468.8,121.4 468.8,91 428.5,91 428.5,101.3 450.4,101.3 450.4,121.5 451.1,121.5 451.1,121.4 
		454.4,121.4 463.1,129.1 463.1,121.4 	"/>
	<path className="st4" d="M433.5,118.7c0-1.8-1.1-3.4-2.7-4.1c-1.7-0.7-3.6-0.3-4.8,1c-1.3,1.3-1.6,3.2-1,4.8c0.7,1.7,2.3,2.7,4.1,2.7
		C431.5,123.1,433.5,121.1,433.5,118.7z M436.8,118.7c0,1.8,1.3,3.3,3,3.6c-0.1,0.4-0.3,0.8-0.5,1.3c-0.5-0.3-1.2-0.5-1.8-0.5
		c-1.3,0-2.6,0.7-3.2,1.9s-0.6,2.6,0.2,3.7c-0.6,0.3-1.2,0.6-1.9,0.8c-0.2-1.8-1.8-3.2-3.6-3.2c-1.8,0-3.4,1.4-3.6,3.2
		c-0.6-0.2-1.2-0.4-1.7-0.7c0.9-1.4,0.7-3.3-0.5-4.5c-1.2-1.2-3-1.5-4.4-0.6c-0.2-0.5-0.5-1-0.6-1.5c1.6-0.4,2.6-1.9,2.6-3.5
		s-1.1-3-2.6-3.5c0.2-0.5,0.4-1.1,0.7-1.6c1.4,0.7,3.1,0.4,4.2-0.7c1.1-1.1,1.3-2.8,0.6-4.2c0.6-0.3,1.3-0.6,1.9-0.8
		c0.4,1.6,1.9,2.6,3.5,2.6c1.6,0,3-1.1,3.5-2.6c0.6,0.2,1.1,0.4,1.6,0.7c-0.9,1.4-0.7,3.3,0.5,4.5c1.2,1.2,3.1,1.4,4.5,0.5
		c0.3,0.5,0.5,1,0.7,1.6C438.1,115.4,436.8,116.9,436.8,118.7z M447.8,135.8v-32.6h-38.2v32.6h5.2v7.8l8.8-7.8H447.8z"/>
	{/* <text x="273.9248 109.049)" className="st6 st7 st8">{lang.title_activities_10_l1}</text> */}
	<text x={lang.svg_labels.activities10.p1.x} y={lang.svg_labels.activities10.p1.y} className="st6 st7 st8">{lang.svg_labels.activities10.l1}</text>
	<text x={lang.svg_labels.activities10.p2.x} y={lang.svg_labels.activities10.p2.y} className="st6 st7 st8">{lang.svg_labels.activities10.l2}</text>
</g>
</NavLink>
<NavLink key='rulestan' to={{pathname: '/activities/rulesetting-standardization'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-2">
	<path className="st5" d="M637.7,163.4c25.7,0,46.5-20.8,46.5-46.5c0-25.7-20.8-46.5-46.5-46.5c-25.7,0-46.5,20.8-46.5,46.5
		c0,12.3,4.9,24.2,13.6,32.9C613.5,158.5,625.3,163.4,637.7,163.4z"/>
	<rect x="633.7" y="101.1" className="st4" width="14" height="3.5"/>
	<rect x="633.7" y="109" className="st4" width="14" height="3.5"/>
	<rect x="633.7" y="117.8" className="st4" width="14" height="3.5"/>
  <rect x="627" y="101.1" className="st4" width="3.5" height="3.5"/>
	<rect x="627" y="109" className="st4" width="3.5" height="3.5"/>
	<rect x="627" y="117.8" className="st4" width="3.5" height="3.5"/>
	<path className="st4" d="M613.3,127V95.1h48.3V127H613.3z M664.9,130.3V91.9H610v38.5h19.8v8.7h-6.7v3.3h28.6V139H645v-8.7H664.9z"/>
	<text x={lang.svg_labels.activities1.p1.x} y={lang.svg_labels.activities1.p1.y} className="st6 st7 st8">{lang.svg_labels.activities1.l1}</text>
	<text x={lang.svg_labels.activities1.p2.x} y={lang.svg_labels.activities1.p2.y} className="st6 st7 st8">{lang.svg_labels.activities1.l2}</text>
</g>
</NavLink>
<NavLink key='rnd' to={{pathname: '/activities/research-development'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-3">
	
		<ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 416.9323 1006.7153)" className="st5" cx="800.1" cy="258.3" rx="46.6" ry="46.6"/>
	<path className="st4" d="M807.2,251.1v-20.8h2.2v-5.3h-17.2v5.3h2.2v20.8c0,6.5-17.5,19.1-17.5,28c-0.2,4.9,2.8,9.4,7.5,10.9h32.8
		c0,0,5.8-2,5.8-10.9C822.9,270.2,807.2,257.7,807.2,251.1z M813.2,285.6h-25.3c-3.5-1-5.9-4.3-5.8-7.9c0-6.4,13.5-15.6,13.5-20.2
		h9.9c0,4.8,12.2,13.8,12.2,20.2C818,281,816.2,284.2,813.2,285.6z"/>
	<path className="st4" d="M809.5,277.2c0.8,0,1.5-0.5,1.8-1.2c0.3-0.7,0.1-1.6-0.4-2.2c-0.6-0.6-1.4-0.7-2.2-0.4c-0.7,0.3-1.2,1-1.2,1.8
		C807.6,276.3,808.4,277.2,809.5,277.2z"/>
	<path className="st4" d="M798.6,281.6c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C796.6,280.7,797.5,281.6,798.6,281.6z"/>
	<path className="st4" d="M795.5,271.5c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9c0,0.8,0.3,1.5,0.8,2
		C794,271.2,794.7,271.5,795.5,271.5z"/>
	<text x={lang.svg_labels.activities2.p1.x} y={lang.svg_labels.activities2.p1.y} className="st6 st7 st8">{lang.svg_labels.activities2.l1}</text>
	<text x={lang.svg_labels.activities2.p2.x} y={lang.svg_labels.activities2.p2.y} className="st6 st7 st8">{lang.svg_labels.activities2.l2}</text>
</g>
</NavLink>
<NavLink key='exce' to={{pathname: '/activities/examination-certification'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-4">
	
		<ellipse transform="matrix(7.088884e-02 -0.9975 0.9975 7.088884e-02 322.148 1242.299)" className="st5" cx="827.9" cy="448.2" rx="46.6" ry="46.6"/>
	<g>
		<polygon className="st4" points="836.7,431 837.4,429.7 836,429 836.4,427.4 834.9,427.1 834.8,425.5 833.3,425.6 832.9,424.1 
			831.4,424.6 830.6,423.2 829.2,424 828.2,422.9 827.1,424 825.7,423.2 824.9,424.6 823.5,424.1 823,425.6 821.5,425.5 
			821.4,427.1 819.9,427.4 820.3,429 818.9,429.7 819.6,431 818.4,432.1 819.5,433.2 818.6,434.5 819.9,435.4 819.3,436.8 
			820.8,437.4 820.6,438.9 822.2,439 822.4,440.6 824,440.3 824.6,441.8 826,441.1 826.9,442.4 828.2,441.4 829.4,442.4 
			830.3,441.1 831.7,441.8 832.4,440.3 833.9,440.6 834.1,439 835.7,438.9 835.5,437.4 837,436.8 836.4,435.4 837.7,434.5 
			836.8,433.2 837.9,432.1 		"/>
		<path className="st4" d="M844.2,432.3c0-7.7-5.5-14.3-13-15.9c-7.6-1.5-15.2,2.5-18.2,9.6c-3,7.1-0.6,15.3,5.8,19.7l-7.1,33.3h12.8
			l1.6-7.6l-5.1-24.4c0.5,0.3,1.1,0.5,1.6,0.7l6.6,31.4h14.9l-7.1-33.3C841.5,442.7,844.2,437.7,844.2,432.3z M835.8,443.2l-2-0.3
			l-0.8,1.9l-1.9-0.8l-1.3,1.7l-1.6-1.3l-1.6,1.3l-1.3-1.7l-1.9,0.8l-0.8-1.9l-2,0.3l-0.3-2.1l-2.1-0.2l0.2-2.1l-2-0.7l0.7-2
			l-1.7-1.2l1.2-1.7l-1.4-1.5l1.6-1.4l-1-1.8l1.9-0.9l-0.5-2l2-0.4l0-2.1l2.1,0.1l0.6-2l2,0.6l1-1.8l1.8,1.1l1.5-1.5l1.5,1.5
			l1.8-1.1l1,1.8l2-0.6l0.6,2l2.1-0.1l0,2.1l2,0.4l-0.5,2l1.9,0.9l-1,1.8l1.6,1.4l-1.4,1.5l1.2,1.7l-1.7,1.2l0.7,2l-2,0.7l0.2,2.1
			l-2.1,0.2L835.8,443.2z"/>
	</g>
	<text x={lang.svg_labels.activities3.p1.x} y={lang.svg_labels.activities3.p1.y} className="st6 st7 st8">{lang.svg_labels.activities3.l1}</text>
	<text x={lang.svg_labels.activities3.p2.x} y={lang.svg_labels.activities3.p2.y} className="st6 st7 st8">{lang.svg_labels.activities3.l2}</text>
</g>
</NavLink>
<NavLink key='tra' to={{pathname: '/activities/training'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-5">
	
		<ellipse transform="matrix(0.3827 -0.9239 0.9239 0.3827 -125.0886 1062.3334)" className="st5" cx="732.4" cy="624.8" rx="46.6" ry="46.6"/>
	<path className="st4" d="M711.9,635c-0.1,0.5-0.1,1.1-0.1,1.6c0,5.4,8.9,9.6,20.1,9.6c11.2,0,20.2-4,20.2-9.4c0-0.4,0-0.8,0-1.2v-9.6
		l-19.8,8l-20.5-8.1L711.9,635L711.9,635z"/>
	<polygon className="st4" points="697.2,616.5 732.8,630.5 768,616.1 732.5,602.4 	"/>
	<polygon className="st4" points="764.9,618.6 761.8,632.2 768,632.2 	"/>
	<text x={lang.svg_labels.activities4.p1.x} y={lang.svg_labels.activities4.p1.y} className="st6 st7 st8">{lang.svg_labels.activities4.l1}</text>
	<text x={lang.svg_labels.activities4.p2.x} y={lang.svg_labels.activities4.p2.y} className="st6 st7 st8">{lang.svg_labels.activities4.l2}</text>
</g>
</NavLink>
<NavLink key='its' to={{pathname: '/activities/it-services'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-6">
	<path className="st5" d="M267.9,305.6c25.7,0,46.5-20.8,46.5-46.5c0-25.7-20.8-46.5-46.5-46.5c-25.7,0-46.5,20.8-46.5,46.5
		c0,12.3,4.9,24.2,13.6,32.9C243.8,300.6,255.6,305.6,267.9,305.6z"/>
	<path className="st4" d="M286.5,256.2c-1,0-1.7-0.8-1.7-1.8c0-1,0.8-1.8,1.7-1.8c1,0,1.7,0.8,1.7,1.8
		C288.2,255.4,287.4,256.2,286.5,256.2L286.5,256.2z M282.4,250.2v8.3h19.8v-8.3H282.4z"/>
	<path className="st4" d="M286.5,262.3c0.7,0,1.3,0.4,1.6,1.1c0.3,0.7,0.1,1.4-0.4,1.9c-0.5,0.5-1.2,0.7-1.9,0.4
		c-0.7-0.3-1.1-0.9-1.1-1.6c0-0.5,0.2-0.9,0.5-1.3C285.6,262.5,286,262.3,286.5,262.3L286.5,262.3z M282.4,268.2h19.8v-8.3h-19.8
		V268.2z"/>
	<path className="st4" d="M286.5,272c1,0,1.7,0.8,1.7,1.8c0,1-0.8,1.8-1.7,1.8c-1,0-1.7-0.8-1.7-1.8c0-0.5,0.2-0.9,0.5-1.3
		C285.6,272.1,286,272,286.5,272L286.5,272z M282.4,277.9h19.8v-8.3h-19.8V277.9z"/>
	<path className="st4" d="M239.2,240.8h36.5v23.9h-36.5V240.8z M235.9,268h15.5v6.6h-5.3v3.3h22.5v-3.3h-5.4V268h15.5v-30.5h-43V268z"/>
	<text x={lang.svg_labels.activities9.p1.x} y={lang.svg_labels.activities9.p1.y} className="st6 st7 st8">{lang.svg_labels.activities9.l1}</text>
</g>
</NavLink>
<NavLink key='ohs' to={{pathname: '/activities/occupational-health-safety'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-7">
	
		<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -346.2427 423.5319)" className="st5" cx="338.1" cy="629.7" rx="46.6" ry="46.6"/>
	<path className="st4" d="M310.9,646.5v6.1h54.5v-6.1H310.9z M336.3,600.7l-2.8,7.3h9.7l-2.8-7.3H336.3z M330.4,615.9l-3.1,7.9h21.9
		l-3.1-7.9H330.4z M324.3,631.7h28l3,7.8h-34.1L324.3,631.7z"/>
	<text x={lang.svg_labels.activities8.p1.x} y={lang.svg_labels.activities8.p1.y} className="st6 st7 st8">{lang.svg_labels.activities8.l1}</text>
	<text x={lang.svg_labels.activities8.p2.x} y={lang.svg_labels.activities8.p2.y} className="st6 st7 st8">{lang.svg_labels.activities8.l2}</text>
</g>
</NavLink>
<NavLink key='sqm' to={{pathname: '/activities/safety-quality-management'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-8">
	<circle className="st5" cx="234.6" cy="449.2" r="46.6"/>
	<text x={lang.svg_labels.activities7.p1.x} y={lang.svg_labels.activities7.p1.y} className="st6 st7 st8">{lang.svg_labels.activities7.l1}</text>
  <text x={lang.svg_labels.activities7.p2.x} y={lang.svg_labels.activities7.p2.y} className="st6 st7 st8">{lang.svg_labels.activities7.l2}</text>
	<text x={lang.svg_labels.activities7.p3.x} y={lang.svg_labels.activities7.p3.y} className="st6 st7 st8">{lang.svg_labels.activities7.l3}</text> 
	<g>
		<polygon className="st4" points="227.3,447 223.2,451.1 234.6,462.7 234.6,454.4 230.5,450.2 		"/>
		<path className="st4" d="M234.4,418.4c0,0-7.9,8-26.1,8v27.7c0,16.9,26.1,24.1,26.2,26.7c0-2.6,26.1-9.8,26.1-26.8v-27.7
			C242.3,426.4,234.4,418.4,234.4,418.4z M234.6,462.8v14.1c-0.2-2.2-22.4-8.5-22.4-23.1v-24c15.7,0,22.4-7,22.4-7l0,0v31.6
			l16.5-16.6l4.1,4.2L234.6,462.8z"/>
	</g>
</g>
</NavLink>
<NavLink key='ati' to={{pathname: '/activities/technical-information'}}>
<g onClick={() => toggleWhereAreWeGoing('hidden')} className="a-icon-9">
	
		<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -340.2105 583.3121)" className="st5" cx="534" cy="702.3" rx="46.6" ry="46.6"/>
	{/* <rect x="515.1" y="675.5" className="st4" width="37.4" height="9.2"/> */}
	<polygon className="st4" points="520.1,675.5 547.5,675.5 552.5,684.7 515.1,684.7 "/>


	<text x={lang.svg_labels.activities6.p1.x} y={lang.svg_labels.activities6.p1.y} className="st6 st7 st8">{lang.svg_labels.activities6.l1}</text>
	<text x={lang.svg_labels.activities6.p2.x} y={lang.svg_labels.activities6.p2.y} className="st6 st7 st8">{lang.svg_labels.activities6.l2}</text>
	<path className="st4" d="M562.6,702.5l-5.1-12h-0.1v11.9h-46.6v-11.9h-0.1l-5.1,12h0v21.8h57.1L562.6,702.5L562.6,702.5z M545.4,715.9
		h-23.3v-8.3h3.3v5.3h16.6v-5.3h3.3V715.9z"/>
</g>
</NavLink>
{/* <circle cx="515" cy="515" className="green" r="25"/> */}
</svg>

        </div>
      
        {/* <div className="link-list">
        { links.filter(item => item.path.includes("activities")).map(function(item) {
                return <NavLink key={item.id} to={
                  {pathname: item.path }} >{lang[item.locString]}</NavLink>
            })
        }
        </div> */}

      </motion.div>
    )
  }

export default Activities;