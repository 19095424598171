import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';


const ActivitiesSafetyQualityManagement = ( prevPath ) => {


  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);

  
  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }

  
  return (
    <motion.div id="activities-7" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_7}/>
      <div className={"b-caption "+lang.credit_activities_7.color}>{lang.credit_activities_7.text}</div>      
    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_7}/> */}
    
<svg version="1.1" id="svg-activities-sqm" className="icon-round-large"  x="0px" y="0px"
	 viewBox="0 0 36.9 36.9">

<circle className="st0 blue" cx="18.4" cy="18.4" r="18.4"/>
<path className="white" d="M18.4,6.3c0,0-3.1,3.2-10.3,3.2v11c0,6.7,10.3,9.6,10.3,10.6c0-1.1,10.3-3.9,10.3-10.6v-11
	C21.5,9.5,18.4,6.3,18.4,6.3z M18.4,29.4c-0.1-0.9-8.9-3.4-8.9-9.1v-9.5c6.2,0,8.9-2.8,8.9-2.8l0,0V29.4z"/>

<g>
	<defs>
		<path id="SVGID_1_" d="M18.4,8v21.4l0,0c0-0.9,8.9-3.4,8.9-9.2v-9.5C21.1,10.7,18.4,8,18.4,8z"/>
	</defs>
	<clipPath id="SVGID_00000080893883603974911950000017982492283015963024_">
		<use xlinkHref="#SVGID_1_"/>
	</clipPath>
	
		<line id="svg-sqm-right" className="draw2" x1="17.2" y1="23.3" x2="25.8" y2="14.7"/>
</g>
<g>
	<defs>
		<path id="SVGID_00000020365957921503044030000003254310910450837928_" d="M18.4,8c0,0-2.7,2.8-8.9,2.8v9.5c0,5.8,8.8,8.3,8.9,9.1
			L18.4,8L18.4,8z"/>
	</defs>
	<clipPath id="SVGID_00000070080728934164854840000014787567527525269690_">
		<use xlinkHref="#SVGID_00000020365957921503044030000003254310910450837928_" />
	</clipPath>
	
		<line id="svg-sqm-left" className="draw1" x1="14.8" y1="18.4" x2="19.2" y2="22.9"/>
</g>
</svg>

    <h1>{lang.title_activities_7}</h1>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_7}}></div>

    <img className="image-activities-7" src={lang.image_activities_7}/>

    </motion.div>
  )
}
export default ActivitiesSafetyQualityManagement;