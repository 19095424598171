import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';
import placeholder from '../../../images/svg/placeholder.svg';


const ActivitiesTraining = ( prevPath ) => {


  // consume theme context
  const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
  const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

  // consume language context
  const { current, de, en } = useContext(LanguageContext);
  // setting language
  const lang = (current === "de") ? de : en 
  // get location
  const location = useLocation()
  const history = useHistory()
  // let exitString = "exit";
  let initString = "hidden";
    
  // useEffect(() => {
    // console.log(prevPath.location.state.prevPath
    if(history.location.state){
      if(history.location.state.from == 'left'){
        if(initString != "enterLeft") initString = "enterLeft";
      }else if(history.location.state.from == 'right'){
        if(initString != "enterRight") initString = "enterRight";
      }
    }
  // }, []);
  
  // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
  if(prevPath.location.state){
    for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
      const element = subpagelinks[location.pathname][index];
      
      if(element.path == prevPath.location.state.prevPath){
        // matched path, now determine which neighbor it is:
        if(element.direction == 'next'){
          initString = "enterLeft";
        }else if(element.direction == 'previous'){
          initString = "enterRight";
        }
      }
    }
  }

  
  return (
    <motion.div id="activities-4" className="content-block full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
    >

    <div className="billboard mb-4">
      <img src={lang.billboard_activities_4}/>
      <div className={"b-caption "+lang.credit_activities_4.color}>{lang.credit_activities_4.text}</div>
    </div>
    {/* <img className="icon-round-large" src={lang.icon_activities_4}/> */}
    <svg version="1.1" id="d-icon-vocational-training" className="icon-round-large" x="0px" y="0px"
          viewBox="0 0 36.9 36.9">
        <circle className="blue" cx="18.4" cy="18.4" r="18.4"/>
        <g className="d-icon-hat">
        <path className="white" d="M10.5,22.7c0,0.3,0,0.6,0,0.7c0,2.1,3.5,3.7,7.9,3.7c4.4,0,8-1.6,8-3.7c0-0.2,0-0.3,0-0.5v-3.8l-7.8,3.2
          l-8.1-3.2L10.5,22.7L10.5,22.7z"/>
        
        <polygon className="white" points="4.4,15.3 18.5,20.8 32.4,15.1 18.4,9.7 4.4,15.3 "/>
        <polygon className="white d-icon-zipfel" points="31.2,16 30,21.5 32.4,21.5 31.2,16 "/>
        </g>
    </svg>

    <h1 className="mb-0p5">{lang.title_activities_4}</h1>

    <div dangerouslySetInnerHTML={{__html: lang.text_activities_4}}></div>

    </motion.div>
  )
}

export default ActivitiesTraining;