import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import MainMenu from './MainMenu';
// navigation
// import PageNavigation from './../navigation/PageNavigation';
import SubpageNavigation from './../navigation/SubpageNavigation';
// import SubpageButtonLeft from './../navigation/SubpageButtonLeft';
import SubpageButtonRight from './../navigation/SubpageButtonRight';

import LanguageToggle from './../toggles/LanguageToggle';
// import StyleGuide from './../pages/dev/StyleGuide';

import UpButton from './../navigation/UpButton';
import TouchIcon from './TouchIcon';


const ScreenSaver = ({ showScreenSaver, setShowScreenSaver}) => {

  return ( 
    <div id="d-screensaver" class={'d-scr-'+showScreenSaver} onClick={ () => setShowScreenSaver(!showScreenSaver)}>
        <video width="320" height="240" loop autoPlay muted>
            <source src="/videos/screensaver.mp4" type="video/mp4"/>
        </video>
        {/* {showScreenSaver ? 'ja' : 'nein'} */}
        </div>
   );
}
 
export default ScreenSaver;

