import React, { Component, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ThemeContext } from './../../contexts/ThemeContext';
import placeholder from '../../images/svg/placeholder.svg';
import btnArrow from '../../images/svg/button-arrow.svg';


const About = () => {

    // consume theme context
    const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing  } = useContext(ThemeContext)

    // destructuring language context
    const { current, de, en } = useContext(LanguageContext)
    // setting language
    const lang = (current === "de") ? de : en 

    const billboards = [
      "/dvgw-billboard-membership-benefits.jpg",
      "/csm_DVGW-Hochschulgruppen_9d7915b878_b.jpg",
      "/Fotolia-131932509-XL-kerkezz_KOM.jpg",
      "/iStock-1049913218-nd3000-RZ.jpg",
      "/iStock-599142780-sturti-RZ.jpg",
      "/iStock-1286013189-jacoblund-RZ.jpg",
      "/billboard-activities-young-professionals.jpg",
      "/mockup-medien.jpg",
      "/iStock-490715578-portra-RZ.jpg",
      "/RH-090219-113-RZ.jpg",
      "/iStock-957195804_obradovic-RZ.jpg",
      "/RH-081117-130-RZ.jpg",
      "/mockup_regelwerk-online-plus.png",
      "/DVGW-2809-TSM-Grafik-Vorteile.png",
      "/dvgw-icon-members.svg",
      "/dvgw-icon-network.svg",
      "/dvgw-icon-gas.svg",
      "/dvgw-icon-drinkingwater.svg",
      "/dvgw-icon-members-1.svg",
      "/dvgw-icon-members-2.svg",
      "/dvgw-icon-members-3.svg",
      "/dvgw-icon-members-4.svg",
      "/dvgw-landesgruppen-02.svg",
      "/dvgw-bezirksgruppen-02.svg",
      "/qr/qr-about-groups2.svg",
      "/logos/youth1.svg",
      "/logos/youth2.svg",
      "/logos/youth3.svg"
    ];

    useEffect(() => {
      // console.log("preloader")
      //preloading image
      billboards.forEach((billboard) => {
        const img = new Image();
        img.src = billboard;
        console.log('loading '+billboard)
      });
    }, []);

    return (
      <motion.div className="content-block wide"
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit={whereAreWeGoing}
      >
        <h3>{lang.title_about}</h3>

        <p className="text-intro" dangerouslySetInnerHTML={{__html: lang.text_about_intro}}></p>

        <div className="d-grid-2x2 text-center mb-4">
          <div dangerouslySetInnerHTML={{__html: lang.text_about_card1}}></div>
          <div dangerouslySetInnerHTML={{__html: lang.text_about_card2}}></div>
          <div dangerouslySetInnerHTML={{__html: lang.text_about_card3}}></div>
          <div dangerouslySetInnerHTML={{__html: lang.text_about_card4}}></div>
        </div>

        <div className="d-grid-2x2 fit-buttons mb-4">
          <Link to="/about/membership1">
            <button className="green" onClick={() => toggleWhereAreWeGoing('hidden')}><span>{lang.button_membership}</span><span><img src={btnArrow}/></span></button>
          </Link>
          <Link to="/about/groups1">
            <button className="green" onClick={() => toggleWhereAreWeGoing('hidden')}><span>{lang.button_groups}</span><span><img src={btnArrow}/></span></button>
          </Link>
          <Link to="/about/research">
            <button className="green" onClick={() => toggleWhereAreWeGoing('hidden')}><span>{lang.button_institutes}</span><span><img src={btnArrow}/></span></button>
          </Link>
          <Link to="/about/youth">
            <button className="green" onClick={() => toggleWhereAreWeGoing('hidden')}><span>{lang.button_youngprofessionals}</span><span><img src={btnArrow}/></span></button>
          </Link>
        </div>

      </motion.div>
    )
}

export default About;