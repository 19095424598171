import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from './../../contexts/LanguageContext';
import { ThemeContext } from './../../contexts/ThemeContext';
import btnArrow from '../../images/svg/button-arrow.svg';


const Water = () => {

    // consume theme context
    const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)

    // destructuring language context
    const { current, de, en } = useContext(LanguageContext)
    // setting language
    const lang = (current === "de") ? de : en 

    return (
      <motion.div className="content-block full-height"
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit={whereAreWeGoing}
      >

        <div className="d-cols-2 full-width full-height d-cols-separator">

        <div>
          <Link to="/water/climate-change">
            <button className={"bg-green big-button-left btn-"+current} onClick={() => toggleWhereAreWeGoing('hidden')}><span>{lang.title_water_supply}</span><span><img src={btnArrow}/></span></button>
          </Link>
        </div>

        <div>
          <Link to="/water/nitrates">
            <button className={"bg-green big-button-right btn-"+current} onClick={() => toggleWhereAreWeGoing('hidden')}><span className="title-nitrate" dangerouslySetInnerHTML={{__html: lang.title_water_nitrates}}></span><span><img src={btnArrow}/></span></button>
          </Link>
        </div>

        </div>

      </motion.div>
    )
  }

export default Water;