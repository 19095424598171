import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, useCycle } from 'framer-motion';
import { LanguageContext } from './../../../contexts/LanguageContext';
import { ThemeContext } from './../../../contexts/ThemeContext';
import { useLocation, useHistory } from 'react-router-dom';

const WaterResponsibility = ( prevPath ) => {
  
    // consume theme context
    const { pageTransitions, subpagelinks, whereAreWeGoing, toggleWhereAreWeGoing } = useContext(ThemeContext)
    const [exitAnimation, cycleExitAnimation] = useCycle("exitLeft", "exitBottom", "exitRight", "exit")

    // destructuring language context
    const { current, de, en } = useContext(LanguageContext)
    // setting language
    const lang = (current === "de") ? de : en 

    
    const location = useLocation()
    const history = useHistory()
    // let exitString = "exit";
    let initString = "hidden";
      
    // useEffect(() => {
      // console.log(prevPath.location.state.prevPath
      if(history.location.state){
        if(history.location.state.from == 'left'){
          if(initString != "enterLeft") initString = "enterLeft";
        }else if(history.location.state.from == 'right'){
          if(initString != "enterRight") initString = "enterRight";
        }
      }
    // }, []);
  

    // loop through subpagelinks and see if immediate neighbor is prevpath.location...:
    if(prevPath.location.state){
      for (let index = 0; index < subpagelinks[location.pathname].length; index++) {
        const element = subpagelinks[location.pathname][index];
        
        if(element.path == prevPath.location.state.prevPath){
          // matched path, now determine which neighbor it is:
          if(element.direction == 'next'){
            initString = "enterLeft";
          }else if(element.direction == 'previous'){
            initString = "enterRight";
          }
        }
      }
    }


    return (
      <motion.div className="content-block align-middle full-height"
      variants={pageTransitions}
      initial={initString}
      animate="visible"
      exit={whereAreWeGoing}
      >
        {/* <h1>{lang.title_water_nitrates}</h1> */}

        <div dangerouslySetInnerHTML={{__html: lang.text_water_responsibility}}></div>
        
      </motion.div>
    )
  }

export default WaterResponsibility;