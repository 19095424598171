import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import Shaderport from '../../three/Shaderport';
import { ThemeContext } from '../../../contexts/ThemeContext';


const Shader = () => {

  // consume theme context
  const { pageTransitions, subpagelinks } = useContext(ThemeContext)

  // destructuring language context
  const { current, de, en } = useContext(LanguageContext)
  // setting language
  const lang = (current === "de") ? de : en 

  return (
    <motion.div className="content-block d-r3f-content"
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Shaderport/>
      </motion.div>
    )
  }

export default Shader;