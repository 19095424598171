import React, { Component, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ThemeContext } from './../../../contexts/ThemeContext';
import StyleGuide from './StyleGuide';

const Changelog = () => {

    // consume theme context
    const { pageTransitions, subpagelinks } = useContext(ThemeContext)

    // destructuring language context
    const { current, de, en } = useContext(LanguageContext)
    // setting language
    const lang = (current === "de") ? de : en 

    return (
      <motion.div className="content-block scrollable"
        variants={pageTransitions}
        initial="hidden"
        animate="visible"
        exit="exit"
      >

        <p>Dies ist ein Prototyp in Entwicklung.</p>
        <ul className="arrow-list arrow-list-large">
          <li><Link to="/styleguide">Styleguide</Link></li>
          <li><Link to="/admin">Editor-Mockup</Link></li>
        </ul>

        <hr/>

        
        <h1>Changelog</h1>

        <h5 class="mb-1">2021-11-02</h5>
        <ul class="arrow-list">
          <li>Inaktivitäts-Timeout</li>
        </ul>


        <h5 class="mb-1">2021-10-30</h5>
        <ul class="arrow-list">
          <li>komplettes Inhaltsupdate</li>
        </ul>

        <h5 class="mb-1">2021-10-27</h5>
        <ul class="arrow-list">
          <li>Helvetica77BoldCondensed eingebaut</li>
          <li>Blasen transparenter, langsamer</li>
        </ul>

        <h5 class="mb-1">2021-10-26</h5>
        <ul class="arrow-list">
          <li>Neuer Video-Content</li>
          <li>Video-Transition-Tuning</li>
          <li>Page-Transition Overhaul</li>
          <li>Homepage-Show-Delay</li>
        </ul>


        <h5 class="mb-1">2021-10-22</h5>
        <ul class="arrow-list">
          <li>Partikel bei Klick im Hauptmenü</li>
          <li>Language Toggle im Hauptmenü</li>
          <li>Video-Laden optimiert</li>
        </ul>


        <h5 class="mb-1">2021-09-21</h5>
        <ul class="arrow-list">
          <li>Interaction Hint auf Menüseite</li>
          <li>Up-Button einheitlich rechs</li>
          <li>CSS Clip-Path für Billboard&gt;img eingebaut</li>
          <li>Icons unter »Was wir tun grün eingefärbt (too much?)«</li>
          <li>Weiter/Up-Button pulsiert nur einmal initial.</li>
        </ul>

        <h5 class="mb-1">2021-09-20</h5>
        <ul class="arrow-list">
          <li>dynamische Menüseite mit THREE.js</li>
        </ul>

        
        <h5 class="mb-1">2021-09-06</h5>
        <ul class="arrow-list">
          <li>Grafiken in About eingesetzt</li>
          <li>Dynamisches SVG / Technische Beratung</li>
        </ul>

        <h5 class="mb-1">2021-08-31</h5>
        <ul class="arrow-list">
          <li>Startseite Basic-Layout mit CSS-Animationen</li>
          <li>Activities / SVG Labels zweisprachig gelayoutet</li>
          <li>Forschungsinstitute und -einrichtungen: Interaktive Karte mit lokalisiertem Popup und unsichtbaren Click-Targets</li>
          <li>Einige Icons mit CSS-Animationen versehen (s. StyleGuide)</li>
        </ul>

        <h5 class="mb-1">2021-08-25</h5>
        <ul class="arrow-list">
          <li>An Subseiten-Navigation geschraubt</li>
          <li>Rudimentäres Video-Playback implementiert</li>
          <li>Up-Button oben links eingefügt</li>
          <li>Reaktives Inline-SVG auf About-Seite</li>
        </ul>

        <hr/>

        <h3>Todos</h3>

        <ul class="arrow-list">
          <li>Page-Transitions (Intro/Exit) glattziehen</li>
          <li>Navigationselemente finalisieren</li>
          <li>Sprachtoggle verorten</li>
          <li>Alle Zeichenketten lokalisieren</li>
          <li>CMS erweitern (Seitenstruktur abbilden, Objekte/Arrays, Buttons, Revisionen)</li>
          <li>Timeouts / Modals</li>
          <li>Lag bei Betreten von about/membership2 ➜ State überprüfen, evtl. inline base64 img.</li>
          <li>Label auf Billboard about/membership per HTML setzen</li>
          
        </ul>


      </motion.div>
    )
}

export default Changelog;